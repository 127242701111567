import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

function Activity() {

    const navigate = useNavigate();

    useEffect(() => {
      const token = localStorage.getItem("token");
      if (!token) {
        navigate("/signup");
      }
    }, [navigate]);

  return (
    <>
    <div data-v-2d3dc984="" data-v-e3c6332a="" class="navbar" style={{ fontFamily: "'Roboto', 'Inter', sans-serif" }}
    >
            <div data-v-2d3dc984="" class="navbar-fixed wc"
                style={{background: "linear-gradient(90deg, rgb(249, 89, 89) 0%, rgb(255, 154, 142) 100%)"}}
>
                <div data-v-2d3dc984="" class="navbar__content">
                    <div data-v-2d3dc984="" class="navbar__content-left"></div>
                    <div data-v-2d3dc984="" class="navbar__content-center">
                        <div data-v-2d3dc984="" class="headLogo"
                            style={{ backgroundImage: "url('/manifest/daman-white.png')" }}
></div>
                        <div data-v-2d3dc984="" class="navbar__content-title"></div>
                    </div>
                    <div data-v-2d3dc984="" class="navbar__content-right"></div>
                </div>
            </div>
        </div>
        <div data-v-3604dd73="" data-v-e3c6332a="" class="activity-wrapper"
           style={{ "--76181987": "'Roboto', 'Inter', sans-serif" }}
>
            <div data-v-3604dd73="" class="activity-banner">
                <div data-v-3604dd73="">
                    <div data-v-3604dd73="" class="banner-title">Activity</div>
                    <div data-v-3604dd73="" class="banner-para">Please remember to follow the event page</div>
                    <div data-v-3604dd73="" class="banner-para">We will launch user feedback activities from time to
                        time</div>
                </div>
            </div>
            <div data-v-3604dd73="" class="activity-panel">
                <div data-v-3604dd73="" class="activity-panel-header lg4">
                    <div data-v-3604dd73="" class="header-item">
                        <div data-v-3604dd73="" class="van-badge__wrapper">
                            <div data-v-3604dd73="" class="a1 bgcontainer"></div>
                        </div><span data-v-3604dd73="">Activity Award</span>
                    </div>
                    <div data-v-3604dd73="" class="header-item">
                        <div data-v-3604dd73="" class="van-badge__wrapper">
                            <div data-v-3604dd73="" class="a2 bgcontainer"></div>
                        </div><span data-v-3604dd73="">Invitation bonus</span>
                    </div>
                    <div data-v-3604dd73="" class="header-item">
                        <div data-v-3604dd73="" class="van-badge__wrapper">
                            <div data-v-3604dd73="" class="a3 bgcontainer"></div>
                        </div><span data-v-3604dd73="">Betting rebate</span>
                    </div>
                    <div data-v-3604dd73="" class="header-item">
                        <div data-v-3604dd73="" class="van-badge__wrapper">
                            <div data-v-3604dd73="" class="a4 bgcontainer"></div>
                        </div><span data-v-3604dd73="">Super Jackpot</span>
                    </div>
                </div>
                <div data-v-3604dd73="" class="activity-panel-content">
                    <div data-v-3604dd73="" class="content-title"><img data-v-3604dd73="" class=""
                            data-origin="https://damanclub.in/assets/png/signInBanner-33f86d3f.png"
                            src="https://damanclub.in/assets/png/signInBanner-33f86d3f.png"/>
                        <div data-v-3604dd73="" class="content-para">Gifts</div>
                        <p data-v-3604dd73="">Enter the redemption code to receive gift rewards</p>
                    </div>
                    <div data-v-3604dd73="" class="content-title"><img data-v-3604dd73="" class=""
                            data-origin="https://damanclub.in/assets/png/giftRedeem-45917887.png"
                            src="https://damanclub.in/assets/png/giftRedeem-45917887.png"/>
                        <div data-v-3604dd73="" class="content-para">Attendance bonus</div>
                        <p data-v-3604dd73="">The more consecutive days you sign in, the higher the reward will be.</p>
                    </div>
                </div>
            </div>
            <div data-v-3604dd73="" role="feed" class="van-list" aria-busy="false">
                <div data-v-3604dd73="" class="activitySection__container">
                    <div data-v-3604dd73="" class="box"><img data-v-3604dd73=""
                            src="https://ossimg.envyenvelope.com/daman/banner/Banner_20230930162805eioo.png"
                            class="act_0"/>
                        <div data-v-3604dd73="" class="box-content">
                            <div data-v-3604dd73="" class="box-title">Become Agent Enjoy Luxury Rewards</div>
                        </div>
                    </div>
                    <div data-v-3604dd73="" class="box"><img data-v-3604dd73=""
                            src="https://ossimg.envyenvelope.com/daman/banner/Banner_20230323134126wxpv.png"
                            class="act_1"/>
                        <div data-v-3604dd73="" class="box-content">
                            <div data-v-3604dd73="" class="box-title">⭐️Member Activities Winning Streak⭐️</div>
                        </div>
                    </div>
                    <div data-v-3604dd73="" class="box"><img data-v-3604dd73=""
                            src="https://ossimg.envyenvelope.com/daman/banner/Banner_20230323134046hviy.png"
                            class="act_2"/>
                        <div data-v-3604dd73="" class="box-content">
                            <div data-v-3604dd73="" class="box-title">⭐️Aviator High Betting Award⭐️</div>
                        </div>
                    </div>
                    <div data-v-3604dd73="" class="box"><img data-v-3604dd73=""
                            src="https://ossimg.envyenvelope.com/daman/banner/Banner_202303231342324ivw.png"
                            class="act_3"/>
                        <div data-v-3604dd73="" class="box-content">
                            <div data-v-3604dd73="" class="box-title">Lucky "10" Days Of Interest</div>
                        </div>
                    </div>
                    <div data-v-3604dd73="" class="box"><img data-v-3604dd73=""
                            src="https://ossimg.envyenvelope.com/daman/banner/Banner_20230323134154oeeu.png"
                            class="act_4"/>
                        <div data-v-3604dd73="" class="box-content">
                            <div data-v-3604dd73="" class="box-title">🔴 Youtube Creative Video 🔴</div>
                        </div>
                    </div>
                    <div data-v-3604dd73="" class="box"><img data-v-3604dd73=""
                            src="https://ossimg.envyenvelope.com/daman/banner/Banner_20230324165404a7bt.png"
                            class="act_5"/>
                        <div data-v-3604dd73="" class="box-content">
                            <div data-v-3604dd73="" class="box-title">❗️ Mysterious Gift ❗️</div>
                        </div>
                    </div>
                    <div data-v-3604dd73="" class="box"><img data-v-3604dd73=""
                            src="https://ossimg.envyenvelope.com/daman/banner/Banner_20231021124857fd2v.png"
                            class="act_6"/>
                        <div data-v-3604dd73="" class="box-content">
                            <div data-v-3604dd73="" class="box-title"></div>
                        </div>
                    </div>
                </div>
                <div class="van-list__finished-text">No more</div>
                <div class="van-list__placeholder"></div>
            </div>
        </div>
        
      
    
    </>
  )
}

export default Activity
