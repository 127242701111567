import React, { useContext, useEffect, useState } from "react";
import { MyContext } from "../../Context/MyContext";
import { useNavigate } from "react-router-dom";

function Wingobetting() {
  const context = useContext(MyContext);

  const {
    setselects,
    usebalance,
    setusebalance,
    selects,
    settotolbet,
    updateuserb,
    addbetcontrol,
    adduserbethis,
    formattedTime,
    handelper,
    games
  } = context;

  const [selectedNumber, setSelectedNumber] = useState(null);
  const [showPopup, setShowPopup] = useState("none");
  const [notfund, setnotfund] = useState("none");
  const [timer, settimer] = useState("none");

  const navigate = useNavigate();

  

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/signup");
    }
  }, [navigate]);

  useEffect(() => {
    const sec = formattedTime();

    if (sec < 6) {
      // const voice1 = document.getElementById('voice1');
      // voice1.play();
      settimer("");
    } else {
      settimer("none");
    }
  }, [formattedTime]);

  const handleNumberClick = (number) => {
    const bodyElement = document.body;
    bodyElement.classList.add("van-overflow-hidden");
    setSelectedNumber(number);
    setShowPopup("");
    setselects(number);
  };

  const [quantity, setquantity] = useState(1);
  const [total, setTotal] = useState(1);
  const [bal, setbal] = useState(1);

  const handleNumberClickp = (number) => {
    setTotal(number);
    setbal(number);
  };

  const handleClickp = (number) => {
    setquantity(number);
  };

  const bethandel = () => {
    console.log(games,"this si sajnd")
    const totalAmountElement = total * quantity;
      const totalAmountValue = parseFloat(totalAmountElement);
    settotolbet(totalAmountValue);
    
  

    if (usebalance > totalAmountValue) {
      
      
      setusebalance(usebalance - totalAmountValue);
      setShowPopup("none");
      updateuserb(totalAmountValue);
      setquantity(1);
      setTotal(1);
      setbal(1);
    } else {
      setnotfund(" ");
    }

    setTimeout(() => {
      setnotfund("none");
    }, 1500);

    const bodyElement = document.body;
    bodyElement.classList.remove("van-overflow-hidden");

   
    addbetcontrol(totalAmountValue, selects);
    adduserbethis(totalAmountValue);
  };

  const betcencel = () => {
    setShowPopup("none");
    setquantity(1);
    setTotal(1);
    setbal(1);
    const bodyElement = document.body;
    bodyElement.classList.remove("van-overflow-hidden");
  };

  return (
    <>
      <div
        data-v-0aa493a0=""
        data-v-f9e6ba25=""
        class="Betting__C"
        voicetype="1"
        typeid="1"
      >
        <div
          data-v-0aa493a0=""
          class="Betting__C-mark"
          style={{ display: timer }}
        >
          <div data-v-0aa493a0="">{formattedTime()}</div>
        </div>

        <div data-v-0aa493a0="" class="Betting__C-head">
          <div
            data-v-0aa493a0=""
            class="Betting__C-head-g"
            onClick={() => handleNumberClick("Green")}
          >
            Green
          </div>
          <div
            data-v-0aa493a0=""
            class="Betting__C-head-p"
            onClick={() => handleNumberClick("White")}
          >
            Violet
          </div>
          <div
            data-v-0aa493a0=""
            class="Betting__C-head-r"
            onClick={() => handleNumberClick("Red")}
          >
            Red
          </div>
        </div>

        <div data-v-0aa493a0="" class="Betting__C-numC">
          <div
            data-v-0aa493a0=""
            onClick={() => handleNumberClick("0")}
            class="Betting__C-numC-item0"
          ></div>
          <div
            data-v-0aa493a0=""
            onClick={() => handleNumberClick("1")}
            class="Betting__C-numC-item1"
          ></div>
          <div
            data-v-0aa493a0=""
            onClick={() => handleNumberClick("2")}
            class="Betting__C-numC-item2"
          ></div>
          <div
            data-v-0aa493a0=""
            onClick={() => handleNumberClick("3")}
            class="Betting__C-numC-item3"
          ></div>
          <div
            data-v-0aa493a0=""
            onClick={() => handleNumberClick("4")}
            class="Betting__C-numC-item4"
          ></div>
          <div
            data-v-0aa493a0=""
            onClick={() => handleNumberClick("5")}
            class="Betting__C-numC-item5"
          ></div>
          <div
            data-v-0aa493a0=""
            onClick={() => handleNumberClick("6")}
            class="Betting__C-numC-item6"
          ></div>
          <div
            data-v-0aa493a0=""
            onClick={() => handleNumberClick("7")}
            class="Betting__C-numC-item7"
          ></div>
          <div
            data-v-0aa493a0=""
            onClick={() => handleNumberClick("8")}
            class="Betting__C-numC-item8"
          ></div>
          <div
            data-v-0aa493a0=""
            onClick={() => handleNumberClick("9")}
            class="Betting__C-numC-item9"
          ></div>
        </div>

        <div data-v-0aa493a0="" class="Betting__C-multiple">
          <div data-v-0aa493a0="" class="Betting__C-multiple-l">
            Random
          </div>
          <div data-v-0aa493a0="" class="Betting__C-multiple-r active">
            X1
          </div>
          <div data-v-0aa493a0="" class="Betting__C-multiple-r">
            X5
          </div>
          <div data-v-0aa493a0="" class="Betting__C-multiple-r">
            X10
          </div>
          <div data-v-0aa493a0="" class="Betting__C-multiple-r">
            X20
          </div>
          <div data-v-0aa493a0="" class="Betting__C-multiple-r">
            X50
          </div>
          <div data-v-0aa493a0="" class="Betting__C-multiple-r">
            X100
          </div>
        </div>

        <div data-v-0aa493a0="" class="Betting__C-foot">
          <div
            data-v-0aa493a0=""
            class="Betting__C-foot-b"
            onClick={() => handleNumberClick("big")}
          >
            Big
          </div>
          <div
            data-v-0aa493a0=""
            class="Betting__C-foot-s"
            onClick={() => handleNumberClick("small")}
          >
            Small
          </div>
        </div>

        <div
          class="van-overlay"
          data-v-7f36fe93=""
          style={{ zIndex: 2003, display: showPopup }}
        ></div>
        <div
          role="dialog"
          tabindex="0"
          class="van-popup van-popup--round van-popup--bottom"
          data-v-7f36fe93=""
          style={{ zIndex: 2003, display: showPopup }}
        >
          <div data-v-7f36fe93="" class="Betting__Popup-0">
            <div data-v-7f36fe93="" class="Betting__Popup-head">
              <div data-v-7f36fe93="" class="Betting__Popup-head-title">
                Win Go 1Min
              </div>
              <div data-v-7f36fe93="" class="Betting__Popup-head-selectName">
                <span data-v-7f36fe93="">Select</span>
                <span data-v-7f36fe93="">{selects}</span>
              </div>
            </div>

            <div data-v-7f36fe93="" class="Betting__Popup-body">
              <div data-v-7f36fe93="" class="Betting__Popup-body-line">
                Balance
                <div data-v-7f36fe93="" class="Betting__Popup-body-line-list">
                  <div
                    data-v-7f36fe93=""
                    onClick={() => handleNumberClickp("1")}
                    class="Betting__Popup-body-line-item bgcolor"
                  >
                    1
                  </div>
                  <div
                    data-v-7f36fe93=""
                    onClick={() => handleNumberClickp("10")}
                    class="Betting__Popup-body-line-item"
                  >
                    10
                  </div>
                  <div
                    data-v-7f36fe93=""
                    onClick={() => handleNumberClickp("100")}
                    class="Betting__Popup-body-line-item"
                  >
                    100
                  </div>
                  <div
                    data-v-7f36fe93=""
                    onClick={() => handleNumberClickp("1000")}
                    class="Betting__Popup-body-line-item"
                  >
                    1000
                  </div>
                </div>
              </div>

              <div data-v-7f36fe93="" class="Betting__Popup-body-line">
                Quantity
                <div data-v-7f36fe93="" class="Betting__Popup-body-line-btnL">
                  <div data-v-7f36fe93="" class="Betting__Popup-btn bgcolor">
                    -
                  </div>
                  <div
                    data-v-7f36fe93=""
                    class="van-cell van-field Betting__Popup-input"
                    modelmodifiers="[object Object]"
                  >
                    <div class="van-cell__value van-field__value">
                      <div class="van-field__body">
                        <input
                          type="tel"
                          value={quantity}
                          onChange={(e) => setquantity(e.target.value)}
                          inputmode="numeric"
                          id="van-field-1-input"
                          class="van-field__control"
                          fdprocessedid="ri7m0i"
                        />
                      </div>
                    </div>
                  </div>
                  <div data-v-7f36fe93="" class="Betting__Popup-btn bgcolor">
                    +
                  </div>
                </div>
              </div>

              <div data-v-7f36fe93="" class="Betting__Popup-body-line">
                <div data-v-7f36fe93=""></div>
                <div data-v-7f36fe93="" class="Betting__Popup-body-line-list">
                  <div
                    data-v-7f36fe93=""
                    onClick={() => handleClickp("1")}
                    class="Betting__Popup-body-line-item bgcolor"
                  >
                    X1
                  </div>
                  <div
                    data-v-7f36fe93=""
                    onClick={() => handleClickp("5")}
                    class="Betting__Popup-body-line-item"
                  >
                    X5
                  </div>
                  <div
                    data-v-7f36fe93=""
                    onClick={() => handleClickp("10")}
                    class="Betting__Popup-body-line-item"
                  >
                    X10
                  </div>
                  <div
                    data-v-7f36fe93=""
                    onClick={() => handleClickp("20")}
                    class="Betting__Popup-body-line-item"
                  >
                    X20
                  </div>
                  <div
                    data-v-7f36fe93=""
                    onClick={() => handleClickp("50")}
                    class="Betting__Popup-body-line-item"
                  >
                    X50
                  </div>
                  <div
                    data-v-7f36fe93=""
                    onClick={() => handleClickp("100")}
                    class="Betting__Popup-body-line-item"
                  >
                    X100
                  </div>
                </div>
              </div>

              <div data-v-7f36fe93="" class="Betting__Popup-body-line">
                <span data-v-7f36fe93="" class="Betting__Popup-agree active">
                  I agree
                </span>
                <span data-v-7f36fe93="" class="Betting__Popup-preSaleShow">
                  《Pre-sale rules》
                </span>
              </div>
            </div>

            <div data-v-7f36fe93="" class="Betting__Popup-foot">
              <div
                data-v-7f36fe93=""
                onClick={() => betcencel()}
                class="Betting__Popup-foot-c"
              >
                Cancel
              </div>
              <div
                data-v-7f36fe93=""
                onClick={() => bethandel()}
                class="Betting__Popup-foot-s bgcolor"
              >
                Total amount ₹₹{total * quantity}.00
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <audio id="voice1">
        <source src="https://damanclub.in/assets/mp3/di1-0f3d86cb.mp3" type="audio/mpeg" />
      </audio>
      <audio id="voice2">
        <source src="https://damanclub.in/assets/mp3/di2-ad9aa8fb.mp3" type="audio/mpeg" />
      </audio> */}

      <div
        role="dialog"
        tabindex="0"
        class="van-popup van-popup--center van-toast van-toast--middle van-toast--break-word van-toast--fail"
        style={{ zIndex: 2010, display: notfund }}
      >
        <i class="van-badge__wrapper van-icon van-icon-fail van-toast__icon"></i>
        <div class="van-toast__text">Error: 142 Insufficient balance</div>
      </div>
    </>
  );
}

export default Wingobetting;
