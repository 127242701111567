import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { MyContext } from '../Context/MyContext';

function Withdraw() {
    const navigate = useNavigate();
    const context = useContext(MyContext);


    useEffect(() => {
      const token = localStorage.getItem("token");
      if (!token) {
        navigate("/signup");
      }
    }, [navigate]);


    const {usebalance} = context;
  return (
    <>
    
    <div data-v-e62caf16="" class="withdraw__container" style={{ "--76181987": "'Roboto', 'Inter', sans-serif" }}
    >
            <div data-v-2d3dc984="" data-v-e62caf16="" class="navbar">
                <div data-v-2d3dc984="" class="navbar-fixed" style={{ background: "rgb(247, 248, 255)" }}
                >
                    <div data-v-2d3dc984="" class="navbar__content">
                        <div data-v-2d3dc984="" class="navbar__content-left"><i data-v-2d3dc984="" onClick={() => navigate(-1)}
                                class="van-badge__wrapper van-icon van-icon-arrow-left"></i></div>
                        <div data-v-2d3dc984="" class="navbar__content-center">
                            <div data-v-2d3dc984="" class="navbar__content-title">Withdraw</div>
                        </div>
                        <div data-v-2d3dc984="" class="navbar__content-right"><span data-v-e62caf16="">Withdrawal
                                history</span></div>
                    </div>
                </div>
            </div>
            <div data-v-e62caf16="" class="withdraw__container-content">
                <div data-v-dea47a8d="" data-v-e62caf16="" class="balanceAssets">
                    <div data-v-dea47a8d="" class="balanceAssets__header">
                        <div data-v-dea47a8d="" class="balanceAssets__header__left"><img data-v-dea47a8d=""
                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABICAMAAABiM0N1AAAArlBMVEUAAAD/sjH/zRj/zR//1zbuR0f/zBb/zRf/zhr/zRn/+HfuSUn/zBn/+njvSUn/zCH/+Xf/+XX/+HT/+nj/zBn/zRr/pSL/20H/2T3/1Db/zBf/30j/1zn/7mP/8Gb/3UT/9nL/9W//8mn/82z/7GDuSEj/+Xf/0zP/5lX/5FL/4Uz/6Vv/6Fj/40//613/+HT/vTT/2033kj3/xzz+yzX/rSj/sSr/0kH/sSz/rym/OY7sAAAAFnRSTlMAIsBCE+/v3IFxkZGRgYEy7+/v7rBhR1ORgAAAAh9JREFUWMPtkuly2jAQgA3hyJ2e1BKyMYGC7dbYXEn6/i/W1QqNughsI03+dPIh7SXzDSMcfPD/czXsQfTmqcv5tb+md88lHV/PzYAjXd/L4ZorD02nyw1D98u55oQb98uhDHpOl9PlFvcOl/ONn+Lp4ssZWA6XV+D70DI0vwIPnxZOfL2lnttFA6VV6vqBiL6UNouyFZ+JKDlNWSY1HA6pyB0qmgIJbJOxSnAnZowBk4GK3KGimWI6lcEs7LDChP3hMQMVuUNFz4rZIet2Rqa6PJpSkROxLYrj5xi3A1Rk8BZFcSQXFpoYw+FAT2IcmIeIKHKHiubRfC43xkguDIAa6KkeSXSkIg+IaDVfwWi1khtrALKu1AEs1aisayq6gPV2PyJ0AsOv1qSVMfiI0rdRreh3W6pRa1Gqc0pHyHrUIErboS5om63ffEUv8L0K8vqcKCOk2Tn2UiSLelEz8hft8yzbnhMVRZHBByKEGvCO9ltI50R5cZLj+WvTv5YXuYE2lKpJ1JbxS71onI81WJGGVlW96AJeqz81Ig+Cf5l48D6iH8gEtiomWE90g6VusMOEWCJnbFGIERMl1DmUoVYUevBOIiFECAFPhGyELDDgR00EgqXujkTCAyJiMGAQIApmSgOjpQ7MEl2KMCUR7ZgzOyLasCVjyyUsCTMBUYeqx83wXLEhov5mt3Rit+kT0eNPZ+4Cwl3fTdN/DD5oy19QoJEcy1l/8AAAAABJRU5ErkJggg=="/>Available
                            balance</div>
                    </div>
                    <div data-v-dea47a8d="" class="balanceAssets__main">
                        <p data-v-dea47a8d="">₹{usebalance}</p><img data-v-dea47a8d=""
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFkAAAA7CAMAAAAn6dbMAAAAOVBMVEUAAAD///////////////////////////////////////////////////////////////////////8KOjVvAAAAEnRSTlMAIN9AgO8QMM9gcKC/kK9Qj29ZmZoEAAAB7klEQVRYw93Y23LjIAwGYHE+OU5W7/+wO2M1/r31QAzBN6uLTqd0voAEyA79H6EWvhAurtZ0ypEvhy6qR+au6LGXPtrl63nW3BfFTy2zMnlfXFTTd9FD/9CepttF6IXmRxY60/xIQgeaH+m2fNBjow3ND++uTNpka81YFZs7L8jm18+RSedL5zoNZLo0PlrggXo8t5XWx1dGuNCVjnaiLfMwvSVaVQb/MI/TupFBJZzsDfmppshKNDmpIYL+WhbLShNUCrP+Vn5t0ko/Mn2kfTIN2RuFbSHOW/5EB8dsK7KMZmx0dop2GbSv93d7llEzd6weQcZfo6/mFTRkDOJQW4L8XlOVznykIaNmej/UK/2S27QVOp9k1Ay/nWQyvMVabyScfssBbTG+q3eWKXH9qimgD7LSWMsi42cZNFGDDpBR2tPWhHwslW3eui4c5Nc/fVw9A1VlUslUz2HcaZFRM0RNlmjRIokccOK+lEFvcpIkP+l7GXeA4z0sTZBBIx7NizbQMK1988Uq0RDd7hVFVjRKhw+PaJEG6dz6L3lCGqKdwM1EF+oMY8sjeWqGRb5uecyOs1lM+jUfRlucH4Zvo+1Nb9PoQ1zUbTQv2UzWLXdE39tY0n208x3fXfTRpuu7i555q96LZonXknFuFn8BRodWROohzGIAAAAASUVORK5CYII="
                            alt=""/>
                    </div>
                    <div data-v-dea47a8d="" class="balanceAssets__tip"><img data-v-dea47a8d=""
                            src="https://damangames.inhttps://damangames.in/assets/png/cip-7ed1a634.png" alt=""/></div>
                </div>
                <div data-v-46c32785="" data-v-e62caf16="" class="withdrawWay">
                    <div data-v-46c32785="" class="select">
                        <div data-v-46c32785=""><img data-v-46c32785=""
                                src="https://ossimg.envyenvelope.com/daman/payNameIcon/WithBeforeImgIcon2_20230914130035ws2w.png"/>
                        </div><span data-v-46c32785=""> BANK CARD</span>
                    </div>
                    <div data-v-46c32785="" class="">
                        <div data-v-46c32785=""><img data-v-46c32785=""
                                src="https://ossimg.envyenvelope.com/daman/payNameIcon/WithBeforeImgIcon_20230914130048fq13.png"/>
                        </div><span data-v-46c32785="">USDT</span>
                    </div>
                </div>
                <div data-v-e62caf16="" class="bankInfo">
                    <div data-v-e62caf16="" class="bankInfoItem">
                        <div data-v-e62caf16=""><img data-v-e62caf16=""
                                src="https://damangames.inhttps://damangames.in/assets/png/1-4618686f.png"/><span data-v-e62caf16="">Indusind
                                Bank</span></div>
                        <div data-v-e62caf16=""><span data-v-e62caf16=""></span><span
                                data-v-e62caf16="">159990****391</span></div><i data-v-e62caf16=""
                            class="van-badge__wrapper van-icon van-icon-arrow"></i>
                    </div>
                </div>
                <div data-v-e04a3713="" data-v-e62caf16="" class="addWithdrawType" style={{display: "none"}}>
                    <div data-v-e04a3713="" class="addWithdrawType-top"><img data-v-e04a3713=""
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAALAAAACwCAMAAACYaRRsAAAAM1BMVEUAAAC3u8+4vNC5vNC5vdG4vNC3us+3t8+4vNC2vM+5vNG4vM+vv8+vr8+5vNC4vNC4vNB5o5b1AAAAEHRSTlMAQN/vj59gIM+QX5AQEK+/+pPJwgAAAbpJREFUeNrt3MuSokAQRuGsKkS8/+//tMPEbCYygDCbjs7CPt8a5QjKAos0AAAAAMAHKsPUtGywRdeV7evVFg1aMZ3OFvV4akOxJVUrmi0p2hBNPlfFg5tW1Hiw6vkbey+2aFx5VRtt0W27+B4IPmk2lYelePz75M/AAdbsZIlOmpXQ5tVSvdw3b9skabRURdJk79Lsbqnukloo2BL4BoL/QzDBDsEEOwQT7BBMsEMwwQ7BBDsEf3bwTbpYonhDKZauhwb84hM6SIMdSQ8XRoK7QrBDMMEOwQQ7BBPsEEzwtmur18TgeEOVWkawb9i/u17fgWCCCSa4q90RvGzQDsPPBxftUgh+41+THS786AgmmGCC+9ndLwxuUk0MjjeMtY0JwbGGQ9+qIrh/BDsEE+wQTLBDMMEOwQR/0aHWeH/2clwc4ISWcqz11X8bDnVR+uwnZQgmmGCCCSaYYIdggh2CCXYIJtghONTQ+hgjqdCgzmKpxtCgzpukl6WqoemxpY9hs2d721OzOmaN8y2v6BG7Vzk/MEN774jnhBnavjdUfMqdof18mFk0eUqaod2mSwc31AEAAAAA3+4PU1FviV2McX8AAAAASUVORK5CYII="/><span
                            data-v-e04a3713="">Add a bank account number</span></div>
                    <div data-v-e04a3713="" class="addWithdrawType-text">Need to add beneficiary information to be able
                        to withdraw money</div>
                </div>
                <div data-v-eee9adef="" class="explain" style={{}}>
                    <div data-v-eee9adef="" class="input">
                        <div data-v-eee9adef="" class="place-div">₹</div><input data-v-eee9adef=""
                            placeholder="Please enter the amount" class="inp" fdprocessedid="joznq"/>
                    </div>
                    <div data-v-eee9adef="" class="balance bank">
                        <div data-v-eee9adef=""><span data-v-eee9adef="">Withdrawable balance <h6 data-v-eee9adef=""
                                    class="red">₹{usebalance}</h6></span><input data-v-eee9adef="" type="button" value="All"
                                fdprocessedid="zdn9t"/></div>
                        <div data-v-eee9adef=""><span data-v-eee9adef="">Withdrawal amount received</span>
                            <div data-v-eee9adef="" class="rightD"><span data-v-eee9adef="" class="yellow">₹0.00</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div data-v-eee9adef="" class="explain usdt" style={{display: "none"}}>
                    <div data-v-eee9adef="" class="head"><img data-v-eee9adef=""
                            src="https://damangames.inhttps://damangames.in/assets/png/1-bcd21d34.png"/></div>
                    <div data-v-eee9adef="" class="input">
                        <div data-v-eee9adef="" class="place-div">₹</div><input data-v-eee9adef="" type="number"
                            placeholder="Please enter withdrawal amount" class="inp"/>
                    </div>
                    <div data-v-eee9adef="" class="balance usdt">
                        <div data-v-eee9adef=""><span data-v-eee9adef="">Withdrawable balance <h6 data-v-eee9adef=""
                                    class="yellow">₹{usebalance}</h6></span><input data-v-eee9adef="" type="button" value="All"/>
                        </div>
                    </div>
                </div>
                <div data-v-e62caf16="" class="recycleBtnD"><button data-v-e62caf16="" class="recycleBtn"
                        fdprocessedid="aiqjvc">Withdraw</button></div>
                <div data-v-bf646bda="" data-v-e62caf16="" class="Recharge__container-intro">
                    <p data-v-bf646bda="">Need to bet <span data-v-470caa86="" class="red">₹0.00</span> to be able to
                        withdraw</p>
                    <p data-v-bf646bda="">Withdraw time <span data-v-bf646bda="" class="red">00:00-23:59</span></p>
                    <p data-v-bf646bda="">Inday Remaining Withdrawal Times<span data-v-bf646bda="" class="red">3</span>
                    </p>
                    <p data-v-bf646bda="">Withdrawal amount range <span data-v-bf646bda=""
                            class="red">₹110.00-₹10,000,000.00</span></p>
                    <p data-v-bf646bda="">Please confirm your beneficial account information before withdrawing. If your
                        information is incorrect, our company will not be liable for the amount of loss</p>
                    <p data-v-bf646bda="">If your beneficial information is incorrect, please contact customer service
                    </p>
                </div>
                <div data-v-c8ab40aa="" data-v-e62caf16="" class="rechargeh__container">
                    <div data-v-c8ab40aa="" class="rechargeh__container-head"><img data-v-c8ab40aa=""
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgBAMAAAAQtmoLAAAAJ1BMVEUAAAD/cXL/cXL/cHD/cXH/cXL/cHD/cXL/////9vf/pqf/7e7/7e3ZVqe2AAAAB3RSTlMA799gX7cgdf8tvgAAAIpJREFUWMPt06ENgEAMheFTLIBggUvwbMAIKDQjgOkKEBYgbEBYhDAVtqLX9ASEpO/3n2leA7JXdJQuCmAgpVEAPWkJgPJBSAbwNWhJKQqgVhclAFIDAAAASIFjZt0GcHGwGMDGwWoAJwf7L64E4AXgHwC8A9YroMoFjR3wALwAdVGTANRFlQGZewAgtne9HOEfwgAAAABJRU5ErkJggg=="/>
                        <h1 data-v-c8ab40aa="">Withdrawal history</h1>
                    </div>
                    <div data-v-c8ab40aa="" class="rechargeh__container-content">
                        <div data-v-c8ab40aa="" class="rechargeh__container-content__item">
                            <div data-v-c8ab40aa="" class="rechargeh__container-content__item-header ar-1px-b"><span
                                    data-v-c8ab40aa="">Withdraw</span><span data-v-c8ab40aa="" class="stateG">Completed
                                </span></div>
                            <div data-v-c8ab40aa="" class="rechargeh__container-content__item-body">
                                <div data-v-c8ab40aa=""><span data-v-c8ab40aa="">Balance</span><span
                                        data-v-c8ab40aa="">₹300.00</span></div>
                                <div data-v-c8ab40aa=""><span data-v-c8ab40aa="">Type</span><span data-v-c8ab40aa="">
                                        BANK CARD</span></div>
                                <div data-v-c8ab40aa=""><span data-v-c8ab40aa="">Time</span><span
                                        data-v-c8ab40aa="">2024-06-05 04:03:50</span></div>
                                <div data-v-c8ab40aa=""><span data-v-c8ab40aa="">Order number</span><span
                                        data-v-c8ab40aa="">WD2024060504035057047416c</span><img data-v-c8ab40aa=""
                                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAANlBMVEUAAABlZWVnZ2dlZWVmZmZgYGBlZWVkZGRgYGBmZmZoaGhmZmZmZmZlZWVkZGRmZmZwcHBmZmYn3H9lAAAAEXRSTlMAYJ/v3xC/QCCAIK+QcFDPEFwncHAAAADVSURBVFjD7dY7EsMgDEVRia//ydv/ZhOlyYxHChq7SaHTAhdsGiio6sEjvZJpanDIs7k+w+dhHKLASz8Dy1BLA6tsk9UjbAASDVX50E6KBIBJoc2LwGnesSVRft0iT2ZgKvDIux6Q9U67HljhlasWmGQo8cie5QhaoMufo7FZ9tEC/BkY4whEIAIRiMB/BhqAfinQWCS8LZcCXyvdC5TlVkDWXwuUJLaDxNVbEBE46crb23ocbubbu6xpoMF8hc7wKqSqD/jkhQxzhkNbyPTsPHI8KWheb7c1z9Bcc9kAAAAASUVORK5CYII="/>
                                </div>
                            </div>
                        </div>
                        <div data-v-c8ab40aa="" class="rechargeh__container-content__item">
                            <div data-v-c8ab40aa="" class="rechargeh__container-content__item-header ar-1px-b"><span
                                    data-v-c8ab40aa="">Withdraw</span><span data-v-c8ab40aa="" class="stateG">Completed
                                </span></div>
                            <div data-v-c8ab40aa="" class="rechargeh__container-content__item-body">
                                <div data-v-c8ab40aa=""><span data-v-c8ab40aa="">Balance</span><span
                                        data-v-c8ab40aa="">₹450.00</span></div>
                                <div data-v-c8ab40aa=""><span data-v-c8ab40aa="">Type</span><span data-v-c8ab40aa="">
                                        BANK CARD</span></div>
                                <div data-v-c8ab40aa=""><span data-v-c8ab40aa="">Time</span><span
                                        data-v-c8ab40aa="">2024-06-01 01:02:26</span></div>
                                <div data-v-c8ab40aa=""><span data-v-c8ab40aa="">Order number</span><span
                                        data-v-c8ab40aa="">WD2024060101022690478879d</span><img data-v-c8ab40aa=""
                                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAANlBMVEUAAABlZWVnZ2dlZWVmZmZgYGBlZWVkZGRgYGBmZmZoaGhmZmZmZmZlZWVkZGRmZmZwcHBmZmYn3H9lAAAAEXRSTlMAYJ/v3xC/QCCAIK+QcFDPEFwncHAAAADVSURBVFjD7dY7EsMgDEVRia//ydv/ZhOlyYxHChq7SaHTAhdsGiio6sEjvZJpanDIs7k+w+dhHKLASz8Dy1BLA6tsk9UjbAASDVX50E6KBIBJoc2LwGnesSVRft0iT2ZgKvDIux6Q9U67HljhlasWmGQo8cie5QhaoMufo7FZ9tEC/BkY4whEIAIRiMB/BhqAfinQWCS8LZcCXyvdC5TlVkDWXwuUJLaDxNVbEBE46crb23ocbubbu6xpoMF8hc7wKqSqD/jkhQxzhkNbyPTsPHI8KWheb7c1z9Bcc9kAAAAASUVORK5CYII="/>
                                </div>
                            </div>
                        </div>
                        <div data-v-c8ab40aa="" class="rechargeh__container-content__item">
                            <div data-v-c8ab40aa="" class="rechargeh__container-content__item-header ar-1px-b"><span
                                    data-v-c8ab40aa="">Withdraw</span><span data-v-c8ab40aa="" class="stateG">Completed
                                </span></div>
                            <div data-v-c8ab40aa="" class="rechargeh__container-content__item-body">
                                <div data-v-c8ab40aa=""><span data-v-c8ab40aa="">Balance</span><span
                                        data-v-c8ab40aa="">₹200.00</span></div>
                                <div data-v-c8ab40aa=""><span data-v-c8ab40aa="">Type</span><span data-v-c8ab40aa="">
                                        BANK CARD</span></div>
                                <div data-v-c8ab40aa=""><span data-v-c8ab40aa="">Time</span><span
                                        data-v-c8ab40aa="">2024-05-26 03:53:58</span></div>
                                <div data-v-c8ab40aa=""><span data-v-c8ab40aa="">Order number</span><span
                                        data-v-c8ab40aa="">WD2024052603535821677236g</span><img data-v-c8ab40aa=""
                                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAANlBMVEUAAABlZWVnZ2dlZWVmZmZgYGBlZWVkZGRgYGBmZmZoaGhmZmZmZmZlZWVkZGRmZmZwcHBmZmYn3H9lAAAAEXRSTlMAYJ/v3xC/QCCAIK+QcFDPEFwncHAAAADVSURBVFjD7dY7EsMgDEVRia//ydv/ZhOlyYxHChq7SaHTAhdsGiio6sEjvZJpanDIs7k+w+dhHKLASz8Dy1BLA6tsk9UjbAASDVX50E6KBIBJoc2LwGnesSVRft0iT2ZgKvDIux6Q9U67HljhlasWmGQo8cie5QhaoMufo7FZ9tEC/BkY4whEIAIRiMB/BhqAfinQWCS8LZcCXyvdC5TlVkDWXwuUJLaDxNVbEBE46crb23ocbubbu6xpoMF8hc7wKqSqD/jkhQxzhkNbyPTsPHI8KWheb7c1z9Bcc9kAAAAASUVORK5CYII="/>
                                </div>
                            </div>
                        </div>
                        <div data-v-c8ab40aa="" class="rechargeh__container-content__item">
                            <div data-v-c8ab40aa="" class="rechargeh__container-content__item-header ar-1px-b"><span
                                    data-v-c8ab40aa="">Withdraw</span><span data-v-c8ab40aa="" class="stateG">Completed
                                </span></div>
                            <div data-v-c8ab40aa="" class="rechargeh__container-content__item-body">
                                <div data-v-c8ab40aa=""><span data-v-c8ab40aa="">Balance</span><span
                                        data-v-c8ab40aa="">₹110.00</span></div>
                                <div data-v-c8ab40aa=""><span data-v-c8ab40aa="">Type</span><span data-v-c8ab40aa="">
                                        BANK CARD</span></div>
                                <div data-v-c8ab40aa=""><span data-v-c8ab40aa="">Time</span><span
                                        data-v-c8ab40aa="">2024-05-25 15:46:31</span></div>
                                <div data-v-c8ab40aa=""><span data-v-c8ab40aa="">Order number</span><span
                                        data-v-c8ab40aa="">WD2024052515463114756413f</span><img data-v-c8ab40aa=""
                                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAANlBMVEUAAABlZWVnZ2dlZWVmZmZgYGBlZWVkZGRgYGBmZmZoaGhmZmZmZmZlZWVkZGRmZmZwcHBmZmYn3H9lAAAAEXRSTlMAYJ/v3xC/QCCAIK+QcFDPEFwncHAAAADVSURBVFjD7dY7EsMgDEVRia//ydv/ZhOlyYxHChq7SaHTAhdsGiio6sEjvZJpanDIs7k+w+dhHKLASz8Dy1BLA6tsk9UjbAASDVX50E6KBIBJoc2LwGnesSVRft0iT2ZgKvDIux6Q9U67HljhlasWmGQo8cie5QhaoMufo7FZ9tEC/BkY4whEIAIRiMB/BhqAfinQWCS8LZcCXyvdC5TlVkDWXwuUJLaDxNVbEBE46crb23ocbubbu6xpoMF8hc7wKqSqD/jkhQxzhkNbyPTsPHI8KWheb7c1z9Bcc9kAAAAASUVORK5CYII="/>
                                </div>
                            </div>
                        </div>
                        <div data-v-c8ab40aa="" class="rechargeh__container-content__item">
                            <div data-v-c8ab40aa="" class="rechargeh__container-content__item-header ar-1px-b"><span
                                    data-v-c8ab40aa="">Withdraw</span><span data-v-c8ab40aa="" class="stateG">Completed
                                </span></div>
                            <div data-v-c8ab40aa="" class="rechargeh__container-content__item-body">
                                <div data-v-c8ab40aa=""><span data-v-c8ab40aa="">Balance</span><span
                                        data-v-c8ab40aa="">₹200.00</span></div>
                                <div data-v-c8ab40aa=""><span data-v-c8ab40aa="">Type</span><span data-v-c8ab40aa="">
                                        BANK CARD</span></div>
                                <div data-v-c8ab40aa=""><span data-v-c8ab40aa="">Time</span><span
                                        data-v-c8ab40aa="">2024-05-15 04:35:37</span></div>
                                <div data-v-c8ab40aa=""><span data-v-c8ab40aa="">Order number</span><span
                                        data-v-c8ab40aa="">WD2024051504353785749575e</span><img data-v-c8ab40aa=""
                                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAANlBMVEUAAABlZWVnZ2dlZWVmZmZgYGBlZWVkZGRgYGBmZmZoaGhmZmZmZmZlZWVkZGRmZmZwcHBmZmYn3H9lAAAAEXRSTlMAYJ/v3xC/QCCAIK+QcFDPEFwncHAAAADVSURBVFjD7dY7EsMgDEVRia//ydv/ZhOlyYxHChq7SaHTAhdsGiio6sEjvZJpanDIs7k+w+dhHKLASz8Dy1BLA6tsk9UjbAASDVX50E6KBIBJoc2LwGnesSVRft0iT2ZgKvDIux6Q9U67HljhlasWmGQo8cie5QhaoMufo7FZ9tEC/BkY4whEIAIRiMB/BhqAfinQWCS8LZcCXyvdC5TlVkDWXwuUJLaDxNVbEBE46crb23ocbubbu6xpoMF8hc7wKqSqD/jkhQxzhkNbyPTsPHI8KWheb7c1z9Bcc9kAAAAASUVORK5CYII="/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div data-v-c8ab40aa="" class="rechargeh__container-footer"><button data-v-c8ab40aa=""
                            fdprocessedid="eziwc9">All history</button></div>
                </div>
            </div>
            <div data-v-cf21bf5a="" data-v-e62caf16="" class="dialog inactive c2c">
                <div data-v-cf21bf5a="" class="dialog__container" role="dialog" tabindex="0">
                    <div data-v-cf21bf5a="" class="dialog__container-img"><img data-v-e62caf16="" class="succeedImg"
                            data-origin="https://damangames.inhttps://damangames.in/assets/png/succeed-83674414.png"
                            src="https://damangames.inhttps://damangames.in/assets/png/succeed-83674414.png"/></div>
                    <div data-v-cf21bf5a="" class="dialog__container-title">
                        <h1 data-v-cf21bf5a="">C2C withdrawal successful</h1>
                    </div>
                    <div data-v-cf21bf5a="" class="dialog__container-content">
                        <div data-v-e62caf16="" class="c2cTip">
                            <h1 data-v-e62caf16="">Please come back and click [Confirm Payment] after receiving the
                                transfer</h1>
                            <p data-v-e62caf16="">C2C withdrawal rewards will be automatically issued after you click
                                <span>[Confirm Arrival]</span>!</p>
                        </div>
                    </div>
                    <div data-v-cf21bf5a="" class="dialog__container-footer"><button
                            data-v-cf21bf5a="">Confirm</button></div>
                </div>
                <div data-v-cf21bf5a="" class="dialog__outside"></div>
            </div>
            <div data-v-cf21bf5a="" data-v-e62caf16="" class="dialog inactive">
                <div data-v-cf21bf5a="" class="dialog__container" role="dialog" tabindex="0">
                    <div data-v-cf21bf5a="" class="dialog__container-img"><img data-v-cf21bf5a="" alt="" class=""
                            data-origin="https://damangames.inhttps://damangames.in/assets/png/orderCancelWarn-ac58c333.png"
                            src="https://damangames.inhttps://damangames.in/assets/png/orderCancelWarn-ac58c333.png"/></div>
                    <div data-v-cf21bf5a="" class="dialog__container-title">
                        <h1 data-v-cf21bf5a=""></h1>
                    </div>
                    <div data-v-cf21bf5a="" class="dialog__container-content">
                        <h1 data-v-e62caf16="">You must recharge to enable the withdrawal function</h1>
                    </div>
                    <div data-v-cf21bf5a="" class="dialog__container-footer"><button
                            data-v-cf21bf5a="">Confirm</button></div><img data-v-cf21bf5a="" class="close_img"
                        src="https://damangames.inhttps://damangames.in/assets/png/close-84ce5e6a.png"/>
                </div>
                <div data-v-cf21bf5a="" class="dialog__outside"></div>
            </div>
        </div>
    
    </>
  )
}

export default Withdraw
