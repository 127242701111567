import React, { useContext, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { MyContext } from '../Context/MyContext';

function Account() {

    const context = useContext(MyContext)

    const {usebalance} = context;


    const navigate =useNavigate();

    const logout = () => {

            localStorage.removeItem("token");

            navigate('/login')
    }

 

    useEffect(() => {
      const token = localStorage.getItem("token");
      if (!token) {
        navigate("/signup");
      }
    }, [navigate]);

  return (
   <>

<div data-v-5b1caab5="" data-v-5768d7f3="" class="userInfo__container"
            style={{ fontFamily: "'Roboto', 'Inter', sans-serif" }}
>
            <div data-v-2d3dc984="" data-v-5b1caab5="" class="navbar" style={{display: "none"}}>
                <div data-v-2d3dc984="" class="navbar-fixed wc"
                    style={{ background: "linear-gradient(90deg, rgb(249, 90, 90) 0%, rgb(255, 153, 142) 100%)" }}
>
                    <div data-v-2d3dc984="" class="navbar__content">
                        <div data-v-2d3dc984="" class="navbar__content-left"><i data-v-2d3dc984=""
                                class="van-badge__wrapper van-icon van-icon-arrow-left"></i></div>
                        <div data-v-2d3dc984="" class="navbar__content-center">
                            <div data-v-2d3dc984="" class="navbar__content-title">Settings Center</div>
                        </div>
                        <div data-v-2d3dc984="" class="navbar__content-right"></div>
                    </div>
                </div>
            </div>
            <div data-v-5b1caab5="" class="userInfo__container-content">
                <div data-v-5b1caab5="" class="userInfo__container-content-wrapper">
                    <div data-v-5b1caab5="" class="userInfo__container-content__avatar"><img data-v-5b1caab5=""
                            src="https://damanclub.in/assets/png/15-80f41fc6.png" class="userAvatar"/></div>
                    <div data-v-5b1caab5="" class="userInfo__container-content__name">
                        <div data-v-5b1caab5="" class="userInfo__container-content-nickname">
                            <h3 data-v-5b1caab5="">MEMBERNNGDGB2S</h3>
                            <div data-v-5b1caab5="" class="n2"></div><img data-v-5b1caab5="" class="editPencil"
                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABICAMAAABiM0N1AAAAUVBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////8IN+deAAAAGnRSTlMAZjN/DllfHwa/Jm8sPxnvn0oT38+PTjhAr4Ga/9wAAAE+SURBVFjD7dfBboMwEEVRG8YGEwiBBJL6/z+0JU31FAQRnpcuWnE33h15xmwwe/+9Muu8dV3FMo195Cjq6C3qRO1k9iknrAOJdSCRDqSSc9A11SnsSsfUh1+DukSo9GtS6paua1Cx3TjIq9nqzU4eD9MxrkDZdieG6RTlaHC+al5sqU9w4uX+cG7JcSlO/N5S7xegKsmJ7X0TlfJCcB77NrWdJxsdNCxKdZqDNZnepX1DcJA3U3LjHNzJVE7poNyA0jho+Hmk4uY0Djo3BmkcdCpoBxTpoFCQDl5vQ55zUOAc1L7JEc5BDeegkXNQzjnog3NQ4BzUvskRzkEN56CRc1BOOoA4B3nOQUI6aOAcJCGi8+WUezHKfGgn4eAzMXu/VllnqiqZQc4qc/NfMnX982R6aDZbppzN12bv7/YJ4RFYNVkqAPgAAAAASUVORK5CYII="
                                style={{display: "none"}}/>
                        </div>
                        <div data-v-5b1caab5="" class="userInfo__container-content-uid"><span
                                data-v-5b1caab5="">UID</span><span data-v-5b1caab5="">|</span><span
                                data-v-5b1caab5="">3391595</span><img data-v-5b1caab5=""
                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYBAMAAAASWSDLAAAAG1BMVEUAAAD////////////////////////////////rTT7CAAAACHRSTlMAf2/fzyAQv1i/o5gAAABWSURBVBjTY0AHSYJgoApis1p0gEFzApDD1AKR8VAAchgbIaolBIA6xNvBOkAchA4gB6EDyEHooBOn0EMBweloDkCSUUP2ALrX2GBeCADJB0Fk1NCDCQANHCEHp1BAOwAAAABJRU5ErkJggg=="/>
                        </div>
                        <div data-v-5b1caab5="" class="userInfo__container-content-logintime"><span
                                data-v-5b1caab5="">Last login:&nbsp;</span><span data-v-5b1caab5="">2024-06-06
                                00:55:09</span></div>
                        <div data-v-5b1caab5="" class="userInfo__container-content-logintime" style={{display: "none"}}>
                            <span data-v-5b1caab5="">&nbsp;</span></div>
                    </div>
                </div>
                <div data-v-5b1caab5="" class="userInfo__container-content-right" style={{display: "none"}}>
                    <h5 data-v-5b1caab5="">Change avatar</h5>
                </div>
            </div>
            <div data-v-5b1caab5="" class="userInfo__container-setting-center" style={{display: "none"}}>
                <div data-v-5b1caab5="" class="userInfo__container-setting-center-header">
                    <div data-v-5b1caab5="" class="userInfo__container-content__avatar"><img data-v-5b1caab5=""
                            data-img="https://damanclub.in/assets/png/avatar1-2f23f3bd.png" class="ar-lazyload"
                            data-origin="https://damanclub.in/assets/png/15-80f41fc6.png"/></div>
                    <div data-v-5b1caab5="" class="userInfo__container-setting-center-header-edit"><span
                            data-v-5b1caab5="">Change avatar</span><i data-v-5b1caab5=""
                            class="van-badge__wrapper van-icon van-icon-arrow"
                            style={{color: "rgb(136, 136, 136)"}}></i></div>
                </div>
                <div data-v-5b1caab5="" class="userInfo__container-setting-center-content ar-1px-b">
                    <h5 data-v-5b1caab5="">Nickname</h5>
                    <div data-v-5b1caab5=""><span data-v-5b1caab5="">MemberNNGDGB2S</span><i data-v-5b1caab5=""
                            class="van-badge__wrapper van-icon van-icon-arrow"
                            style={{color: "rgb(136, 136, 136)"}}></i></div>
                </div>
                <div data-v-5b1caab5="" class="userInfo__container-setting-center-content">
                    <h5 data-v-5b1caab5="">UID</h5>
                    <div data-v-5b1caab5=""><span data-v-5b1caab5="">3391595</span><img data-v-5b1caab5=""
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEQAAABECAMAAAAPzWOAAAAAPFBMVEUAAAD/mJ3/mZ7/mJz/l5//l5f/mZ3/mJ3/mJz/mZ3/mZ3/mZ3/mZ7/mJz/mJz/n5//mZz/mZ3/mJ3/mZ2EdgcPAAAAE3RSTlMA39+fICBfn79/j+8/L28Qz6+v4XBg7QAAAMdJREFUWMPt0DsSwyAMRVFhE5mfwQn732ugSiMlkuNxpVsx85hTCKz72pOTFBD4YpcWGmfULi8whnddUaERHFMuj9/tx/j5opE0plV2//HT0dM2JhQh3hAJ0jCtMzemsHLF8g2puctynkViF5d3BvFdUWCQrWsqNHKokEgjiwpZDTHEEEMMMeR+BMfzOI0EnMU8PQJR5y9AIvyN5ArnEbfNEjY4j3yuaci1SKKRpwpBGikaw3mgCwqkAlOTKxH4MCyS0g7Wbb0BWRmxAQ/pIb8AAAAASUVORK5CYII="/>
                    </div>
                </div>
            </div>
            <div data-v-cb2d8429="" data-v-5b1caab5="" class="info-dialog"></div>
            <div data-v-5b1caab5="" class="info-dialog"></div>
        </div>
        <div data-v-5768d7f3="" class="userinfo-content" style={{ fontFamily: "'Roboto', 'Inter', sans-serif" }}>
            <div data-v-b0a50002="" data-v-5768d7f3="" class="totalSavings__container">
                <div data-v-b0a50002="" class="totalSavings__container-header">
                    <div data-v-b0a50002="" class="totalSavings__container-header-box ar-1px-b">
                        <div data-v-b0a50002="" class="totalSavings__container-header__title"><span
                                data-v-b0a50002="">Total balance</span></div>
                        <p data-v-b0a50002="" class="totalSavings__container-header__subtitle"><span
                                data-v-b0a50002="">₹{usebalance}</span><img data-v-b0a50002=""
                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAqCAMAAADyHTlpAAAAOVBMVEUAAACjo6OgoKCjo6OhoaGjo6Ofn5+np6ejo6Ojo6OlpaWioqKkpKSjo6Ojo6Ojo6Ojo6OkpKSjo6PuE3LfAAAAEnRSTlMAf0C/H98QIM/vMGCfj29Qr1+b1SuLAAAA4UlEQVQ4y+3Sy3aEIBBF0eJR8hCx+/7/x8amdCVCZYUMetZnpm4BBfr05hbblX+lEX2pBJ1uUDKTK1ojUGgyA2z/sHmcbVetG4YNDHBQaEXqxjykbj3gbzceSIdUbUcN8ARyQlz+oBUwHqC7dTF8000ehISNXpQsflh5T6gBrHw8L0JpBR6nlDmE7ucOZ7CnRsVe9AVio4GvAexCjcqFPaG8Vw6a27RXQrvEAg7sh189VNHa7/sFWP24AEY5F0qri3VcVwo0GYNnbU7g56xlgIvp0mfyBWNMer4W11Xp03v7Att9D7xKb3uAAAAAAElFTkSuQmCC"/>
                        </p>
                    </div>
                </div>
                <div data-v-b0a50002="" class="totalSavings__container-content">
                    <div data-v-b0a50002="" class="totalSavings__container-content-item">
                        <Link to="/wallet">
                        <div data-v-b0a50002=""><img data-v-b0a50002=""
                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAMAAABg3Am1AAAAeFBMVEUAAAD/oKD/oKD/oaH/n5//n5//oKD/oaH/n5//n5//oKD/n5//oKD/n5/+i4v/n5/+iIj/n5//oaH/n5//n5//oaH/n5/5WVn9hYX6a2v7c3P////8i4v7eXn6YmL5XV3+lpb+j4/+9fX+6ur+m5v8trb7f3/6Zmaz6OagAAAAFnRSTlMA359fIBBvP++vjzC/f9/Pv49/YFBPchRNZwAAANhJREFUSMft1bkSgjAUheEEw467XoQQdvX931CLFBdDSFJYMMNfn2+YYQlkyz7/mnjaLqmyv0WwmMem+wBMUR/vWQTGzhgkYBG+RGYDdgjQtYCxEiXq5AVsCYyiySc9AGiqB20tdxgAxUDdqwBCHSjzebDTgCp3AfgCtSWQu6rnTzsgNwPn/OUC+i+orIB8ZsD5kFuBQo6EqP90W+HtCrrG7dXAAoPI1wJoixkQLH5AnShwhywOydoOgZ/urkclQyAEczHB7cEUZcRJUOUfxLwj1RYHPtly6gOS6nMolZZu1QAAAABJRU5ErkJggg=="
                                alt=""/><span data-v-b0a50002="">Wallet</span></div></Link>
                    </div>
                    <div data-v-b0a50002="" class="totalSavings__container-content-item">

                        <Link to="/deposit"><div data-v-b0a50002="">  <img data-v-b0a50002=""
                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHgAAAB4CAMAAAAOusbgAAAAjVBMVEUAAAD3eAD3dwD4dgD5dwD2dgD4dgD0bwD4eQD4dwD4dwD4dwD7dwD5dwD2dgD5dgD4dwDtcQD5dgD2eAD5dwD4dwD4dwD4dwD4dwD4eAD3eAD4dgD/dAD3dQDqbgD5dwD3dgD4dwD5eQD5dwD5dwD4dwD5dwD6dgD4dwD4dgD6eQD7dgD4eAD2dAD4dwAMGSgcAAAALnRSTlMAZsyZxU2mEkxrQN9yn3OMcg19OfPqv6+UWTImHxoI0YZULfiE2HlfuI9fQnM4FNyjywAAAtFJREFUaN7s1u9ymkAUBfDdPYBDKAERkdZ/WFF0THn/x2sTSy5JENiF64eMvxc4c+7smVnx8PDw8O1N7chJlEqcQP6airuZykTV4Oda3IVdxRLsJoKdVA32sf9b8HpWDbzyn20hGEnVJC7feP5KMLFVk235zn8SHNaJajIva7yCoXbQUpjE/or30PS0vhj5jS9Uo33ZwF/dvTBFMxeOyxu8grXwobztPGEsPC9beCuGwrQl3mRH52mR7cBrb/4ojS3VnYW5pR3ipFmYPAlDsxCArzS3RBLD2AyvFi1b4qi8DnFlaW+JqEj7gV1Q0d8SmQO23ksOUUn0C5M9AFej9CRDxTfZEnEA5BODXNMtUTAla+XCMtsSBVOyRu6wLVEw8o3oFoIYPi2C/0LRyUaNY7glmlPl0nlo1J0GFvZQ6fwYRCCmWyIW0PPYM9QtzLZEFiBL0SZDnTWwcIyacHDhQ9nXAehZ+QfG2xJdurvyBF+elnXynU+sHraHuLo02fS89Mlyghdp7JimKT649Lt0cHTlUMeg361BqOswL7XWZ3HDEpU0kmS81uuu787ZlSNy30vPRLMdrnJXjivC1U40c/GGzjx2ctH6qHPJIMer57bgQLIIuoJTV7Io0o7gSDIJ24MzySZqDXYlGzdtCc4ko+PfdukYx2EQCsLwmwI50ESsKyAgmWJX8v0PuE2aFOPYjqeJ+C7wa6TZCHsIeR6+Q+pOw7+Q+qFhDynPwh5inoT/ILaS8AKxhYQdXn1P+DbCI/w0wiM8wiO8OxxAqMMzxAIJd4jNJFwg1kl4gtiDhBvEJiMStIwJkLoZ0yE1GxMhVY1yEErGZQgF4xqEJiPIr8Wf1t+r2qYMkWDbWoJEivbGBIlib3UIZNshi7tcFne5mnChtdtuMeAyLtoR0eESqdhRMayfr612yiMsOG11vdl5rZYc3HJkfEouzCXaMAzDt/kHbN7wdhAVLO0AAAAASUVORK5CYII="
                                alt=""/><span data-v-b0a50002="">Deposit</span></div></Link>
                        
                    </div>
                    <div data-v-b0a50002="" class="totalSavings__container-content-item">
                        <Link to="/withdraw"><div data-v-b0a50002=""><img data-v-b0a50002=""
                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHgAAAB4CAMAAAAOusbgAAAAXVBMVEUAAABcpf9cpv9bpv9cpv9cpv9dpv9cpv9bpf9dpv9ZpP9cpv9cp/9cpf9bpP9Yp/9cp/9ZoP9cpv9cpv9cp/9Ypf9Ynf9Onf9cpv9cpf9bpf9dpv9fpP9ao/9cpv/+vZV2AAAAHnRSTlMAZoxfTb9Z3yY/H/DFeXAazxGWj4I5DQbZvJ9wRkB5MVsiAAABcklEQVRo3u3T3XKCMBCG4Q0/ASMoiIBa3fu/zHac2nQwESzZHHS+5zAHvGwWCAAAAOBJm3Z5wasUeZe19J5NzoHk6TvZhgMqlqZNzoFdzKJxtxxcsaFZKYtIV3RFyxsW8/K2TcFitob8Liwoj77g+csuWFQReeD5kXN2kd+yYXEtuWQsLiWXjsV1cVds5TF/pvkfiiNAGOEHhP9ZWEWAMMIPCCOMMMIII4zwD4QRRhhhhBH2ho+JZU93iUsdLnzY8i/bw3e2YbdqFyhc88TxflyxT7M+7C6c7eu47cKETzxxUl8S9kvChM9PS7yvmP3qv4X19JN2P7dhn0rN0eSSLfyqK3ZrPtScjFx69SSxjva0Tlxs1q8nl1KJM+QyKnEjeZYsLCO3vRJWkodWojRR/JHtwPG3fCO/IVVi9EBWzMtu6aVSWYILli/b7qxWq+D0nhYYbiqwbKBlSh00u6flzDVy1hpNn+mVm7325UgAAAAAU5/94mxKVD2pxAAAAABJRU5ErkJggg=="
                                alt=""/><span data-v-b0a50002="">Withdraw</span></div></Link>
                        
                    </div>
                    <div data-v-b0a50002="" class="totalSavings__container-content-item">
                        <div data-v-b0a50002=""><img data-v-b0a50002="" alt="" class=""
                                data-origin="https://damanclub.in/assets/png/VipIcon-3c72b1cc.png"
                                src="https://damanclub.in/assets/png/VipIcon-3c72b1cc.png"/><span
                                data-v-b0a50002="">VIP</span></div>
                    </div>
                </div>
            </div>
            <div data-v-929ecffd="" data-v-5768d7f3="" class="financialServices__container">
                <div data-v-929ecffd="" class="financialServices__container-footer">
                    <div data-v-929ecffd="">
                        <div data-v-929ecffd="">
                            <div data-v-929ecffd=""><span data-v-929ecffd="">Safe</span>
                                <div data-v-929ecffd="" class="financialServices__container-footer-des">
                                    <h4 data-v-929ecffd="">₹0.00</h4><i data-v-929ecffd=""
                                        class="van-badge__wrapper van-icon van-icon-arrow"></i>
                                </div>
                            </div><span data-v-929ecffd="">Daily interest rate 0.1% + VIP extra income safe, calculated
                                every 1 minute</span>
                        </div>
                    </div>
                </div>


                <div data-v-929ecffd="" class="financialServices__container-box">
                    <div data-v-929ecffd=""><img data-v-929ecffd=""
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAAtFBMVEUAAABcp/9cp/9epv9dn/9cpv9cpv9cpf9cp/9bpv9cpv9dp/9cpP9bp/9eqP9apf9Sn/9dpv9Jmv9eqP9ElP9cp/9cpv////82if+t0v/B3f+Zx//W6P/m8f/N4f/0+f9Il/9Ckv9wsf9Rnv9Qmf9Lmf/r9P/a6f+00/86jf+Duf9ao/+jzf9Xo/8+j/+/2v+32P+Pwv9mrP9op//g7v+31/98tv91rv9mq//N4/+jzP+Nvf89OsrBAAAAFnRSTlMAf78gEN/vz29fr58/n28wz49vT09A/ixTGwAAAdVJREFUWMPtlttygjAQhkHAQ221xwSlCSogKJ6t1bbv/16Ngc7KTIBlai+c+t3JzH4uu3802pXzYnbuaoXcdIyicr1OymnnKowGQWHlGaC+zGAq61sETVspsAgeVQsvpAJNhaBZRXCnEOhVBDe/FdSKBdOPVxUOVjCiOfSQgl6eYIwUzPIEb0jBZkyVuOsSAbB2VBzKhljKfxKMVn0FK3wSXarm8+KTSB30EGc9FdMLysEfCuZeMAi8UsHBgcmt4XE8mNgJw/viJGbW2Ccpng0s2gY+SO9EMrAlX+H2KCCWgY7yFOoFjC6P70CEAXGY4AAEstqPmHjCWBjDn7wyiTs4xLuRnP5E1ruZ19IrrHGYtk9Dl7qcRwER1E28QDYQUxoJCU9mIGiiBLDBrRhoRlDDCWCElIb+qaCOE8AOOZWEkZ8IiIkVDBOB+PoEFhCJge8gZboUWxCaFXSAn8FPEvmQUbfiDLyMwF5SuoGbEioHGYHP0ny3cNc8GMKWMXZy2bG0DLdFglhGMaIp3IMGgBopm4LPORdbiPaTOeQQ1wJsUiZxoL70P2AMTri3F+JjA+oxi4DfBMEiJnVdU9KyChWejHRAGrqp5dF90gt5fL7tmtqVs/MN94DbsbuNvhYAAAAASUVORK5CYII="/>
                            <Link to="/gamehistory"><div data-v-929ecffd="" class="financialServices__container-box-para">
                            <h3 data-v-929ecffd="">Game History</h3><span data-v-929ecffd="">My game history</span>
                        </div></Link>
                        
                    </div>
                    <div data-v-929ecffd=""><img data-v-929ecffd=""
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAArlBMVEUAAABK461L46xE36RL4q1L465K4q1O4a9K4a1K46xK4qxK4a1K46xL4qxK4q1L4axK4q1K361M4q0T0IE53J8u2JUg2I5L4qz///8GzHal8NW79N+O7MvS9+rg+e4X0oTp/PUo15Al0odi5rdE4KY33J0l04ch1YwLznph5rYQ0H6w8tqa79CC5bpW5LEx2Zcs2JT0/vqD68Z46cHk+vHg+e+P7cvH9uWa7tBt57tTDyxnAAAAF3RSTlMAf78Qn28/H3+vj99g79/PTzDv779vT8FjD08AAAGZSURBVFjD7ZZpb4JAEIaVS8Gj2msArYoCWrxQe/7/P1aWjQTbYXcItompzxcTk3kyzLwLW7tyXlRdU4SYekNU3rwFOVqhonEDJFpFBlZPM6hofRvIaKigC3SwFgwogY4IdIBqz1CHEphVBYpYcHh/wnijCmZ2AS5R4BYJJkTBqkjwShRsJnj9bk8QcPZDjI1siFL+k2C2HCAs6Unc2Tiri0+iPSQP8cPFOFxQDn5R4AdbbxtIBZuhm7HPVXuRw5nfi5N4ssbB8e9g4WTcNUsE6RNSPCfHGDSVHOXDaX24DpkATOph4gcgdo6M7FHIBGDRjvPyOZ3fIidIDEwA/RJrnDsZU2bY8jDQBayBcGRnvKR9qWRB4CSsf3zl2mRBzHvPTTbtwEIEshFkOWCYVQXK3wliB92CVXULvRI5QLeg0q95XpbBkL8QkLueAQL8KDOkAhblzvc7twLCKXADF0Q+dls1QITHDesp+/EAAHknWXIDJ8LqGU2QzIEz9qFl1FDaXRARjJN1LuKOwstR+r26kIdHo1G7cn6+AEIy4spd9Gx2AAAAAElFTkSuQmCC"/>

                            <Link to="/transachistory">
                        <div data-v-929ecffd="" class="financialServices__container-box-para">
                            <h3 data-v-929ecffd="">Transaction</h3><span data-v-929ecffd="">My transaction
                                history</span>
                        </div>
                        </Link>
                    </div>
                    <div data-v-929ecffd=""><img data-v-929ecffd=""
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAMAAAC8EZcfAAAAbFBMVEX6WloAAAD5WFj7WFj6WVn/W1v7Wlr6Wlr7W1v6Wlr6XFz6Wlr5WFj8W1v6WVn6WVn5WVn/VVX7W1v+XV36WVn4WFj6W1v5WVn5WVn7Wlr6W1v8XFz7WVn9XV3/SUn6WVn6Wlr/XFz4WVn5WVlMptElAAAAI3RSTlNmAJ9AxQxNWXkzJRn1X9eOsgZGE+u8cOzigl9ZOSwHqZ8ycDPAW1MAAAL6SURBVHja7dzbbuMgFIXh5WBjAz47SZNOjvX7v+NI7QVSpwkQbGBm9n8bX3zCiVEks5ElHgEJ6BgBk4uABHSMgMnlBiwmeRbsM2ycAvtMCKmGTLcgcFAn/g7dZnZqA907P6mFgYPUOC+gbpTLAYe71nkDdSUrfIGatyhQxwp/4FnzlgeilJ7AYQTWBAJs8AEWJdYGoiwMQINvXaBZCINvZaBZCINvbaAWugM5QgHBXwEKhAPi7A4sEBLYFM5AFhQI7gpUCAuEcgSOoYHcDVggNBCTE5CFB96dgGV4YOMCVAgPhHIAihjAuwOQxwByByBiAJvBGjhFAWKyBso4QGkNFHGAJ2vgGAfIrIE8DpCnDiytgWXqQMQBgoAEJCABCbg2sO73+1udLnB7BIDqLVXgtgK+hGkCv3xfwhSBh0p/WB3SA7Z7QPdxSA2Y41t5WsAd/qhPCdjjh/p0gD1+rE8FeMWDrkkA6xsedksAWB/xpGMdDdjp7fdxelPpggNx0NubWbhFeGDVznNb2V4XAQjs97YXAhGALhHw5whIQAIaIiABCWiIgAQkoKG/Gdjt8mfturjAqp1NHaqYwMNsro0I7GabunjAnRVwFw+YWwFzWkHP72AVD4jewtcjIhDXNwOvvsbe6rrNs7o9QHsxQEACEtAQAQlIQEMEJCABn9a19fxqdV55AUvv/x1m4nHlN9E3s2f1x7rv8rezb1cPIIOx2bsc3xutgScYq1cACmugDHGLbx5HhiYY63x928rj0NXQRHnMLHvwD7d2XvZBzR2AAhESDkCFCCkzUNcgeGX2AJjKPWZOwAnBK2yAOo7AjdlDYBo/E/UQmMYSMvdRGg1CVtgCdWcE7J49ASZwk/lrA3F+IUDmgTjxhYaRQtGFpqFMsYTa5zUYjGHlxovnaDW56iI2Z//hdAXDWjXikvkANVGvYkieBpqT49I6Li8Lz8BUJ94shTsprTMCXbooKQTzSJzlVGSf/Z9jTs0RMLkISEDHCJhcBPzngb8BncLoIXGJNNsAAAAASUVORK5CYII="/>
                            <Link to="/deposithistory">
                            <div data-v-929ecffd="" class="financialServices__container-box-para">
                            <h3 data-v-929ecffd="">Deposit</h3><span data-v-929ecffd="">My deposit history</span>
                        </div>
                            </Link>
                        
                    </div>
                    <div data-v-929ecffd=""><img data-v-929ecffd=""
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAMAAAC5zwKfAAAAbFBMVEUAAAD/iRv/ihz/hhr/iBr/iRv/iB3/iRv/ihv/iRz/hR3/iRz/iRr/hRT/iR3/ihv/iRr/hxn/iRv/iRv/gCr/iR3/iRz/hxv/iRz/iRz/iBz/ihz/ixv/hRH/iRz/ih3/iBv/iRv/kiT/iR3fckNCAAAAI3RSTlMAZp8mH1/gb1iPEH9NDPU+MBmxeQbvz0Dsxb6oORLZv5aCBztI/qIAAAHtSURBVFjD7djJkoIwEIDhJISwyiqiuIxOv/87ToFV0wQZk9A5zMH/ZFn6VbNEAfbp02+yDMvKn5Z0MNXkPrg0AUxJ+ngKtELPHop0D0W6RxfRM4t0D0W6hyLd08s9e9BJuqc3ePKw0rMHiWcPlAM4gE2ppbaX+VlYdC7rvYVW5dyhONgZuJC7Vr0Z8xrzDcV/DhnwjQXrnuTcq7gLt4Ph2lbHnFBu3IH0jY5pYLz0ak6sXoAlFbwYtpi6zXtObq8vOjp4pR8Tvdqw7IhnYvBPwOgufrvQwfYGs0Iy+CjAK9gW4Bc8gV8wAs9g4hsUvsGHBRjdT8cCQB1EawajwgRGzewjJzPZGMDHEbQa44jqLShg2WASW/UGjOC1EzclbsUfoOxgpfP2ny8FaxXtVjBcUgqmbi6gZNjXgpNMHmAs2vafInXvMF6MV8578Ypgrnkind582g4gw5Lnl/tejB6bKmHsaO/lDBsdUONgGWT6nY89WC3BZnrZs6lKgSu4W4IDw8oOnhXWXv+67rKV01I5D4gHAMXIsJzNV4dpNxczwO6WXvzNtBJAce4dN95L4VJpkgFmNc4ejog5D3j5Zi+lhzWwteHy2vqZgDBiYV7hdCaxiwJD9c78HBP7koycFMj1zEtpnwkhkjBlnxz6AfNNngUB9TYwAAAAAElFTkSuQmCC"/>

                             <Link to="/withdrawhistory">
                        <div data-v-929ecffd="" class="financialServices__container-box-para">
                            <h3 data-v-929ecffd="">Withdraw</h3><span data-v-929ecffd="">My withdraw history</span>
                        </div>
                        </Link>
                    </div>
                </div>




            </div>
            <div data-v-2f13803f="" data-v-5768d7f3="" class="settingPanel__container">
                <div data-v-2f13803f="" class="settingPanel__container-items">
                    <div data-v-2f13803f="" class="settingPanel__container-items__item ar-1px-b">
                        <div data-v-2f13803f="" class="settingPanel__container-items__title"><img data-v-2f13803f=""
                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHgAAAB4CAMAAAAOusbgAAAAYFBMVEX6WloAAAD7Wlr6Wlr7Wlr7Wlr6WVn8W1v/WFj6Wlr/X1/5WVn6WVn4WVn6W1v6WVn6WVn6Wlr5WVn5WFj5WFj/UVH6WVn6WVn6W1v7WVn8XV36WVn7Wlr4WFj8W1v5WVk1zszIAAAAH3RSTlNmADN5P1nsXwwZEuKfTSWojHCyvPUGxdlfUizPgpZKdJ6sfgAAAipJREFUaN7t2u1SqzAUheHVkgBBAoEW+mE193+Xx3E87nFkGzDsik7eC+AZs37YgWD3QyWYK8EJTnCCE5xgtjvDpWmu+291bUz5PXgwyp4R1dkqsxQ2itA4XFULYGOxYracCQ8KK7cf5sClxurpMgxXZwhUVAGYXGkZ0udMp/0lfIFY9iu4gWBXHi41BCsGFt5DtD0La4hWcHAD4QwDKwinGFhDuGIariBeNQk3EK+ZhBXEU5OwhXjPk/AF4l0mYQ3x9CSMO7QEduNYY0ld/3iq4+Hev/SQYXbZg3/pFAs/+dfy2bLL/Wt1JHzwb7WYVevfeoyER/+/HuG6gyd4laOmocPzrnPUONGz8iw8L/3BcTCtHB669dQBETDzwPC8/lhjDRi3nBmamTe/IQJe9lR39O+dOsTCVM8Mza8RD4cf3Y3svPEwsg9Ds0NkiIHDQ9eg/0XMvFEwP/RxbF2WuXY8eqoHRGC0nonmjYXDQ1M0rxxMQ1M0rxxMQ1M0rzCMOv/M5g4CcJA+9h1EYcqN71vnBwdAHKY65+r66dZhUb/iB32CE5zgBCc4wfeBt/WC7cdeKT5DPLut18YNxGu29WlgV0A4vbXPPwbCNQwsfdZ6ex81hwKC6ZKFd1fIRQsTTFmIddnm5YRdxcwseh2DZAF3u1duJC4ZqWHmtapy3WtVZsFFskoVK42rzNI7e0bZIhK1ygy/57Lg/P7UvUwqwQlOcIITnGC2vwP/A8YJWveoD7GJAAAAAElFTkSuQmCC"/><span
                                data-v-2f13803f="">Notification</span></div>
                        <div data-v-2f13803f="" class="settingPanel__container-items-right">
                            <h5 data-v-2f13803f="">80</h5><span data-v-2f13803f=""
                                style={{display: "none"}}>English</span><i data-v-2f13803f=""
                                class="van-badge__wrapper van-icon van-icon-arrow"
                                style={{color: "rgb(51, 51, 51)"}}></i>
                        </div>
                    </div>
                    <div data-v-2f13803f="" class="settingPanel__container-items__item ar-1px-b">
                        <div data-v-2f13803f="" class="settingPanel__container-items__title"><img data-v-2f13803f=""
                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHgAAAB4CAMAAAAOusbgAAAAYFBMVEUAAAD6Wlr4WFj/YWH6WVn7Wlr5WFj5Wlr5WFj/WFj8Wlr5WVn6WVn5WFj4V1f/V1f5WVn5WFj7WVn7WVn+XFz7Xl75WVn/VVX5WFj4WFj7W1v+W1v5WVn7Wlr5Wlr5WVmJKV1iAAAAH3RSTlMAZsIMWT+2fX8RX29NH40m359ROSwmGQatmUYyz17vxXPQ3wAAAcRJREFUaN7t2m1vgjAQwPEe0AkUUB4ERF2//7ecJG6XLdJKrheMu//LhuTnRRFenJIkSXrXYn2FZq88dQVcdRzS3V9hTitnGubyLKAL97THRTmUi7LLRTmYi7LXRZnu+mV0Uaa7fhldlOmuS0Y3sNwDuGR0A8txAi4Z3UfyQICP8Di97GIdAdbgkL3XcMCg/Ze0BLgEh+xxoVSECofscYu1VpaOBxu0Q5SXfrayLKUetzxYpi6ZE0Y3eJHLzS1j2gFfLCHCyKVlLVuEG8tasQgby5pZhFPLWiqwwPf+A7zZfdxY1vTr/VeryDIWbfU8zpXaZOT6Nd+5VFlbjj5TdBdpE4V+rx4Nsq5iWGgXufqAhRKllMACCyywwAILLLDAAgss8NxpdzuoI2zkg80Hjndj5yo8qvhgqH7GNXDP1N8fxTDCcPo1Lg6NLhMM5jTW1d+zqq4rAzywP4EFFlhggQNUPAsPW8EqMDw9DScEhbSJMhEU0tJPGxbuCUtNpAa1zf3UEFZ8mH9b2DkkHK+Ah5wAkfbJOu6B+b/ls1rXkPC4/uKJyfV3JA9d9OtEpAuCmre9ItR3umiSdWLSTO0xU5IkSe/WF7W+N+28iEaIAAAAAElFTkSuQmCC"/><span
                                data-v-2f13803f="">Gifts</span></div>
                        <div data-v-2f13803f="" class="settingPanel__container-items-right">
                            <h5 data-v-2f13803f="" style={{display: "none"}}>80</h5><span data-v-2f13803f=""
                                style={{display: "none"}}>English</span><i data-v-2f13803f=""
                                class="van-badge__wrapper van-icon van-icon-arrow"
                                style={{color: "rgb(51, 51, 51)"}}></i>
                        </div>
                    </div>
                    <div data-v-2f13803f="" class="settingPanel__container-items__item ar-1px-b" style={{display: "none"}}>
                        <div data-v-2f13803f="" class="settingPanel__container-items__title"><img data-v-2f13803f=""
                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAIxSURBVHgB7Zo9ThtBFMff20W2iYzkImWkbBrqHME5QSxFKJiGFTcgFyC5gXMCDBJeyiBygHQpg5QuTaZLRRcl9mLm8V9jKBBmP/zBeng/aTyy9Hbl3+zMznjeECmKoiwRTDmRnZ01urh4ScwxHx7+oiUjl7BsbFQuq9V31tq10Xfm79Wjo5+0RHh5gv/5/vMb2QSxNqAlI5ewC6iw66xQAfCme2FF3pNIHLfbH6gozAafnyu93hktiELCIvIM1Tozk9AUyOjqEI32DfIHEO/SnCkkPGug3YR8c9Bu76ERO5fWnqweHxuaA2UbwwF6T8dj/h1vbe2jvKYZU4onfB8QD1GFeOpZwg3KbjWKvqQFuvKWDlD2pdVqpAW6NC01+vV66hDQhYfrqLDrqLDrqLDrqLDrqLDrqLDrqLDrqLDraOYhC+x559hVPMXW4l9soBfOESN7EaDFt2mBFM08nCPd8tUS/an1eqc0Bf83Nz+iamIveo+udx/nyqPvS48zDN2kQD70mbdHmYgiDIcmLaRUYxjy3UoUvUFXf4Xec5DnWsv8KUt6ppSZh/EPD8fdPchwiVmNIpMhrryploSxuKEZovOw66iw66iw6zw54QfPWmLiD9j3Wywyyqyjrgvz+m3AnT8PuJlZxEmcaZgoLGHYiAeDH5RzQY918G4tijpUUiZ26WG/nxwfCCj/Dd9SiZkovFKrJafjDOUEi/4TKjGpY9jzvJCyInKW5eiQoiiKkpErBiCvMuicgNYAAAAASUVORK5CYII="/><span
                                data-v-2f13803f="">My tournament</span></div>
                        <div data-v-2f13803f="" class="settingPanel__container-items-right">
                            <h5 data-v-2f13803f="" style={{display: "none"}}>80</h5><span data-v-2f13803f=""
                                style={{display: "none"}}>English</span><i data-v-2f13803f=""
                                class="van-badge__wrapper van-icon van-icon-arrow"
                                style={{color: "rgb(51, 51, 51)"}}></i>
                        </div>
                    </div>
                    <div data-v-2f13803f="" class="settingPanel__container-items__item ar-1px-b" style={{display: "none"}}>
                        <div data-v-2f13803f="" class="settingPanel__container-items__title"><img data-v-2f13803f=""
                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHgAAAB4CAMAAAAOusbgAAAAZlBMVEX6WloAAAD7Wlr6WVn7W1v/WFj7Wlr8W1v6WVn6W1v5WVn/X1/6WFj7Wlr+XFz1WFj5WVn6WVn/VVX6WVn7WVn6WVn6WVn7W1v/SUn5WFj4WFj6WVn4WFj7WVn/XFz6Wlr7Wlr5WVmvcrXyAAAAIXRSTlNmADPZWQw/X+wmcBKheSwaslMGTTnFjEYH9ePPvB8ZvIImAPz+AAACDElEQVRo3u3a627yMAyAYZM6CSGspQdWdoDv6/3f5KSpmqWFBRPcsWl+/yGBHpqmKBKG1Z1S+KsUVljhW2EbjBtbRLgij23vzDGWwyfn4YbQHYtg4+HmMFwNxxZEQnsdHD0I5SMPJldO5sMWQTDs2PAAohkubEE23zFhA8IZJozSMPLgCOJZFhzk4cCCnTzsWHArD7cs2MvDngN3sEAdA46wQJYBN7BAJwYcYIGOZfC2mqbq8RY4MOAaPref3tvB3MNrlWu7KYNNcr3T3OPsPk351hsZeD3NvcB71XSpXQLXBfCGroRe51snsPke+FkEpqXec5d6LwLT5vo/b671pZV+kIHh9fPj9K/K9LLbgBAM2+fpqfAHpBymFFZYYYV/E0xnroVh+TOXudeZy9zrzGX0zKVnLj1z/dXfaoUVVlhhhRVWWGGFFVZYYYUVhgWqGXANCxR+8h/XEdK8q4MZ4VJ+NMGMCGmnoqkIbOapJ5NnzWFeM+ROReRnjNyBNWGGlt42Fg2gDJlxGYs5l3IlIzdN7jMNY9IjlWMOpnqgBubTVufWrV/x4Ej7pc5+Lcpl5+AsD6ZHuT/3iQ5TFw+rNDt/RR/4c5nWecChWZ2tuXCDqca14F0UG3OuOcOAi8xX97kbTMnDHSY3OEkSpiwy5oklYZIdzA2Z6xWGacfSzj/fbxze/0hhhRVWOOkNzLdtCg3xP8oAAAAASUVORK5CYII="/><span
                                data-v-2f13803f="">Product code</span></div>
                        <div data-v-2f13803f="" class="settingPanel__container-items-right">
                            <h5 data-v-2f13803f="" style={{display: "none"}}>80</h5><span data-v-2f13803f=""
                                style={{display: "none"}}>English</span><i data-v-2f13803f=""
                                class="van-badge__wrapper van-icon van-icon-arrow"
                                style={{color: "rgb(51, 51, 51)"}}></i>
                        </div>
                    </div>
                    <div data-v-2f13803f="" class="settingPanel__container-items__item ar-1px-b" style={{display: "none"}}>
                        <div data-v-2f13803f="" class="settingPanel__container-items__title"><img data-v-2f13803f=""
                                src="https://damanclub.in/assets/png/mylottery-59fde5b3.png"/><span
                                data-v-2f13803f="">my draw</span></div>
                        <div data-v-2f13803f="" class="settingPanel__container-items-right">
                            <h5 data-v-2f13803f="" style={{display: "none"}}>80</h5><span data-v-2f13803f=""
                                style={{display: "none"}}>English</span><i data-v-2f13803f=""
                                class="van-badge__wrapper van-icon van-icon-arrow"
                                style={{color: "rgb(51, 51, 51)"}}></i>
                        </div>
                    </div>
                    <div data-v-2f13803f="" class="settingPanel__container-items__item ar-1px-b">
                        <div data-v-2f13803f="" class="settingPanel__container-items__title"><img data-v-2f13803f=""
                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHgAAAB4CAMAAAAOusbgAAAAP1BMVEUAAAD6Wlr4WVn8W1v6WVn5WFj6WVn6WFj9XFz/Wlr5WVn4WFj4WVn6WVn7W1v5WFj5WVn6WFj/UFD4Wlr5WVlzcdAPAAAAFHRSTlMAZk1MX3+/Pykf77+/U0bfz5cQbxtI08QAAADoSURBVGje7dnLDoJADEDRQSsgKC/7/9/qRkiYStjYhuA964abIZN0MQkAsKjaRmetpDA3XbmlIKKZKsV45OE2xdBck2Kokb4iTJgw4dOHpdXFIFFhu2ujwqIZCQrbXRsUVoMwYcKE/zY83T+m0PCr10UvceFRV8aosN21QeHB7NqgsBqECRMmTJgwYcKECRMmTJgwYcKECRMmfLxwozmfmf2X0tJlxqo0Iz4z+6/hXjOWlDprymp3RueZne8AAH6k7q5+6u3us3D13Dxv4WzrzJfCWbcR7gpn16P96rDLZdUXP12dgHN7Az9pIk/r/Zr3AAAAAElFTkSuQmCC"/><span
                                data-v-2f13803f="">Game statistics</span></div>
                        <div data-v-2f13803f="" class="settingPanel__container-items-right">
                            <h5 data-v-2f13803f="" style={{display: "none"}}>80</h5><span data-v-2f13803f=""
                                style={{display: "none"}}>English</span><i data-v-2f13803f=""
                                class="van-badge__wrapper van-icon van-icon-arrow"
                                style={{color: "rgb(51, 51, 51)"}}></i>
                        </div>
                    </div>
                    <div data-v-2f13803f="" class="settingPanel__container-items__item ar-1px-b">
                        <div data-v-2f13803f="" class="settingPanel__container-items__title"><img data-v-2f13803f=""
                                src="https://damanclub.in/assets/png/languageIcon-4c117d4d.png"/><span
                                data-v-2f13803f="">Language</span></div>
                        <div data-v-2f13803f="" class="settingPanel__container-items-right">
                            <h5 data-v-2f13803f="" style={{display: "none"}}>80</h5><span
                                data-v-2f13803f="">English</span><i data-v-2f13803f=""
                                class="van-badge__wrapper van-icon van-icon-arrow"
                                style={{color: "rgb(51, 51, 51)"}}></i>
                        </div>
                    </div>
                </div>
            </div>
            <div data-v-50e93753="" data-v-5768d7f3="" class="serviceCenter-wrap">
                <div data-v-50e93753="" class="serviceCenter__container">
                    <h1 data-v-50e93753="">Service center</h1>
                    <div data-v-50e93753="" class="serviceCenter__container-items">
                        <div data-v-50e93753="" class="serviceCenter__container-items__item"><img data-v-50e93753=""
                                class=""
                                data-origin="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHgAAAB4CAMAAAAOusbgAAAAY1BMVEUAAAD6Wlr/W1v/UVH6Wlr7W1v6Wlr6WVn8W1v/XV38Xl77W1v6Wlr/Xl78WVn6WVn6Wlr6WVn5V1f7W1v5WFj5WFj7Wlr5WVn/XFz5WVn7W1v5WFj5WVn5WVn8Wlr4WFj5WVmWy2Q/AAAAIHRSTlMAZgwGTFkzU18sJj8ZEzjsqIwmRsGagnAy4nn1snlS48+T4koAAAKxSURBVGje7drbjpswEAbgGR8AcwwhISHpbv3+T9nVqpKbBGx2xogS8d0a6ZcPcQZj2O12u907kaKShHauXOGX9Eho5yhT/EsLGCFce8xoodDJqpEHNDqdgDjkFR9o+fKExgdFlGiT4ZManiT4RJsYk/vqabAFOpGmWhY4Jn0cbI1OnPGWKY6rvQPt1j9VgVOu7qEapyggynFaKtwvbZIBGo0+3bEsc4U+GZBUyFYChUES/lgnyFZsK9ggWwIU5VqLCzJkyiSsM8k10Ehil/mbdcLtMJVEFgFkChkU0NW8kabLkcGsFXwEOsPbL+m6lRaXQBZOsceiltm4Lre27S/oc+W+qb1qT439NpzOvj6LH6caX+zhbv/ReKOPFcwiTae1Rp9+sE+aD/TJdNodRfDVMORgRxzQIUy4VBj0y476hUGphCkp/rS/zoGRnGDQh53UY1BN36caO2nAMEn9KzpbJ94CU6QOO3cM+iSW0Wfr1WIQjMGgk/U6LRY8WK9mqeCLDbgsFNzbgNtCwa0NaN8t+GYD+ndbXNhYr99b20Ay7uo6E4MVBt2ZG9cntQ44MDtck488BlaHUZBLn96zXzIKTbVwsff/lbcAiV6qoM8S8BJ5l+oMfW4vG9jQ+zO17oyEWSrvof/5Ifru664ylYz5mno+DfZbc2p9seUSL+Z9294uy5x0dcjSAZXI8Nm7n2XyDti2eKS42iFqggw1bG9xCWSRjClmuQKVRpZM0pfWOl3WyJTJbX3UNMiWbOvD9Za/mJttXceAFH2UKcu8QB+9xJWbckYteoz/51TMWYNp/GtVxZxvvVpEv0im5wyMksCRa3wlwlWwLsGJFV0Et5osgQhEEZo7qfFBLcHhRs+/EKoqcOKNt64AfMm6hLjyFL+oRAbaIT5ZVTLYvtvtdrs38gceAYlmarNG9wAAAABJRU5ErkJggg=="
                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHgAAAB4CAMAAAAOusbgAAAAY1BMVEUAAAD6Wlr/W1v/UVH6Wlr7W1v6Wlr6WVn8W1v/XV38Xl77W1v6Wlr/Xl78WVn6WVn6Wlr6WVn5V1f7W1v5WFj5WFj7Wlr5WVn/XFz5WVn7W1v5WFj5WVn5WVn8Wlr4WFj5WVmWy2Q/AAAAIHRSTlMAZgwGTFkzU18sJj8ZEzjsqIwmRsGagnAy4nn1snlS48+T4koAAAKxSURBVGje7drbjpswEAbgGR8AcwwhISHpbv3+T9nVqpKbBGx2xogS8d0a6ZcPcQZj2O12u907kaKShHauXOGX9Eho5yhT/EsLGCFce8xoodDJqpEHNDqdgDjkFR9o+fKExgdFlGiT4ZManiT4RJsYk/vqabAFOpGmWhY4Jn0cbI1OnPGWKY6rvQPt1j9VgVOu7qEapyggynFaKtwvbZIBGo0+3bEsc4U+GZBUyFYChUES/lgnyFZsK9ggWwIU5VqLCzJkyiSsM8k10Ehil/mbdcLtMJVEFgFkChkU0NW8kabLkcGsFXwEOsPbL+m6lRaXQBZOsceiltm4Lre27S/oc+W+qb1qT439NpzOvj6LH6caX+zhbv/ReKOPFcwiTae1Rp9+sE+aD/TJdNodRfDVMORgRxzQIUy4VBj0y476hUGphCkp/rS/zoGRnGDQh53UY1BN36caO2nAMEn9KzpbJ94CU6QOO3cM+iSW0Wfr1WIQjMGgk/U6LRY8WK9mqeCLDbgsFNzbgNtCwa0NaN8t+GYD+ndbXNhYr99b20Ay7uo6E4MVBt2ZG9cntQ44MDtck488BlaHUZBLn96zXzIKTbVwsff/lbcAiV6qoM8S8BJ5l+oMfW4vG9jQ+zO17oyEWSrvof/5Ifru664ylYz5mno+DfZbc2p9seUSL+Z9294uy5x0dcjSAZXI8Nm7n2XyDti2eKS42iFqggw1bG9xCWSRjClmuQKVRpZM0pfWOl3WyJTJbX3UNMiWbOvD9Za/mJttXceAFH2UKcu8QB+9xJWbckYteoz/51TMWYNp/GtVxZxvvVpEv0im5wyMksCRa3wlwlWwLsGJFV0Et5osgQhEEZo7qfFBLcHhRs+/EKoqcOKNt64AfMm6hLjyFL+oRAbaIT5ZVTLYvtvtdrs38gceAYlmarNG9wAAAABJRU5ErkJggg=="/><span
                                data-v-50e93753="">Settings</span></div>
                        <div data-v-50e93753="" class="serviceCenter__container-items__item"><img data-v-50e93753=""
                                class=""
                                data-origin="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAMAAAANIilAAAAAYFBMVEUAAAD6Wlr5WFj7Wlr5WVn6WVn7W1v4WFj/VFT5WVn5WVn5WVn6WVn6WVn7W1v7WVn7W1v7WVn6Wlr/XFz5WFj7W1v/XV39XFz6WVn/Zmb5WFj5WVn5WVn6Wlr5WVn5WVnIvMgnAAAAH3RSTlMAZp9M2u0/vg2zcBnFjHlfWTkzMpZGLCYfCvVTz6h5gltZtQAAAP1JREFUSMft1d2OgjAQhuFOa1so8iciuO4u93+XcqBIMmP9Ih6o4T1/MikzCWrtFTlvh1nW97gtB1aJ2t5ybBN8MC8AMGvayks4PZldEqeGxrYS3tKYifAdURSPNQ8seQl7YlqypCW8p0sHyR7pWpBWRVPSux1NFZbZgqaMPPim9Wbebxpo1pHhjuD4q1scO4YNjtsl2Kz4K3CuWf8o9gPvJ4DYDkIaxKk0uQAx7TUrfMCqPhTPLywXMH5hfxzjF7bhGL+wlFn8wvI3X5Vb8rs54LhjOMFxolgGfzIvwwcL1ZhtlJiDP/WTs2t1t66K0ypTsXp3IrmyqjO1tqgzPZ0xAYt3JnkAAAAASUVORK5CYII="
                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAMAAAANIilAAAAAYFBMVEUAAAD6Wlr5WFj7Wlr5WVn6WVn7W1v4WFj/VFT5WVn5WVn5WVn6WVn6WVn7W1v7WVn7W1v7WVn6Wlr/XFz5WFj7W1v/XV39XFz6WVn/Zmb5WFj5WVn5WVn6Wlr5WVn5WVnIvMgnAAAAH3RSTlMAZp9M2u0/vg2zcBnFjHlfWTkzMpZGLCYfCvVTz6h5gltZtQAAAP1JREFUSMft1d2OgjAQhuFOa1so8iciuO4u93+XcqBIMmP9Ih6o4T1/MikzCWrtFTlvh1nW97gtB1aJ2t5ybBN8MC8AMGvayks4PZldEqeGxrYS3tKYifAdURSPNQ8seQl7YlqypCW8p0sHyR7pWpBWRVPSux1NFZbZgqaMPPim9Wbebxpo1pHhjuD4q1scO4YNjtsl2Kz4K3CuWf8o9gPvJ4DYDkIaxKk0uQAx7TUrfMCqPhTPLywXMH5hfxzjF7bhGL+wlFn8wvI3X5Vb8rs54LhjOMFxolgGfzIvwwcL1ZhtlJiDP/WTs2t1t66K0ypTsXp3IrmyqjO1tqgzPZ0xAYt3JnkAAAAASUVORK5CYII="/><span
                                data-v-50e93753="">Feedback</span></div>
                        <div data-v-50e93753="" class="serviceCenter__container-items__item"><img data-v-50e93753=""
                                class=""
                                data-origin="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHgAAAB4CAMAAAAOusbgAAAAXVBMVEUAAAD4WFj5WVn6WVn4WFj5WVn6WVn6WVn3WFj5WVn6WVn5WVn5WVn4WFj4V1f5WVn7WVn/Xl73WFj5Wlr/Vlb6WVn5WVn5WVn5WFjzWlr5WFj6WVn5WVn5WVn5WVkLa5K7AAAAHnRSTlMAmd/wIHGPv4ZW0KBOv49BORNgLxD45a58CqXFtV94gzL6AAABwElEQVRo3u3Y227CMAyAYedYeoAC7Ths4Pd/zE2aNGswEIoXe2j+r9t+ipS2ScCyLMuyrF9tSnnvPX4VQKA5NQEvg+qNDWmC8NkjKsADsZJwbBA14BRQA557RA04dqgCR4+yMLmyMLkq8OxRB86oAyesCK/Tyt3o9aUevDm423msBm8Wd9wl1oPvuS7Ug9eOMWAOnBgDpj4vX6V2ehjOjAFTdM9i2DwGO96UJpjoNRd+xRL4o5YJL0tht+XBvhh2LQt+KYfdyID3yIDzsRx+48BuKIc7FpyPxfCOBbtYDAce3GrBh2IYeXDWgt2/g7PW5ErP9zp5HjwpfTIXUAwvWfCg9FtcbJQWAmtgwDutpc9bMXwGFtxrLW/drghejewtzKkAXg1HhU3boY3HP79NhQNjyPWOIk7VYJLxKprYVY+b8Kqqx03UjaclLRh6LXjulGCIXhwmWRgmWRomWQmGOQvDVApKMMS9MExtvThMtDRMdCMMU3HbBGGYGlNuOk9XBiH4OoMNNthgg3/KYIOfB/aXbgcy9ZdwDzKNl/AAQjXfXQ9SxYBIhQhiTQHJnUAw2gY3EWQb+g7R9yNYlmVZAO+iU4WdA6Pt7AAAAABJRU5ErkJggg=="
                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHgAAAB4CAMAAAAOusbgAAAAXVBMVEUAAAD4WFj5WVn6WVn4WFj5WVn6WVn6WVn3WFj5WVn6WVn5WVn5WVn4WFj4V1f5WVn7WVn/Xl73WFj5Wlr/Vlb6WVn5WVn5WVn5WFjzWlr5WFj6WVn5WVn5WVn5WVkLa5K7AAAAHnRSTlMAmd/wIHGPv4ZW0KBOv49BORNgLxD45a58CqXFtV94gzL6AAABwElEQVRo3u3Y227CMAyAYedYeoAC7Ths4Pd/zE2aNGswEIoXe2j+r9t+ipS2ScCyLMuyrF9tSnnvPX4VQKA5NQEvg+qNDWmC8NkjKsADsZJwbBA14BRQA557RA04dqgCR4+yMLmyMLkq8OxRB86oAyesCK/Tyt3o9aUevDm423msBm8Wd9wl1oPvuS7Ug9eOMWAOnBgDpj4vX6V2ehjOjAFTdM9i2DwGO96UJpjoNRd+xRL4o5YJL0tht+XBvhh2LQt+KYfdyID3yIDzsRx+48BuKIc7FpyPxfCOBbtYDAce3GrBh2IYeXDWgt2/g7PW5ErP9zp5HjwpfTIXUAwvWfCg9FtcbJQWAmtgwDutpc9bMXwGFtxrLW/drghejewtzKkAXg1HhU3boY3HP79NhQNjyPWOIk7VYJLxKprYVY+b8Kqqx03UjaclLRh6LXjulGCIXhwmWRgmWRomWQmGOQvDVApKMMS9MExtvThMtDRMdCMMU3HbBGGYGlNuOk9XBiH4OoMNNthgg3/KYIOfB/aXbgcy9ZdwDzKNl/AAQjXfXQ9SxYBIhQhiTQHJnUAw2gY3EWQb+g7R9yNYlmVZAO+iU4WdA6Pt7AAAAABJRU5ErkJggg=="/><span
                                data-v-50e93753="">Notification</span></div>
                        <div data-v-50e93753="" class="serviceCenter__container-items__item"><img data-v-50e93753=""
                                class="" data-origin="https://damanclub.in/assets/png/serviceCenter-ed250156.png"
                                src="https://damanclub.in/assets/png/serviceCenter-ed250156.png"/><span
                                data-v-50e93753="">24/7 Customer service</span></div>
                        <div data-v-50e93753="" class="serviceCenter__container-items__item"><img data-v-50e93753=""
                                class=""
                                data-origin="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAMAAAANIilAAAAAb1BMVEUAAAD6Wlr7W1v6WVn6Wlr7Wlr/Vlb5WVn6WVn6WFj3WFj6Wlr8XFz4WVn6WVn5WVn/Xl74WVn6WVn5WFj4V1f1WFj/TEz5WVn5WVn7WVn/XFz6WVn6WVn5WVn7Wlr4V1f4WFj7Wlr7W1v/Wlr5WVm+biE6AAAAJHRSTlMAZnnFP1kO306PIO8mcF+8E+y/fy4aBp9TORn1z8+yr5aCRh+2VEayAAABCElEQVRIx+3Wx3KDMBSF4SuhTjfFJT3R+z9j4qBINgGbq43tGf4lh29g0AZYu0UiM4d+wW2tYmZ8TZFj/LOt5mGbb6U9tj3jPSc+zg5TkunU+qSA0N5B76tJGWogtCPjVBZWtbHjJITIRNzxQtqJ5nDgAoC5p2KwSz1bG42JXfEl/JEMvcfg2i1POR5TP2k8Jq9uSaI+WE5/e7CjIvSvCFz6rUTj3IYoGtd+q/GvTctkqKQPdVQrXvEsLrA4hZDC4jcIdVhs4KQMhws4S7BdsQynL9rA/yqzVxdxutHqC+brDePTWGom4HpV8F42DBbXsROcaob+keUDlo2AiH64lQXE1jFYu5e+AXBomYy+4tfiAAAAAElFTkSuQmCC"
                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAMAAAANIilAAAAAb1BMVEUAAAD6Wlr7W1v6WVn6Wlr7Wlr/Vlb5WVn6WVn6WFj3WFj6Wlr8XFz4WVn6WVn5WVn/Xl74WVn6WVn5WFj4V1f1WFj/TEz5WVn5WVn7WVn/XFz6WVn6WVn5WVn7Wlr4V1f4WFj7Wlr7W1v/Wlr5WVm+biE6AAAAJHRSTlMAZnnFP1kO306PIO8mcF+8E+y/fy4aBp9TORn1z8+yr5aCRh+2VEayAAABCElEQVRIx+3Wx3KDMBSF4SuhTjfFJT3R+z9j4qBINgGbq43tGf4lh29g0AZYu0UiM4d+wW2tYmZ8TZFj/LOt5mGbb6U9tj3jPSc+zg5TkunU+qSA0N5B76tJGWogtCPjVBZWtbHjJITIRNzxQtqJ5nDgAoC5p2KwSz1bG42JXfEl/JEMvcfg2i1POR5TP2k8Jq9uSaI+WE5/e7CjIvSvCFz6rUTj3IYoGtd+q/GvTctkqKQPdVQrXvEsLrA4hZDC4jcIdVhs4KQMhws4S7BdsQynL9rA/yqzVxdxutHqC+brDePTWGom4HpV8F42DBbXsROcaob+keUDlo2AiH64lQXE1jFYu5e+AXBomYy+4tfiAAAAAElFTkSuQmCC"/><span
                                data-v-50e93753="">Beginner's Guide</span></div>
                        <div data-v-50e93753="" class="serviceCenter__container-items__item"><img data-v-50e93753=""
                                class=""
                                data-origin="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAMAAAANIilAAAAAP1BMVEUAAAD6Wlr6Wlr/VFT7Wlr6WVn4WFj5WVn6WFj6W1v5WVn6WVn5Wlr8WFj5XV3/UlL9XFz6WFj5WVn4WFj5WVkVSZV8AAAAFHRSTlMAZhkOS0Ag379Yf5/vOCYGLo+vb8qn634AAAEoSURBVEjH7dRbDoMgEEBRhqegqG3d/1qrTclIBnm1n95P8ThGguzuf6nBmAE66fbJqC4aGnUT1SPSRi7chA55M8WMq7CvQCmHAlWGGMKLn5hW2DeBNNMoknjcqnomB2+VpUbrWqxTk6f2yZirsw5FpCtmT6/a80B7CIJq+aBZPj1cUnVBFi99eCMwWcrDrSFhYU9dcwOBStizZ31cyfDJCaSf5AnDN+TapWkoxpSP8e4IpASn+GN4Ov2lCqCMkWNIyxg50iym+bDoARoxboeCHgzrsTRDH7bHku3EIBhb4AfMuzG/8QWWrVieMG/FMzul2rBnUb4Fq4XFcVmLJUdFOMWUZjnFlFJuCS5TbLYRFoTW8gXPM9Iyx9/QSmiZSyvX8CS707t/9AZGVTXAbo1QkAAAAABJRU5ErkJggg=="
                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAMAAAANIilAAAAAP1BMVEUAAAD6Wlr6Wlr/VFT7Wlr6WVn4WFj5WVn6WFj6W1v5WVn6WVn5Wlr8WFj5XV3/UlL9XFz6WFj5WVn4WFj5WVkVSZV8AAAAFHRSTlMAZhkOS0Ag379Yf5/vOCYGLo+vb8qn634AAAEoSURBVEjH7dRbDoMgEEBRhqegqG3d/1qrTclIBnm1n95P8ThGguzuf6nBmAE66fbJqC4aGnUT1SPSRi7chA55M8WMq7CvQCmHAlWGGMKLn5hW2DeBNNMoknjcqnomB2+VpUbrWqxTk6f2yZirsw5FpCtmT6/a80B7CIJq+aBZPj1cUnVBFi99eCMwWcrDrSFhYU9dcwOBStizZ31cyfDJCaSf5AnDN+TapWkoxpSP8e4IpASn+GN4Ov2lCqCMkWNIyxg50iym+bDoARoxboeCHgzrsTRDH7bHku3EIBhb4AfMuzG/8QWWrVieMG/FMzul2rBnUb4Fq4XFcVmLJUdFOMWUZjnFlFJuCS5TbLYRFoTW8gXPM9Iyx9/QSmiZSyvX8CS707t/9AZGVTXAbo1QkAAAAABJRU5ErkJggg=="/><span
                                data-v-50e93753="">About us</span></div>
                    </div>
                </div>
                <div data-v-50e93753="" class="serviceCenter-wrap-header"><button data-v-50e93753="" onClick={logout}><img
                            data-v-50e93753="" class=""
                            data-origin="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAMAAADVRocKAAAAQlBMVEUAAABlZWVmZmZlZWVmZmZmZmZmZmZkZGRmZmZlZWVra2tmZmZmZmZnZ2dmZmZgYGBmZmZmZmZlZWViYmJlZWVmZmYJxgEqAAAAFXRSTlMA799/v18fEI9vHz+fL68gT88wL2Dza9ruAAACiklEQVRo3u1YS2KrMAxE8hdMEmjr+1/1vbhpZxEcG4l0xSzB1li/EWY4ceKv4Yjd8E5wzjS8E/k/hgE4CU6CBkZi1yZwxKPI/Oeac6Y2Aeec2QuOn+/gNgGVhWan+WDLNuoJEZeldpcTt+9Nc+hJsp9z3hmmz+I2pZ4qghN02WffTv1l6lcw9NqP+/rA9DPcCFXRSQAG35tfs7+TTWemV9jvJwCDHYD6KiPToojcVZHua65SsSvdmVozN/MkJQhle2gFKMnlOjVk6YYgCudBfF2rMwIkJAh0l7CXDjjdRHP3Ff6VA9qRyYhyxQExAVygUH3FmqGPCTdW22TUEKDUbTVCk54g1Ow4MIsJEIn6cz3BiJM+JefjCAKfNy8RH3gsIWgfdYFOawmum/0UoYNyApiKm7TLMQRfm8GwmARyAqRz3RSpSUuAMuLNrUFLgF4mwVbNKjxslzi/lcAxOSEBIQcKQE8FVQSIqmiFgGiRIKdPAqIFZK0uIHoYmKoIiBIWsvY0D+Rojy46oIxwVMFMBiQzGc/1sJV6DFnfy/i+qjGbY4rUVmNHegKudmwgZTPj63qqfw3wEQ7MVRXUV6pDiitXHAqqEoIDW/CkVbwIBwT3ZEB2T8ZVXRUgbh/BikUCAVL8MWkE2HSdwojtr81lnguD0D53TBRPIgaToRENXEiQh4i/jr0Mq99Rnxb2uxi4xLNbWRfaYx+ZznOXE+Gakd9uhscm0zZvqCy1QVAVJU6+aV6o8p4fFHM1tik+zNuLbHhwfnDE9Hz2FH9ek3hIeUP5BzaO6VLyOE3LGO3vGzKaEeVHzhXAvBLuWrdu03AEwjJv+MHzVxiOg1/MbFcqltnO4+KHEydO9OIfEq1JSVrjUXcAAAAASUVORK5CYII="
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAMAAADVRocKAAAAQlBMVEUAAABlZWVmZmZlZWVmZmZmZmZmZmZkZGRmZmZlZWVra2tmZmZmZmZnZ2dmZmZgYGBmZmZmZmZlZWViYmJlZWVmZmYJxgEqAAAAFXRSTlMA799/v18fEI9vHz+fL68gT88wL2Dza9ruAAACiklEQVRo3u1YS2KrMAxE8hdMEmjr+1/1vbhpZxEcG4l0xSzB1li/EWY4ceKv4Yjd8E5wzjS8E/k/hgE4CU6CBkZi1yZwxKPI/Oeac6Y2Aeec2QuOn+/gNgGVhWan+WDLNuoJEZeldpcTt+9Nc+hJsp9z3hmmz+I2pZ4qghN02WffTv1l6lcw9NqP+/rA9DPcCFXRSQAG35tfs7+TTWemV9jvJwCDHYD6KiPToojcVZHua65SsSvdmVozN/MkJQhle2gFKMnlOjVk6YYgCudBfF2rMwIkJAh0l7CXDjjdRHP3Ff6VA9qRyYhyxQExAVygUH3FmqGPCTdW22TUEKDUbTVCk54g1Ow4MIsJEIn6cz3BiJM+JefjCAKfNy8RH3gsIWgfdYFOawmum/0UoYNyApiKm7TLMQRfm8GwmARyAqRz3RSpSUuAMuLNrUFLgF4mwVbNKjxslzi/lcAxOSEBIQcKQE8FVQSIqmiFgGiRIKdPAqIFZK0uIHoYmKoIiBIWsvY0D+Rojy46oIxwVMFMBiQzGc/1sJV6DFnfy/i+qjGbY4rUVmNHegKudmwgZTPj63qqfw3wEQ7MVRXUV6pDiitXHAqqEoIDW/CkVbwIBwT3ZEB2T8ZVXRUgbh/BikUCAVL8MWkE2HSdwojtr81lnguD0D53TBRPIgaToRENXEiQh4i/jr0Mq99Rnxb2uxi4xLNbWRfaYx+ZznOXE+Gakd9uhscm0zZvqCy1QVAVJU6+aV6o8p4fFHM1tik+zNuLbHhwfnDE9Hz2FH9ek3hIeUP5BzaO6VLyOE3LGO3vGzKaEeVHzhXAvBLuWrdu03AEwjJv+MHzVxiOg1/MbFcqltnO4+KHEydO9OIfEq1JSVrjUXcAAAAASUVORK5CYII="/>
                        Log out</button></div>
                <div data-v-cf21bf5a="" data-v-50e93753="" class="dialog inactive">
                    <div data-v-cf21bf5a="" class="dialog__container" role="dialog" tabindex="0">
                        <div data-v-cf21bf5a="" class="dialog__container-img"><img data-v-cf21bf5a="" alt="" class=""
                                data-origin="https://damanclub.in/assets/png/orderCancelWarn-ac58c333.png"
                                src="https://damanclub.in/assets/png/orderCancelWarn-ac58c333.png"/></div>
                        <div data-v-cf21bf5a="" class="dialog__container-title">
                            <h1 data-v-cf21bf5a="">Do you want to log out?</h1>
                        </div>
                        <div data-v-cf21bf5a="" class="dialog__container-content"></div>
                        <div data-v-cf21bf5a="" class="dialog__container-footer"><button
                                data-v-cf21bf5a="">Cancel</button><button data-v-cf21bf5a="">Confirm</button></div>
                        
                    </div>
                    <div data-v-cf21bf5a="" class="dialog__outside"></div>
                </div>
            </div>
        </div>
   </>
  )
}

export default Account
