import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

function Deposithistory() {
    const navigate = useNavigate();
    

    useEffect(() => {
      const token = localStorage.getItem("token");
      if (!token) {
        navigate("/signup");
      }
    }, [navigate]);

  return (
<>
<div data-v-f851bd18="" class="rechargeh__container"
           style={{ "--f13b4d11-currentFontFamily": "'Roboto', 'Inter', sans-serif" }}>
            <div data-v-12a80a3e="" data-v-f851bd18="" class="navbar white">
                <div data-v-12a80a3e="" class="navbar-fixed">
                    <div data-v-12a80a3e="" class="navbar__content">
                        <div data-v-12a80a3e="" class="navbar__content-left"><i data-v-12a80a3e=""
                                class="van-badge__wrapper van-icon van-icon-arrow-left" onClick={() => navigate(-1)}></i></div>
                        <div data-v-12a80a3e="" class="navbar__content-center">
                            <div data-v-12a80a3e="" class="navbar__content-title">Deposit history</div>
                        </div>
                        <div data-v-12a80a3e="" class="navbar__content-right"></div>
                    </div>
                </div>
            </div>
            <div data-v-f851bd18="" class="rechargeh__header_box">
                <div data-v-f851bd18="" class="fun-tabs tabs" is-auto-load="true" activeclassname="tab_active">
                    <div class="fun-tabs__tab-list"
                        style={{ transitionTimingFunction: "cubic-bezier(0.25, 0.46, 0.45, 0.94)", transitionDuration: "360ms", transform: "translate3d(0px, 0px, 0px)" }}
>
                        <div class="fun-tab-item funtab_item activeClassName" style={{ color: "rgb(22, 119, 255)" }}
                        >
                            <div class="fun-tab-item__wrap">
                                <div class="fun-tab-item__label">
                                    <div data-v-f851bd18="" class="tab_item tab_active"><svg data-v-f851bd18=""
                                            class="svg-icon icon-all">
                                            <use xlinkhref="#icon-all"></use>
                                        </svg><span data-v-f851bd18="">All</span></div>
                                </div>
                            </div>
                        </div>
                        <div class="fun-tab-item funtab_item">
                            <div class="fun-tab-item__wrap">
                                <div class="fun-tab-item__label">
                                    <div data-v-f851bd18="" class="tab_item"><img data-v-f851bd18=""
                                            src="https://ossimg.envyenvelope.com/daman/payNameIcon/payNameIcon_20230808040003ju9r.png"
                                            alt=""/><span data-v-f851bd18="">Local UPI</span></div>
                                </div>
                            </div>
                        </div>
                        <div class="fun-tab-item funtab_item">
                            <div class="fun-tab-item__wrap">
                                <div class="fun-tab-item__label">
                                    <div data-v-f851bd18="" class="tab_item"><img data-v-f851bd18=""
                                            src="https://ossimg.envyenvelope.com/daman/payNameIcon/payNameIcon_20230824183241hlx2.png"
                                            alt=""/><span data-v-f851bd18="">E-Wallet</span></div>
                                </div>
                            </div>
                        </div>
                        <div class="fun-tab-item funtab_item">
                            <div class="fun-tab-item__wrap">
                                <div class="fun-tab-item__label">
                                    <div data-v-f851bd18="" class="tab_item"><img data-v-f851bd18=""
                                            src="https://ossimg.envyenvelope.com/daman/payNameIcon/payNameIcon_20230824184753jw3f.png"
                                            alt=""/><span data-v-f851bd18="">Paytm x QR</span></div>
                                </div>
                            </div>
                        </div>
                        <div class="fun-tab-item funtab_item">
                            <div class="fun-tab-item__wrap">
                                <div class="fun-tab-item__label">
                                    <div data-v-f851bd18="" class="tab_item"><img data-v-f851bd18=""
                                            src="https://ossimg.envyenvelope.com/daman/payNameIcon/payNameIcon_202308080410066jdc.png"
                                            alt=""/><span data-v-f851bd18="">UPI x QR</span></div>
                                </div>
                            </div>
                        </div>
                        <div class="fun-tab-item funtab_item">
                            <div class="fun-tab-item__wrap">
                                <div class="fun-tab-item__label">
                                    <div data-v-f851bd18="" class="tab_item"><img data-v-f851bd18=""
                                            src="https://ossimg.envyenvelope.com/daman/payNameIcon/payNameIcon_20230808035808n6cg.png"
                                            alt=""/><span data-v-f851bd18="">Local Bank</span></div>
                                </div>
                            </div>
                        </div>
                        <div class="fun-tab-item funtab_item">
                            <div class="fun-tab-item__wrap">
                                <div class="fun-tab-item__label">
                                    <div data-v-f851bd18="" class="tab_item"><img data-v-f851bd18=""
                                            src="https://ossimg.envyenvelope.com/daman/payNameIcon/payNameIcon_2023080804094137ww.png"
                                            alt=""/><span data-v-f851bd18="">USDT</span></div>
                                </div>
                            </div>
                        </div>
                        <div class="fun-tab-item funtab_item">
                            <div class="fun-tab-item__wrap">
                                <div class="fun-tab-item__label">
                                    <div data-v-f851bd18="" class="tab_item"><img data-v-f851bd18=""
                                            src="https://ossimg.envyenvelope.com/daman/payNameIcon/payNameIcon_20230808040928kyt8.png"
                                            alt=""/><span data-v-f851bd18="">TRX</span></div>
                                </div>
                            </div>
                        </div>
                        <div class="fun-tabs__active-line"
                            style={{ transition: "all 300ms ease 0s", width: "0px", height: "3px", transform: "translate3d(53.5px, 0px, 0px)", backgroundColor: "rgb(22, 119, 255)" }}
>
                        </div>
                    </div>
                </div>
                <div data-v-f851bd18="" class="query_select">
                    <div data-v-f851bd18="" class="ar-searchbar__selector">
                        <div data-v-f851bd18="" class="selectorA"><span data-v-f851bd18=""
                                class="ar-searchbar__selector-default">All</span><i data-v-f851bd18=""
                                class="van-badge__wrapper van-icon van-icon-arrow-down"></i></div>
                    </div>
                    <div class="ar-searchbar__selector">
                        <div><span class="noSelect">Choose a date</span><i
                                class="van-badge__wrapper van-icon van-icon-arrow-down"></i></div>
                    </div>
                    
                </div>
            </div>
            <div data-v-61888f52="" data-v-f851bd18="" class="infiniteScroll"
                id="refreshd1afad67abbd4a7f9619949c89f4f133">
                <div data-v-f851bd18="" class="rechargeh__container-content">
                    <div data-v-f851bd18="" class="rechargeh__container-content__item">
                        <div data-v-f851bd18="" class="rechargeh__container-content__item-header"><span
                                data-v-f851bd18="" class="recharge_tit">Deposit</span>
                       
                            <div data-v-f851bd18="" class="recharge_right recharge">To Be Paid <i data-v-f851bd18=""
                                    class="van-badge__wrapper van-icon van-icon-arrow"></i></div>
                        </div>
                        <div data-v-f851bd18="" role="separator" class="van-divider van-divider--hairline divier">
                            </div>
                        <div data-v-f851bd18="" class="rechargeh__container-content__item-body">
                            <div data-v-f851bd18=""><span data-v-f851bd18="">Balance</span><span data-v-f851bd18=""
                                    class="price">₹500.00</span></div>
                            <div data-v-f851bd18=""><span data-v-f851bd18="">Type</span><span data-v-f851bd18="">Daman -
                                    UPI x QR</span></div>
                            <div data-v-f851bd18=""><span data-v-f851bd18="">Time</span><span
                                    data-v-f851bd18="">2024-06-13 04:40:24</span></div>
                            <div data-v-f851bd18=""><span data-v-f851bd18="">Order number</span>
                                <div data-v-f851bd18="" class="order"><span
                                        data-v-f851bd18="">P2024061223102488307686</span><svg data-v-f851bd18=""
                                        class="svg-icon icon-copy">
                                        <use xlinkhref="#icon-copy"></use>
                                    </svg></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div data-v-61888f52="" class="infiniteScroll__loading">
                    <div data-v-61888f52="">No more</div>
                </div>
            </div>
        </div>

</>
  )
}

export default Deposithistory
