import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

function Wingouserbethis({cart}) {

    const [hidedisplay, setdisplay] = useState("none");

    const hidedisplays = () => {
        setdisplay((prevDisplay) => (prevDisplay === "none" ? "" : "none"));
      };


      const navigate = useNavigate();

      useEffect(() => {
        const token = localStorage.getItem("token");
        if (!token) {
          navigate("/signup");
        }
      }, [navigate]);
  

  return (
    <>


        <div data-v-2faec5cb="" data-v-4b21e13b="" class="MyGameRecordList__C">
            <div data-v-2faec5cb="" class="MyGameRecordList__C-item" onClick={hidedisplays}>
                <div data-v-2faec5cb="" className={`MyGameRecordList__C-item-l MyGameRecordList__C-item-l-${cart.select}`}> {cart.select === 'Green' ? '\u00A0' : cart.select === 'Red' ? '\u00A0' : cart.select}</div>
                <div data-v-2faec5cb="" class="MyGameRecordList__C-item-m">
                    <div data-v-2faec5cb="" class="MyGameRecordList__C-item-m-top">{cart.priodno}</div>
                    <div data-v-2faec5cb="" class="MyGameRecordList__C-item-m-bottom">2024-06-24 03:56:54</div>
                </div>
                <div data-v-2faec5cb=""  style={{
              display:
                cart.status === " "
                  ? "none"
                  : cart.status === "success" || "failed"
                  ? ""
                  : "",
            }} class={`MyGameRecordList__C-item-r ${
                cart.status === "success"
                  ? "success"
                  : cart.status === "failed"
                  ? ""
                  : ""
              }`}>
                    <div data-v-2faec5cb="" class="">{cart.status}</div><span data-v-2faec5cb="">₹{cart.winloss}</span>
                </div>
            </div>



            <div data-v-2faec5cb="" class="MyGameRecordList__C-detail" style={{display:hidedisplay}}>
    <div data-v-2faec5cb="" class="MyGameRecordList__C-detail-text">Details</div>
    <div data-v-2faec5cb="" class="MyGameRecordList__C-detail-line"><span data-v-2faec5cb="">Order number</span>
        <div data-v-2faec5cb="">{cart._id}{" "} <svg data-v-2faec5cb="" class="svg-icon icon-copy">
                <use xlinkhref="#icon-copy"></use>
            </svg></div>
    </div>
    <div data-v-2faec5cb="" class="MyGameRecordList__C-detail-line"><span data-v-2faec5cb="">Period</span>
        <div data-v-2faec5cb="">{cart.priodno}</div>
    </div>
    <div data-v-2faec5cb="" class="MyGameRecordList__C-detail-line"><span data-v-2faec5cb="">Purchase amount</span>
        <div data-v-2faec5cb="">₹{cart.pamount}</div>
    </div>
    {/* <div data-v-2faec5cb="" class="MyGameRecordList__C-detail-line"><span data-v-2faec5cb="">Quantity</span>
        <div data-v-2faec5cb="">2</div>
    </div> */}
    <div data-v-2faec5cb="" class="MyGameRecordList__C-detail-line"><span data-v-2faec5cb="">Amount after tax</span>
        <div data-v-2faec5cb="" class="red">₹{cart.amountaftertax}</div>
    </div>
    <div data-v-2faec5cb="" class="MyGameRecordList__C-detail-line"><span data-v-2faec5cb="">Tax</span>
        <div data-v-2faec5cb="">₹{cart.tax}</div>
    </div>
    <div data-v-2faec5cb="" class="MyGameRecordList__C-detail-line"><span data-v-2faec5cb="">Result</span>
        <div data-v-2faec5cb="">
            <div data-v-2faec5cb="" class="MyGameRecordList__C-inlineB">{cart.resultnumber}</div>
            <div data-v-2faec5cb="" class={`MyGameRecordList__C-inlineB ${
                  cart.resultcolor === "green"
                    ? "greenColor"
                    : cart.resultcolor === "red"
                    ? "redColor"
                    : ""
                }`}>Green</div>
            <div data-v-2faec5cb="" class="MyGameRecordList__C-inlineB purpleColor">{cart.resultcolor}</div>
            <div data-v-2faec5cb="" class="MyGameRecordList__C-inlineB big"> {cart.resultbigsmall}</div>
        </div>
    </div>
    <div data-v-2faec5cb="" class="MyGameRecordList__C-detail-line"><span data-v-2faec5cb="">select</span>
        <div data-v-2faec5cb="">{cart.select}</div>
    </div>
    <div data-v-2faec5cb="" class="MyGameRecordList__C-detail-line"><span data-v-2faec5cb="">Status</span>
        <div data-v-2faec5cb="" class={cart.status === 'success' ? 'green' : cart.status === 'failed' ? 'red' : ''}>Failed</div>
    </div>
    <div data-v-2faec5cb="" class="MyGameRecordList__C-detail-line"><span data-v-2faec5cb="">Win/lose</span>
        <div data-v-2faec5cb="" class={cart.status === 'success' ? 'green' : cart.status === 'failed' ? 'red' : ''}> ₹{cart.winloss}</div>
    </div>
    <div data-v-2faec5cb="" class="MyGameRecordList__C-detail-line"><span data-v-2faec5cb="">Order time</span>
        <div data-v-2faec5cb="">2024-06-24 03:56:54</div>
    </div>
</div>
           
        </div>
  
    
    </>
  )
}

export default Wingouserbethis
