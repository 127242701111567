import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

function Transactionhistory() {
    const navigate = useNavigate();
   

    useEffect(() => {
      const token = localStorage.getItem("token");
      if (!token) {
        navigate("/signup");
      }
    }, [navigate]);

  return (
    <>
      <div data-v-2565e76d="" class="transRecord__container"
            style={{ '--f13b4d11-currentFontFamily': "'Roboto', 'Inter', sans-serif" }}>
            <div data-v-12a80a3e="" data-v-2565e76d="" class="navbar">
                <div data-v-12a80a3e="" class="navbar-fixed">
                    <div data-v-12a80a3e="" class="navbar__content">
                        <div data-v-12a80a3e="" class="navbar__content-left"><i data-v-12a80a3e=""
                                class="van-badge__wrapper van-icon van-icon-arrow-left" onClick={() => navigate(-1)}></i></div>
                        <div data-v-12a80a3e="" class="navbar__content-center">
                            <div data-v-12a80a3e="" class="navbar__content-title">Transaction history</div>
                        </div>
                        <div data-v-12a80a3e="" class="navbar__content-right"></div>
                    </div>
                </div>
            </div>
            <div data-v-2565e76d="" style={{height: "65px"}}>
                <div data-v-2565e76d="" class="ar">
                    <div data-v-2565e76d="" class="ar-searchbar">
                        <div data-v-fa757a88="" data-v-2565e76d="" class="ar-searchbar__selector">
                            <div data-v-fa757a88=""><span data-v-fa757a88=""
                                    class="ar-searchbar__selector-default">All</span><i data-v-fa757a88=""
                                    class="van-badge__wrapper van-icon van-icon-arrow-down"></i>
                            </div>
                        </div>
                        <div data-v-fa757a88="" data-v-2565e76d="" class="ar-searchbar__selector">
                            <div data-v-fa757a88=""><span data-v-fa757a88=""
                                    class="ar-searchbar__selector-default">Choose a date</span><i data-v-fa757a88=""
                                    class="van-badge__wrapper van-icon van-icon-arrow-down"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div data-v-61888f52="" data-v-2565e76d="" class="infiniteScroll"
                id="refresh15cdd84d3efe4d6faa7a6da52d68a4f6">
                <div data-v-2565e76d="" class="transRecord__container-content">
                    
                   
                    
                    
                    
                    <div data-v-2565e76d="" class="transRecord__container-content__item">
                        <div data-v-2565e76d="" class="transRecord__container-content__card">
                            <div data-v-2565e76d="" class="transRecord__container-content__card-top">
                                <h3 data-v-2565e76d="">Agent commission</h3>
                            </div>
                            <div data-v-2565e76d="" class="transRecord__container-content__card-mid">
                                <div data-v-2565e76d="" class="line">
                                    <div data-v-2565e76d="" class="left">Detail</div>
                                    <div data-v-2565e76d="" class="right">Agent commission</div>
                                </div>
                                <div data-v-2565e76d="" class="line">
                                    <div data-v-2565e76d="" class="left">Time</div>
                                    <div data-v-2565e76d="" class="right">2024-06-13 02:50:24</div>
                                </div>
                                <div data-v-2565e76d="" class="line">
                                    <div data-v-2565e76d="" class="left">Balance</div>
                                    <div data-v-2565e76d="" class="right green" >₹9.25</div>
                                </div>
                            </div>
                            <div data-v-2565e76d="" class="transRecord__container-content__card-bot"><textarea
                                    data-v-2565e76d="" class="textarea" name="remark" cols="30" rows="10"
                                    readonly=""></textarea></div>
                        </div>
                    </div>
                    
                    <div data-v-2565e76d="" class="transRecord__container-content__item">
                        <div data-v-2565e76d="" class="transRecord__container-content__card">
                            <div data-v-2565e76d="" class="transRecord__container-content__card-top">
                                <h3 data-v-2565e76d="">Bet</h3>
                            </div>
                            <div data-v-2565e76d="" class="transRecord__container-content__card-mid">
                                <div data-v-2565e76d="" class="line">
                                    <div data-v-2565e76d="" class="left">Detail</div>
                                    <div data-v-2565e76d="" class="right">Bet</div>
                                </div>
                                <div data-v-2565e76d="" class="line">
                                    <div data-v-2565e76d="" class="left">Time</div>
                                    <div data-v-2565e76d="" class="right">2024-06-11 04:28:28</div>
                                </div>
                                <div data-v-2565e76d="" class="line">
                                    <div data-v-2565e76d="" class="left">Balance</div>
                                    <div data-v-2565e76d="" class="right red" >₹13.00</div>
                                </div>
                            </div>
                            <div data-v-2565e76d="" class="transRecord__container-content__card-bot"><textarea
                                    data-v-2565e76d="" class="textarea" name="remark" cols="30" rows="10"
                                    readonly=""></textarea></div>
                        </div>
                    </div>
                    
                </div>
                <div data-v-61888f52="" class="infiniteScroll__loading">
                    <div data-v-61888f52="">No more</div>
                </div>
            </div>
        </div>
    
    
    </>
  )
}

export default Transactionhistory
