import React, { useContext, useEffect, useState } from 'react'
import { MyContext } from '../../Context/MyContext';
import { useNavigate } from 'react-router-dom';
import Wingobetting from '../../Wingo/Wingoparts/Wingobetting';
import Wingorehis from '../../Wingo/Wingoparts/Wingorehis';
import Wingouserbethis from '../../Wingo/Wingoparts/Wingouserbethis';

function Wino5() {

    const context = useContext(MyContext);

    const getRemainingTime = () => {
      const currentTime = new Date();
  
      // Get the total seconds from the current time
      const totalSeconds = currentTime.getMinutes() * 60 + currentTime.getSeconds();
  
      // Calculate seconds passed in the current 3-minute block
      const secondsPassedIn3Min = totalSeconds % 300;
  
      // Calculate remaining seconds for the countdown
      const remainingTimeInSeconds = 300 - secondsPassedIn3Min;
  
      return remainingTimeInSeconds;
    };
  
  
    const [timeLeft, setTimeLeft] = useState(getRemainingTime());
  
    useEffect(() => {
      const interval = setInterval(() => {
        setTimeLeft(getRemainingTime()); // Update timeLeft every second
      }, 1000);
  
      return () => clearInterval(interval); // Cleanup interval on unmount
    }, []);
  
    // Convert total seconds left into minutes and seconds
    const minutes = String(Math.floor(timeLeft / 60)).padStart(2, '0');
    const seconds = String(timeLeft % 60).padStart(2, '0');

    const { setfootershow, formattedTime, historyi3,timeid,minpriodno5, userbethistory, myhistory,setmyhistory,harry,
      handelper} =
      context;
     
  
    
  
      
  
      
  
    
  
      const navigate = useNavigate();
  
      useEffect(() => {
        const token = localStorage.getItem("token");
        if (!token) {
          navigate("/signup");
        }
      }, [navigate]);
  
  
    useEffect(() => {
      setfootershow("none");
    }, []);
  
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
  
    // Reverse the array to show the last items first
    const reversedHistory = [...historyi3].reverse();
  
    // console.log(reversedHistory, "mukeem very");
  
    // Calculate the indices for the items to be displayed on the current page
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = reversedHistory.slice(indexOfFirstItem, indexOfLastItem);
  
    const handleNext = () => {
      if (indexOfLastItem < reversedHistory.length) {
        setCurrentPage((prevPage) => prevPage + 1);
      }
    };
  
    const handleBack = () => {
      if (currentPage > 1) {
        setCurrentPage((prevPage) => prevPage - 1);
      }
    };
  
    const [currentPageu, setCurrentPageu] = useState(1);
    const itemsPerPageu = 10;
  
    // Reverse the array to show the last items first
    const reversedHistoryu = [...userbethistory].reverse();
  
    // console.log(reversedHistory, "mukeem very");
  
    // Calculate the indices for the items to be displayed on the current page
    const indexOfLastItemu = currentPageu * itemsPerPageu;
    const indexOfFirstItemu = indexOfLastItemu - itemsPerPageu;
    const currentItemsu = reversedHistoryu.slice(indexOfFirstItemu, indexOfLastItemu);
  
    const handleNextu = () => {
      if (indexOfLastItemu < reversedHistoryu.length) {
        setCurrentPageu((prevPage) => prevPage + 1);
      }
    };
  
    const handleBacku = () => {
      if (currentPageu > 1) {
        setCurrentPageu((prevPage) => prevPage - 1);
      }
    };
  
  
    const myhistory1 = () => {
      setmyhistory(false);
    };
  
    const myhistory2 = () => {
      setmyhistory(true);
    };
  return (
   <>

<div data-v-c0d1aac6="" class="TimeLeft__C">
            <div data-v-c0d1aac6="" class="TimeLeft__C-rule">
              How to play {harry}
            </div>
            <div data-v-c0d1aac6="" class="TimeLeft__C-name">
              Win Go 1Min
            </div>
            <div data-v-c0d1aac6="" class="TimeLeft__C-num">
              <div data-v-c0d1aac6="" class="n4"></div>
              <div data-v-c0d1aac6="" class="n9"></div>
              <div data-v-c0d1aac6="" class="n7"></div>
              <div data-v-c0d1aac6="" class="n8"></div>
              <div data-v-c0d1aac6="" class="n5"></div>
            </div>
            <div data-v-c0d1aac6="" class="TimeLeft__C-id">
              {minpriodno5}
            </div>
            <div data-v-c0d1aac6="" class="TimeLeft__C-text">
              Time remaining
            </div>
            <div data-v-c0d1aac6="" class="TimeLeft__C-time">
              
              <div data-v-c0d1aac6="">{minutes}</div>
              <div data-v-c0d1aac6="">:</div>
              <div data-v-c0d1aac6="">{seconds}</div>
            </div>
          </div>

       
          <Wingobetting/>

          <div data-v-4e271e20="" data-v-f9e6ba25="" class="RecordNav__C">
            <div data-v-4e271e20="" class={myhistory ? 'active': ' '} onClick={myhistory2}>
              Game history
            </div>
            <div data-v-4e271e20="" class="">
              Chart
            </div>
            <div data-v-4e271e20="" class={myhistory ? ' ': ' active'} onClick={myhistory1}>
              My history
            </div>
          </div>


          <div
            data-v-c74f4bba=""
            data-v-f9e6ba25=""
            class="GameRecord__C"
            style={{ display: myhistory ? " " : "none" }}
            
          >
            <div data-v-c74f4bba="" class="GameRecord__C-head">
              <div data-v-c74f4bba="" class="van-row">
                <div data-v-c74f4bba="" class="van-col van-col--8">
                  Period
                </div>
                <div data-v-c74f4bba="" class="van-col van-col--5">
                  Number
                </div>
                <div data-v-c74f4bba="" class="van-col van-col--5">
                  Big Small
                </div>
                <div data-v-c74f4bba="" class="van-col van-col--6">
                  Color
                </div>
              </div>
            </div>
            <div data-v-c74f4bba="" class="GameRecord__C-body">
              {reversedHistory.length === 0 ? (
                <h3>You have no item in your cart, please add it!</h3>
              ) : (
                <>
                  {currentItems.map((cart) => (
                    <Wingorehis key={cart._id} cart={cart} />
                  ))}

                  <div data-v-c74f4bba="" class="GameRecord__C-foot">
                    <div
                      data-v-c74f4bba=""
                      className={`GameRecord__C-foot-previous ${
                        currentPage === 1 ? "disabled" : ""
                      }`}
                    >
                      <i
                        onClick={handleBack}
                        data-v-c74f4bba=""
                        class="van-badge__wrapper van-icon van-icon-arrow-left GameRecord__C-icon"
                        style={{ fontsize: "20px" }}
                      ></i>
                    </div>
                    <div data-v-c74f4bba="" class="GameRecord__C-foot-page">
                      {currentPage}/
                      {Math.ceil(reversedHistory.length / itemsPerPage)}
                    </div>
                    <div
                      data-v-c74f4bba=""
                      className={`GameRecord__C-foot-next ${
                        indexOfLastItem >= reversedHistory.length
                          ? "disabled"
                          : ""
                      }`}
                    >
                      <i
                        onClick={handleNext}
                        data-v-c74f4bba=""
                        class="van-badge__wrapper van-icon van-icon-arrow GameRecord__C-icon"
                        style={{ fontsize: "20px" }}
                      ></i>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>

          <div
            data-v-4b21e13b=""
            data-v-5d71c3fd=""
            class="MyGameRecord__C game-record"
            style={{ display: myhistory ? "none" : "" }}
          >
            <div data-v-4b21e13b="" class="MyGameRecord__C-head">
              <div data-v-4b21e13b="" class="MyGameRecord__C-head-moreB">
                Detail{" "}
                <svg data-v-4b21e13b="" class="svg-icon icon-rightCircle">
                  <use xlinkhref="#icon-rightCircle"></use>
                </svg>
              </div>
            </div>

            <div data-v-4b21e13b="" class="MyGameRecord__C-body">
              {/* <Wingouserbethis/> */}


            {reversedHistoryu.length === 0 ? (
                <h3>You have no item in your cart, please add it!</h3>
              ) : (
                <>
                  {currentItemsu.map((cart) => (
                    <Wingouserbethis key={cart._id} cart={cart} />
                  ))}

                  <div data-v-c74f4bba="" class="GameRecord__C-foot">
                    <div
                      data-v-c74f4bba=""
                      className={`GameRecord__C-foot-previous ${
                        currentPageu === 1 ? "disabled" : ""
                      }`}
                    >
                      <i
                        onClick={handleBacku}
                        data-v-c74f4bba=""
                        class="van-badge__wrapper van-icon van-icon-arrow-left GameRecord__C-icon"
                        style={{ fontsize: "20px" }}
                      ></i>
                    </div>
                    <div data-v-c74f4bba="" class="GameRecord__C-foot-page">
                      {currentPageu}/
                      {Math.ceil(reversedHistoryu.length / itemsPerPageu)}
                    </div>
                    <div
                      data-v-c74f4bba=""
                      className={`GameRecord__C-foot-next ${
                        indexOfLastItemu >= reversedHistoryu.length
                          ? "disabled"
                          : ""
                      }`}
                    >
                      <i
                        onClick={handleNextu}
                        data-v-c74f4bba=""
                        class="van-badge__wrapper van-icon van-icon-arrow GameRecord__C-icon"
                        style={{ fontsize: "20px" }}
                      ></i>
                    </div>
                  </div>
                </>
              )}
            </div>

         
          </div>

          <div data-v-cf21bf5a="" data-v-f9e6ba25="" class="dialog inactive">
            <div
              data-v-cf21bf5a=""
              class="dialog__container"
              role="dialog"
              tabindex="0"
            >
              <div data-v-cf21bf5a="" class="dialog__container-img">
                <img
                  data-v-cf21bf5a=""
                  alt=""
                  class=""
                  data-origin="https://damangames.inhttps://damangames.in/assets/png/orderCancelWarn-ac58c333.png"
                  src="https://damangames.inhttps://damangames.in/assets/png/orderCancelWarn-ac58c333.png"
                />
              </div>
              <div data-v-cf21bf5a="" class="dialog__container-title">
                <h1 data-v-cf21bf5a=""></h1>
              </div>
              <div data-v-cf21bf5a="" class="dialog__container-content">
                <div data-v-f9e6ba25=""></div>
              </div>
              <div data-v-cf21bf5a="" class="dialog__container-footer">
                <button data-v-cf21bf5a="">Cancel</button>
                <button data-v-cf21bf5a="">Confirm</button>
              </div>
            </div>
            <div data-v-cf21bf5a="" class="dialog__outside"></div>
          </div>
          <div
            data-v-537dde41=""
            data-v-f9e6ba25=""
            class="WinningTip__C"
            style={{ display: "none" }}
          >
            <div data-v-537dde41="" class="WinningTip__C-body">
              <div data-v-537dde41="" class="WinningTip__C-body-l1">
                Congratulations
              </div>
              <div
                data-v-537dde41=""
                class="WinningTip__C-body-l2 typeundefined"
              >
                Lottery results <div data-v-537dde41=""></div>
                <div data-v-537dde41="" class="WinningNum"></div>
                <div data-v-537dde41="">Small</div>
              </div>
              <div data-v-537dde41="" class="WinningTip__C-body-l3">
                <div data-v-537dde41="" class="head">
                  Bonus
                </div>
                <div data-v-537dde41="" class="bonus"></div>
                <div data-v-537dde41="" class="gameDetail">
                  Period:{" "}
                </div>
              </div>
              <div data-v-537dde41="" class="WinningTip__C-body-l4">
                <div data-v-537dde41="" class="acitveBtn"></div> 3 seconds auto
                close
              </div>
              <div data-v-537dde41="" class="closeBtn"></div>
            </div>
          </div>
          <div
            data-v-8a2fbe28=""
            data-v-f9e6ba25=""
            class="changlongEnter"
          ></div>
   
   
   </>
  )
}

export default Wino5
