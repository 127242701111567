import React, { useContext, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { MyContext } from '../Context/MyContext';

function Footer() {

    const context=useContext(MyContext);

    const {footershow } = context;

   




  return (
    <>



        
<div data-v-6ab3f23e="" class="tabbar__container"
     style={{ "--f13b4d11-currentFontFamily": "'Roboto', 'Inter', sans-serif",display:footershow }}>
       
    <div data-v-6ab3f23e="" class="tabbar__container-item active">
     

        <svg data-v-6ab3f23e="" class="svg-icon icon-home">
            <Link to="/"><use href="#icon-home" style={{color:""}}></use> </Link>
        </svg>
        <span data-v-6ab3f23e=""><Link to="/">Home</Link></span>
    </div>
    <div data-v-6ab3f23e="" class="tabbar__container-item">
        <svg data-v-6ab3f23e="" class="svg-icon icon-activity">
        <Link to="/activity"><use href="#icon-activity" style={{color:""}}></use> </Link>
            
           
        </svg>
        <span data-v-6ab3f23e=""><Link to="/activity">Activity</Link></span>
    </div>
    <div data-v-6ab3f23e="" class="tabbar__container-item">
        <svg data-v-6ab3f23e="" class="svg-icon icon-promotion">
        <Link to="/promotion"><use href="#icon-promotion" style={{color:""}}></use> </Link>
            
        </svg>
        <div data-v-6ab3f23e="" class="promotionBg"></div>
        <span data-v-6ab3f23e=""><Link to="/promotion">Promotion</Link></span>
    </div>
    <div data-v-6ab3f23e="" class="tabbar__container-item">
        <svg data-v-6ab3f23e="" class="svg-icon icon-wallet">
        <Link to="/wallet"><use href="#icon-wallet" style={{color:""}}></use> </Link>
             
        </svg>
        <span data-v-6ab3f23e=""><Link to="/wallet">Wallet</Link></span>
    </div>
    <div data-v-6ab3f23e="" class="tabbar__container-item">
        <svg data-v-6ab3f23e="" class="svg-icon icon-main">
        <Link to="/account"><use href="#icon-main" style={{color:""}}></use> </Link>
        
        </svg>
        <span data-v-6ab3f23e=""><Link to="/account">Account</Link></span>
    </div>
</div>



    
    
    </>
  )
}

export default Footer
