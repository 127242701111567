import React, { createContext, useState, useEffect } from "react";


import Wingo3min from "../jsfile/Wingo3min";

export const MyContext = createContext();

const MyContextProvider = (props) => {
  const [exampleState, setExampleState] = useState("");
  const [usebalance, setusebalance] = useState("0.00");
  const [footershow, setfootershow] = useState("");
  const [showPopup, setshowPopup] = useState(false);
  const [myhistory, setmyhistory] = useState(true);
  const [selects, setselects] = useState(null);
  const [totolbet, settotolbet] = useState(null);
  const [Result, setResult] = useState(null);
  const [big, setbig] = useState(0);
  const [small, setsmall] = useState(0);
  const [red, setred] = useState(0);
  const [green, setgreen] = useState(0);
  const [white, setwhite] = useState(0);
  const [num1, setnum1] = useState(0);
  const [num2, setnum2] = useState(0);
  const [num3, setnum3] = useState(0);
  const [num4, setnum4] = useState(0);
  const [num5, setnum5] = useState(0);
  const [num6, setnum6] = useState(0);
  const [num7, setnum7] = useState(0);
  const [num8, setnum8] = useState(0);
  const [num9, setnum9] = useState(0);
  const [num0, setnum0] = useState(0);
  const [timeid, settimeid] = useState(null);

  const [token, settoken] = useState(null);
  const [userinfo, setuserinfo] = useState(null);

  const [status, setstatus] = useState("");

  const [historyi, sethistoryi] = useState([]);
  const [historyi2, sethistoryi2] = useState([]);
  const [historyi3, sethistoryi3] = useState([]);
  const [historyi4, sethistoryi4] = useState([]);
  const [userbethistory, setuserbethistory] = useState([]);

  const [Seconds, setSeconds] = useState(null);
  const [currentTime, setcurrentTime] = useState(new Date());
  const [shouldSubmit, setshouldSubmit] = useState(false);

  const [Resultcolor, setResultcolor] = useState(null);
  const [ResultNumber, setResultNumber] = useState(null);


  const [minpriodno1, setminpriodno1] = useState(null);
  const [minpriodno3, setminpriodno3] = useState(null);
  const [minpriodno5, setminpriodno5] = useState(null);
  const [minpriodno10, setminpriodno10] = useState(null);




 

  // this is for set 1min or 3min or 5min or 10min

  const [games, setgames] = useState("1min");

  // const url = "http://localhost:5000";
  const url = "https://dhancash.uw.r.appspot.com";


  const {
    timeid3min,
    formattedTime3min,
    history3min,
    histor3minyitem,
    addhistoryitem3min,
    addbetcontrol3min,
    adduserbethis3min,
  } = Wingo3min();

  useEffect(() => {
    const timerID = setInterval(() => setcurrentTime(new Date()), 1000);
    return () => clearInterval(timerID);
  }, []);

 
  useEffect(() => {
    historyitem();

    const { year, month, day, hours, minutes } = getCurrentTimeVars();
    const periodno = `${year}${month}${day}${hours}${minutes}`;
  settimeid(periodno)
    // setInterval(() => {
    //   if(Seconds==1)
    //   historyitem();
     

    // }, 1000);

    
  }, [Seconds]);

  useEffect(() => {
    Getuser();
  }, []);

  useEffect(() => {
    getuserbethis();
  }, []);

  

  useEffect(() => {
    if (!shouldSubmit) return;

    const submitBet = async () => {
      try {
        const requestBody = {
          priodno: timeid,
          big: big,
          small: small,
          red: red,
          green: green,
          violet: white,
          Num1: num1,
          Num2: num2,
          Num3: num3,
          Num4: num4,
          Num5: num5,
          Num6: num6,
          Num7: num7,
          Num8: num8,
          Num9: num9,
          Num0: num0,
        };

        const updateResponse = await fetch(
          `${url}/api/betcontrol/getwingoitems`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBody),
          }
        );

        const updatedUserData = await updateResponse.json();
        resetState();
        // console.log(updatedUserData,"this is cheack for data");
      } catch (error) {
        console.error("Error updating user balance:", error.message);
      } finally {
        setshouldSubmit(false); // Reset the flag
      }
    };

    submitBet();
  }, [
    big,
    small,
    red,
    green,
    white,
    num1,
    num2,
    num3,
    num4,
    num5,
    num6,
    num7,
    num8,
    num9,
    num0,
    shouldSubmit,
    timeid,
    url,
  ]);

  useEffect(() => {
    if (Seconds == 1) {
      upuserbethistory();

      // setHistoryItemAdded(false);
    }
  }, [Seconds]);

  const formattedTime = () => {
    const year = currentTime.getFullYear();
    const month = String(currentTime.getMonth() + 1).padStart(2, "0");
    const day = String(currentTime.getDate()).padStart(2, "0");
    const hours = String(currentTime.getHours()).padStart(2, "0");
    const minutes = String(currentTime.getMinutes()).padStart(2, "0");
    const seconds = String(60 - currentTime.getSeconds()).padStart(2, "0");
    // settimeid(`${year}${month}${day}${hours}${minutes}`);
    setSeconds(seconds);
    return `${seconds}`;
  };

  const getCurrentTimeVars = () => {
    const currentTimes = new Date();
    const year = currentTimes.getFullYear();
    const month = String(currentTimes.getMonth() + 1).padStart(2, "0");
    const day = String(currentTimes.getDate()).padStart(2, "0");
    const hours = String(currentTimes.getHours()).padStart(2, "0");
    const minutes = String(currentTimes.getMinutes()).padStart(2, "0");
    const seconds = String(currentTimes.getSeconds()).padStart(2, "0");
    // const periodno = `${year}${month}${day}${hours}${minutes}`;
    // settimeid(periodno);
  
  
    return { year, month, day, hours, minutes,seconds };

  };

  const Getuser = async () => {
    try {
      const token = localStorage.getItem("token");
      settoken(token);
      if (token) {
        const response = await fetch(`${url}/api/user/Getuser`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "auto-token": token,
          },
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const json = await response.json();

        setusebalance(json.userbalance);
      }
    } catch (error) {
      console.error("Error fetching user data:", error.message);
    }
  };

  const updateuserb = async (totalAmountValue) => {
    try {
      const token = localStorage.getItem("token");
      const totolbet = totalAmountValue;

      if (!token) {
        throw new Error("Token not found");
      }

      const balanceResponse = await fetch(`${url}/api/user/Getuser`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "auto-token": token,
        },
      });

      if (!balanceResponse.ok) {
        throw new Error("Failed to fetch user balance");
      }

      const balanceData = await balanceResponse.json();
      const currentUserBalance = parseFloat(balanceData.userbalance);

      if (isNaN(currentUserBalance)) {
        throw new Error("Invalid user balance value");
      }

      const parsedTotolbet = parseFloat(totolbet);

      if (isNaN(parsedTotolbet)) {
        throw new Error("Invalid totolbet value");
      }

      const updatedBalance = currentUserBalance - parsedTotolbet;

      const requestBody = {
        userbalance: updatedBalance,
      };

      const updateResponse = await fetch(`${url}/api/user/userupdate`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "auto-token": token,
        },
        body: JSON.stringify(requestBody),
      });

      if (!updateResponse.ok) {
        throw new Error("Failed to update user balance");
      }

      const updatedUserData = await updateResponse.json();
      setusebalance(updatedUserData.userbalance);
    } catch (error) {
      console.error("Error updating user balance:", error.message);
    }
  };

  const historyitem = async () => {
    try {
      const response = await fetch(`${url}/api/history/getwingoitems`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const json = await response.json();


      const updatedJson = json.slice(0, -1);

    sethistoryi(updatedJson);
    
    const response2 = await fetch(`${url}/api/history3min/getwingoitems3min`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response2.ok) {
      throw new Error("Network response was not ok");
    }

    const json2 = await response2.json();


    const updatedJson2 = json2.slice(0, -1);

    sethistoryi2(updatedJson2);

    const response3 = await fetch(`${url}/api/history5min/getwingoitems5min`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response3.ok) {
      throw new Error("Network response was not ok");
    }

    const json3 = await response3.json();


    const updatedJson3 = json3.slice(0, -1);

    sethistoryi3(updatedJson3);

    // this get data 10 min

    const response4 = await fetch(`${url}/api/history10min/getwingoitems10min`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response2.ok) {
      throw new Error("Network response was not ok");
    }

    const json4 = await response4.json();


    const updatedJson4 = json4.slice(0, -1);

    sethistoryi4(updatedJson4);




    // get last item


    const responselastitem = await fetch(`${url}/api/history/getwingoitems1mingetone`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!responselastitem.ok) {
      throw new Error("Network response was not ok");
    }

    const jsonlastitem = await responselastitem.json();
    setminpriodno1(jsonlastitem);


    const responselastitem3 = await fetch(`${url}/api/history3min/getwingoitems3mingetone`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!responselastitem3.ok) {
      throw new Error("Network response was not ok");
    }

    const jsonlastitem3 = await responselastitem3.json();
    setminpriodno3(jsonlastitem3);



    const responselastitem5 = await fetch(`${url}/api/history5min/getwingoitems5mingetone`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!responselastitem5.ok) {
      throw new Error("Network response was not ok");
    }

    const jsonlastitem5 = await responselastitem5.json();
    setminpriodno5(jsonlastitem5);


    const responselastitem10 = await fetch(`${url}/api/history10min/getwingoitems10mingetone`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!responselastitem10.ok) {
      throw new Error("Network response was not ok");
    }

    const jsonlastitem10 = await responselastitem10.json();
    setminpriodno10(jsonlastitem10);









    } catch (error) {
      console.error("Error fetching history items:", error.message);
    }
  };



  const addbetcontrol = (totalAmountValue, Selects) => {
    const parsedTotolbet = parseFloat(totalAmountValue);
    console.log(Selects, "this is select value");

    switch (Selects) {
      case "big":
        setbig(parsedTotolbet);
        break;
      case "small":
        setsmall(parsedTotolbet);
        break;
      case "White":
        setwhite(parsedTotolbet);
        break;
      case "Red":
        setred(parsedTotolbet);
        break;
      case "Green":
        setgreen(parsedTotolbet);
        break;
      case "1":
        setnum1(parsedTotolbet);
        break;
      case "2":
        setnum2(parsedTotolbet);
        break;
      case "3":
        setnum3(parsedTotolbet);
        break;
      case "4":
        setnum4(parsedTotolbet);
        break;
      case "5":
        setnum5(parsedTotolbet);
        break;
      case "6":
        setnum6(parsedTotolbet);
        break;
      case "7":
        setnum7(parsedTotolbet);
        break;
      case "8":
        setnum8(parsedTotolbet);
        break;
      case "9":
        setnum9(parsedTotolbet);
        break;
      case "0":
        setnum0(parsedTotolbet);
        break;
      default:
        console.log("Invalid selection");
    }
    setshouldSubmit(true);
    // Reset state after running addbetcontrol
  };

 
  const resetState = () => {
    setbig(0);
    setsmall(0);
    setred(0);
    setgreen(0);
    setwhite(0);
    setnum1(0);
    setnum2(0);
    setnum3(0);
    setnum4(0);
    setnum5(0);
    setnum6(0);
    setnum7(0);
    setnum8(0);
    setnum9(0);
    setnum0(0);
  };

  const getuserbethis = async () => {
    try {
      const token = localStorage.getItem("token");

      if (!token) {
        throw new Error("Token not found");
      }
      const response = await fetch(`${url}/api/userbethistory/getuserbethis`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "auto-token": token,
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const json = await response.json();

      setuserbethistory(json);
    } catch (error) {
      console.error("Error fetching history items:", error.message);
    }
  };

  const adduserbethis = async (totalAmountValue) => {
    try {
      const token = localStorage.getItem("token");

      if (!token) {
        throw new Error("Token not found");
      }

      const requestBody = {
        priodno: timeid,
        pamount: totalAmountValue,
        amountaftertax: (totalAmountValue * 98) / 100,
        resultnumber: ResultNumber,
        resultcolor: Resultcolor,
        resultbigsmall: Result,
        select: selects,
        status: " ",
        winloss: 0,
        ordertime: timeid,
        tax: (totalAmountValue * 2) / 100,
      };

      console.log(ResultNumber, "this is number");

      const updateResponse = await fetch(
        `${url}/api/userbethistory/adduserbethis`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "auto-token": token, // Add the token here
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (!updateResponse.ok) {
        throw new Error(`HTTP error! status: ${updateResponse.status}`);
      }

      const updatedUserData = await updateResponse.json();

      setuserbethistory(userbethistory.concat(updatedUserData));
      console.log(updatedUserData);
    } catch (error) {
      console.error("Error adding history item:", error.message);
    }
  };

  const upuserbethistory = async () => {
    try {
      // Fetch user bets
      const responses = await fetch(`${url}/api/history/getwingoitemsone`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ periodno: timeid }),
      });

      if (!responses.ok) {
        throw new Error("Network response was not ok for getwingoitemsone");
      }

      const userBets = await responses.json();
      console.log("Fetched user bets result:", userBets);

      if (userBets.length === 0) {
        throw new Error("No user bets found for the provided periodno");
      }

      // Extracting betnumbers, bigsmall, and color from userBets
      const { betnumbers, bigsmall, color } = userBets[0];

      // Fetch user history
      const response = await fetch(
        `${url}/api/userbethistory/getuserselectedbet`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ priodno: timeid }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const userHistory = await response.json();
      console.log("Fetched user history:", userHistory);

      if (userHistory.length === 0) {
        throw new Error("No user history found for the provided priodno");
      }

      // Loop through each record in user history to update status
      for (const record of userHistory) {
        let status = "failed";
        let number = 1;
        if (
          record.select === betnumbers.toString() ||
          record.select.toLowerCase() === bigsmall.toLowerCase() ||
          record.select.toLowerCase() === color.toLowerCase()
        ) {
          status = "success";
        }

        // Update each record with new status using the record's _id
        const updateResponse = await fetch(
          `${url}/api/userbethistory/upuserbethistory/${record._id}`,
          {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              resultnumber: betnumbers,
              resultcolor: color,
              resultbigsmall: bigsmall,
              winloss: record.amountaftertax * number,
              status: status,
            }),
          }
        );

        console.log("Update request payload:", {
          resultnumber: betnumbers,
          resultcolor: color,
          resultbigsmall: bigsmall,
          status: status,
        });

        if (!updateResponse.ok) {
          throw new Error(`HTTP error! status: ${updateResponse.status}`);
        }

        const updatedRecord = await updateResponse.json();
        console.log("Updated record:", updatedRecord);

        getuserbethis();
      }
    } catch (error) {
      console.error("Error processing bet history:", error.message);
    }
  };

  return (
    <MyContext.Provider
      value={{
        exampleState,
        setExampleState,
        Seconds,
        setSeconds,
        Result,
        setResult,
        usebalance,
        setusebalance,
        updateuserb,
        footershow,
        setfootershow,
        showPopup,
        setshowPopup,
        myhistory,
        setmyhistory,
        selects,
        setselects,
        totolbet,
        settotolbet,
        big,
        setbig,
        green,
        setgreen,
        white,
        setwhite,
        red,
        setred,
        small,
        setsmall,
        num1,
        setnum1,
        num2,
        setnum2,
        num3,
        setnum3,
        num4,
        setnum4,
        num5,
        setnum5,
        num6,
        setnum6,
        num7,
        setnum7,
        num8,
        setnum8,
        num9,
        setnum9,
        userinfo,
        formattedTime,

        timeid,
        settimeid,
        historyitem,
        sethistoryi,
        sethistoryi2,
        sethistoryi3,
        sethistoryi4,
        historyi,
        historyi2,
        historyi3,
        historyi4,
        
        addbetcontrol,
        resetState,
        adduserbethis,
        userbethistory,
        timeid3min,
        history3min,
        formattedTime3min,
        addhistoryitem3min,
        addbetcontrol3min,
        games,
        setgames,
        adduserbethis3min,
        minpriodno1,
        minpriodno3,
        minpriodno5,
        minpriodno10,
        
      }}
    >
      {props.children}
    </MyContext.Provider>
  );
};

export default MyContextProvider;
