import React, { useContext, useEffect } from "react";
import { MyContext } from "../../Context/MyContext";
import { useNavigate } from "react-router-dom";

function Wingorehis({cart}) {
  const context = useContext(MyContext);

  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/signup");
    }
  }, [navigate]);


  return (
    <>
      <div data-v-c74f4bba="" class="van-row">
        <div data-v-c74f4bba="" class="van-col van-col--8">
          {cart.periodno}
        </div>
        <div data-v-c74f4bba="" class="van-col van-col--5 numcenter">
          <div data-v-c74f4bba="" class={`GameRecord__C-body-num ${cart.color}`}>
          {cart.betnumbers}
          </div>
        </div>
        <div data-v-c74f4bba="" class="van-col van-col--5">
          <span data-v-c74f4bba="">{cart.bigsmall}</span>
        </div>
        <div data-v-c74f4bba="" class="van-col van-col--6">
          <div data-v-c74f4bba="" class="GameRecord__C-origin">
          <div data-v-c74f4bba=""  style={{display:'none'}} class={`GameRecord__C-origin-I ${cart.color==="mixedColor5" ? 'green' : cart.color==="mixedColor0" ? 'red':'' }`}></div>
            <div data-v-c74f4bba=""   class={`GameRecord__C-origin-I ${cart.color==="greenColor" ? 'green' : cart.color==="mixedColor5" ? 'violet' :cart.color==="mixedColor0" ? 'violet' :'red' }`}></div>
            
          </div>
        </div>
      </div>
    </>
  );
}

export default Wingorehis;

