import React, { useContext, useEffect, useState } from 'react'
import { MyContext } from '../Context/MyContext';
import { useNavigate } from 'react-router-dom';
import Wino5 from './Wino5minpart/Wino5';
import Lottrynav from '../Components/Lottrynav';
import Lottrybalance from '../Components/Lottrybalance';

function Wino5min() {

    const context = useContext(MyContext);

    const { setfootershow, formattedTime, timeid, historyi, userbethistory, myhistory,setmyhistory,games,setgames, } =
      context;
  
      const navigate = useNavigate();
  
      useEffect(() => {
        const token = localStorage.getItem("token");
        if (!token) {
          navigate("/signup");
        }
      }, [navigate]);
  
  
    useEffect(() => {
      setfootershow("none");
    }, []);
  
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
  
    // Reverse the array to show the last items first
    const reversedHistory = [...historyi].reverse();
  
    // console.log(reversedHistory, "mukeem very");
  
    // Calculate the indices for the items to be displayed on the current page
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = reversedHistory.slice(indexOfFirstItem, indexOfLastItem);
  
    const handleNext = () => {
      if (indexOfLastItem < reversedHistory.length) {
        setCurrentPage((prevPage) => prevPage + 1);
      }
    };
  
    const handleBack = () => {
      if (currentPage > 1) {
        setCurrentPage((prevPage) => prevPage - 1);
      }
    };
  
    const [currentPageu, setCurrentPageu] = useState(1);
    const itemsPerPageu = 10;
  
    // Reverse the array to show the last items first
    const reversedHistoryu = [...userbethistory].reverse();
  
    // console.log(reversedHistory, "mukeem very");
  
    // Calculate the indices for the items to be displayed on the current page
    const indexOfLastItemu = currentPageu * itemsPerPageu;
    const indexOfFirstItemu = indexOfLastItemu - itemsPerPageu;
    const currentItemsu = reversedHistoryu.slice(indexOfFirstItemu, indexOfLastItemu);
  
    const handleNextu = () => {
      if (indexOfLastItemu < reversedHistoryu.length) {
        setCurrentPageu((prevPage) => prevPage + 1);
      }
    };
  
    const handleBacku = () => {
      if (currentPageu > 1) {
        setCurrentPageu((prevPage) => prevPage - 1);
      }
    };
  
  
    const myhistory1 = () => {
      setmyhistory(false);
    };
  
    const myhistory2 = () => {
      setmyhistory(true);
    };
  
  
    const [selectedGame, setSelectedGame] = useState('1min');
  
    const handleGameClick = (game) => {
      setSelectedGame(game);
      setgames(game);
      console.log(games,"this is selected")
      
    };
  return (
    <>
    <div id="app" data-v-app="">
      <div
        data-v-f9e6ba25=""
        class="WinGo__C"
        style={{ fontFamily: "'Roboto', 'Inter', sans-serif" }}
      >
        <Lottrynav />
        <Lottrybalance />

        <div data-v-f9e6ba25="" class="noticeBar__container">
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="notice_svg"
          >
            <g clip-path="url(#clip0_589_37747)">
              <path
                d="M15.9993 4V28C11.3327 28 7.86502 21.8927 7.86502 21.8927H3.99935C3.26297 21.8927 2.66602 21.2958 2.66602 20.5594V11.3405C2.66602 10.6041 3.26297 10.0072 3.99935 10.0072H7.86502C7.86502 10.0072 11.3327 4 15.9993 4Z"
                fill="url(#paint0_linear_589_37747)"
              ></path>
              <path
                d="M21.334 10C21.7495 10.371 22.1261 10.7865 22.4567 11.2392C23.4265 12.5669 24.0007 14.2149 24.0007 16C24.0007 17.7697 23.4363 19.4045 22.4819 20.7262C22.1452 21.1923 21.7601 21.6195 21.334 22"
                stroke="url(#paint1_linear_589_37747)"
                stroke-width="1.8"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M22.8242 27.4571C26.7227 25.1302 29.3336 20.87 29.3336 16C29.3336 11.2057 26.8031 7.00234 23.005 4.65271"
                stroke="url(#paint2_linear_589_37747)"
                stroke-width="1.8"
                stroke-linecap="round"
              ></path>
            </g>
            <defs>
              <linearGradient
                id="paint0_linear_589_37747"
                x1="9.33268"
                y1="4"
                x2="9.33268"
                y2="28"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FF7C7C"></stop>
                <stop offset="0.74876" stop-color="#F54545"></stop>
              </linearGradient>
              <linearGradient
                id="paint1_linear_589_37747"
                x1="22.6673"
                y1="10"
                x2="22.6673"
                y2="22"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FF7C7C"></stop>
                <stop offset="0.74876" stop-color="#F54545"></stop>
              </linearGradient>
              <linearGradient
                id="paint2_linear_589_37747"
                x1="26.0789"
                y1="4.65271"
                x2="26.0789"
                y2="27.4571"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FF7C7C"></stop>
                <stop offset="0.74876" stop-color="#F54545"></stop>
              </linearGradient>
              <clipPath id="clip0_589_37747">
                <rect width="32" height="32" fill="white"></rect>
              </clipPath>
            </defs>
          </svg>
          <div class="noticeBar__container-body">
            <div class="noticeBar__container-body-text">
              All Recharge payment methods on the DamanGames site are only
              available in the Recharge menu on the official website. Make
              sure to make a Recharge only through our official website and
              don't trust any party on behalf of DamanGames. If you find any
              discrepancies or suspicious behavior, please contact our
              customer service immediately for confirmation. We urge all
              members not to believe or be tempted by other promotions outside
              our site, Thank You
            </div>
          </div>
          <button>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_589_37755)">
                <path
                  d="M24 0H0V24H24V0Z"
                  fill="white"
                  fill-opacity="0.01"
                ></path>
                <path
                  d="M12 22C16.1173 22 19.4999 18.7371 19.4999 14.5491C19.4999 13.5209 19.4476 12.4187 18.8778 10.7058C18.3079 8.9929 18.1931 8.7718 17.5905 7.71395C17.333 9.8727 15.9555 10.7724 15.6055 11.0413C15.6055 10.7615 14.7722 7.66795 13.5088 5.81695C12.2685 4 10.5817 2.80796 9.59265 2C9.59265 3.53489 9.16095 5.81695 8.5427 6.9797C7.92445 8.14245 7.80835 8.1848 7.0361 9.0501C6.2639 9.9154 5.90945 10.1826 5.2637 11.2325C4.61798 12.2825 4.5 13.6809 4.5 14.7091C4.5 18.8971 7.88265 22 12 22Z"
                  fill="white"
                ></path>
              </g>
              <defs>
                <clipPath id="clip0_589_37755">
                  <rect width="24" height="24" fill="white"></rect>
                </clipPath>
              </defs>
            </svg>{" "}
            Detail
          </button>
        </div>
        <div data-v-484ec2ec="" data-v-f9e6ba25="" className="GameList__C">
      <div
        data-v-484ec2ec=""
        className={`GameList__C-item ${selectedGame === '1min' ? 'active' : ''}`}
        onClick={() => handleGameClick('1min')}
      >
        <div data-v-484ec2ec="">
          Win Go
          <br />
          1Min
        </div>
      </div>
      <div
        data-v-484ec2ec=""
        className={`GameList__C-item ${selectedGame === '3min' ? 'active' : ''}`}
        onClick={() => handleGameClick('3min')}
      >
        <div data-v-484ec2ec="">
          Win Go
          <br />
          3Min
        </div>
      </div>
      <div
        data-v-484ec2ec=""
        className={`GameList__C-item ${selectedGame === '5min' ? 'active' : ''}`}
        onClick={() => handleGameClick('5min')}
      >
        <div data-v-484ec2ec="">
          Win Go
          <br />
          5Min
        </div>
      </div>
      <div
        data-v-484ec2ec=""
        className={`GameList__C-item ${selectedGame === '10min' ? 'active' : ''}`}
        onClick={() => handleGameClick('10min')}
      >
        <div data-v-484ec2ec="">
          Win Go
          <br />
          10Min
        </div>
      </div>
    </div>

<Wino5/>
    {/* {selectedGame === '1min' && <Wingo1min />}
    {selectedGame === '3min' && <Wingo3min />}
    {selectedGame === '5min' && <Wingo5min />}
    {selectedGame === '10min' && <Wingo10min />} */}
      </div>
    </div>
  </>
  )
}

export default Wino5min
