import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

function Promotion() {

    const navigate = useNavigate();

    useEffect(() => {
      const token = localStorage.getItem("token");
      if (!token) {
        navigate("/signup");
      }
    }, [navigate]);

  return (
   <>

<div data-v-2d3dc984="" data-v-2fa41a6b="" class="navbar" style={{ "--76181987": "'Roboto', 'Inter', sans-serif" }}
>
            <div data-v-2d3dc984="" class="navbar-fixed" style={{ background: "rgb(247, 248, 255)" }}
            >
                <div data-v-2d3dc984="" class="navbar__content">
                    <div data-v-2d3dc984="" class="navbar__content-left"></div>
                    <div data-v-2d3dc984="" class="navbar__content-center">
                        <div data-v-2d3dc984="" class="navbar__content-title">Agency</div>
                    </div>
                    <div data-v-2d3dc984="" class="navbar__content-right"><img data-v-2fa41a6b=""
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAMAAABg3Am1AAAAWlBMVEX/WmYAAAD/WWf/Wmf/WmX/WGj/WGj/WWf/WGX/WWf/WGf/Wmf/W2b/V2X/W2n/WWj/WWX/WGf/WGf/WWP/XmX/U2z/WGj/XGb/W2j/WWf/Wmb/V2f/Wmb/WWdvLvmAAAAAHXRSTlNmALKfM0Ag32BZUS5GJl85H79wTSYNgBlM7++QgAa8nloAAAEfSURBVEjHzdNZcoMwEEXRfhEIJATYBIIzsP9tJnawGiQKNUm5yvf7HWYIB/sb0OcprKj2AO+592EHTFudgJZuWVcLwSvdM7UQcNlRQG3ipgsADS3TDPINUAIjrSoZoIv2X9fj0LqKAd6CfQegpyDHIBAfFsDYhMB4wII/i5riVgDK7zWAjJIAxQzmfQLwo1LR84lBXy/eRhcdPwZEGYNSBCg7CuiZgQVaGajm/2D+cdSLT22B+VWfNTDc9tMitQFGQH8qi58uImBwLyeSXBLZAdd0Q3ER+C3XuroQiQD3eMA3zKkUUFOQ+i8wskuyHjgS5TyoZKD2AI1kb8DgJDsBA7TpfYYlQJbat1gD9GZv3mgw8MTZ7bVx1QAGsp4ZfAM0fzA9owuXkgAAAABJRU5ErkJggg=="
                            alt=""/></div>
                </div>
            </div>
        </div>
        <div data-v-d18c566e="" data-v-2fa41a6b="" class="container" style={{ "--76181987": "'Roboto', 'Inter', sans-serif" }}
        >
            <div data-v-d18c566e="" class="amount">0</div>
            <div data-v-d18c566e="" class="amount_txt">Yesterday's total commission</div>
            <div data-v-d18c566e="" class="tip">Upgrade the level to increase commission income</div>
            <div data-v-d18c566e="" class="info_content">
                <div data-v-d18c566e="" class="info">
                    <div data-v-d18c566e="" class="head">Direct subordinates</div>
                    <div data-v-d18c566e="" class="line1">
                        <div data-v-d18c566e="">0</div> number of register
                    </div>
                    <div data-v-d18c566e="" class="line2">
                        <div data-v-d18c566e="">1</div> Deposit number
                    </div>
                    <div data-v-d18c566e="" class="line3">
                        <div data-v-d18c566e="">130</div> Deposit amount
                    </div>
                    <div data-v-d18c566e="" class="line1">
                        <div data-v-d18c566e="">0</div> Number of people making first deposit
                    </div>
                </div>
                <div data-v-d18c566e="" class="info">
                    <div data-v-d18c566e="" class="head u2">Team subordinates</div>
                    <div data-v-d18c566e="" class="line1">
                        <div data-v-d18c566e="">1</div> number of register
                    </div>
                    <div data-v-d18c566e="" class="line2">
                        <div data-v-d18c566e="">6</div> Deposit number
                    </div>
                    <div data-v-d18c566e="" class="line3">
                        <div data-v-d18c566e="">1780</div> Deposit amount
                    </div>
                    <div data-v-d18c566e="" class="line1">
                        <div data-v-d18c566e="">1</div> Number of people making first deposit
                    </div>
                </div>
            </div>
        </div>
        <div data-v-2fa41a6b="" class="content" style={{ "--76181987": "'Roboto', 'Inter', sans-serif" }}>
            <div data-v-2fa41a6b="" class="shareBtnContainer"><button data-v-2fa41a6b="" class="shareBtn">INVITATION
                    LINK</button></div>
            <div data-v-2fa41a6b="" class="promote__cell">
                <div data-v-2fa41a6b="" class="promote__cell-item">
                    <div data-v-2fa41a6b="" class="label"><img data-v-2fa41a6b=""
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAMAAAANIilAAAAAZlBMVEUAAAD6Wlr6WVn5WFj5WVn7WVn/Vlb6Wlr7W1v3Wlr5WVn6WFj8W1v5WVn5WVn4WVn6Wlr6Wlr4Wlr3WFj/VVX4WFj6WVn6Wlr6WVn5WVn5WVn5WVn6WVn4WVn5WFj6W1v/UVH5WVkYbn5UAAAAIXRSTlMAZrKf7XkNn3lA4L5MYM+BWTMmIAaWU/XPxqmOcHA5qRZw9CxiAAABJklEQVRIx+3T3XKCMBCG4SzkpyTyIyit2tru/d9kT6xdCJF8dXrgjO8xz+xOSNSze9r157GjRN2m6m2S2qqmtaoE31JW26WNN5TZZhdhYVd1emd8c0tQdoIrDFfoYFktz6wnsB7dWna+/Z+M5kvaUNQocEdRga8dKKoTmOKOv9hR3G1MrrjkCMWyx8FGs0gbCAeeVEDYTbGDMLlCdHqU00bwe3ltbyjR5COBPYsOi9w0LPIC8yRtlp+3LInZx1hzLmYXX5p8/PoxHwxgLqLBAJ6N1gCejz4yhvmzffmpYBTL/hF71GqBGxQ3ApcoLgSuUVwLbDWIB/X3vUslG+DBshaxrZoV8u2bmmdDtrWCgZuf1GJDuU5DpVJ9jfvgU843ZW3Vszv6BlTHjeSB3A++AAAAAElFTkSuQmCC"
                            alt=""/><span data-v-2fa41a6b="">Copy invitation code</span></div>
                    <div data-v-2fa41a6b="" class="arrow"><span data-v-2fa41a6b="">241173391595</span><img
                            data-v-2fa41a6b=""
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwAgMAAAAqbBEUAAAADFBMVEUAAABmZmZkZGRmZmZ0zVwQAAAAA3RSTlMA3yDHuD3GAAAANUlEQVQoz2MYhIC1AYnDnoDE4byBxGEqQ9Y0F1mTOk01YXAQyjANQBiNsJRGWtgTUANxMAIAk88RuZxQh1MAAAAASUVORK5CYII="
                            alt=""/></div>
                </div>
                <div data-v-2fa41a6b="" class="promote__cell-item">
                    <div data-v-2fa41a6b="" class="label"><img data-v-2fa41a6b=""
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAMAAAANIilAAAAAbFBMVEX/WmYAAAD/WWj/T2T/W2X/W2f/WWb/WWf/WGf/WGb/WGb/WWb/WWb/WWf/WGX/WGf/WWb/XGX/VGr/WWf/WGf/WWf/WWb/Wmr/WWf/WWf/WWf/WWf/Wmf/XGb/WWj/Wmf/VYD/WWf/WmX/WWcSNvN4AAAAI3RSTlNmAIwMeXlwYLyW44Ev2U5A7yYQ7LCfVx+/OPXfsxnGqAbPR17xxycAAAHhSURBVEjHzdfbkuIgFAVQJkTQkPvNRG27p/f//+PEdAEnhKpAz4v7jVBL2Z6irLA//5F3xHk/tJsHaiqD8R1QdC2Bug3EFYCBPpgAhGIBQNIHNVCHHrsHkNOfAEAfit0vKgGIY8xfmQE8OMkFQMlfIdiVpWSvfAL4ZCQZAMHWVNyPm4Wu+QsgoRhAZhaND3NtWQfcqE0APM1Kcg+u9K4CUBC71hjscvbgUW8OAB7ErjWUXco9/jCbZ7dy5tTY45nijjmY1hDXHR7N5ulZXDZYFUVKlpcdbllwzpWL83CcSRePwVbAxZwF5wLl4CaiMhIHlxGVMWzxF4uojH6Lm5jKmLb4O6Yy7lsszaFOnohtZYrppVA3+PKglZdYTK9yAX9SWhloKR6PsKCVgavB9Cqr7ujYGZbkBM/MJvVEOJVRETxGTXmJtJiz8JxdnEfgbMXK4jJE0cpIDOZ2SyWepLvKGAw2l+Lkn1OXupXRG/xtP9afs1sZk8b2KicH2FTGXeMrPZQvRepWRq1xFT3lJRrL6CkvaX/wR4QV0LkuOOrUdBz5Dx5/UxnlilsWkcJgueL8V5UhonFisVpxE1uZHvtLhuMO7qjy6EHR+zyHNr4ZS/7omvJYiuSpZT3Jt327eWv8D31pUMh1MCbLAAAAAElFTkSuQmCC"
                            alt=""/><span data-v-2fa41a6b="">Subordinate data</span></div>
                    <div data-v-2fa41a6b="" class="arrow"><img data-v-2fa41a6b=""
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwAgMAAAAqbBEUAAAADFBMVEUAAABmZmZkZGRmZmZ0zVwQAAAAA3RSTlMA3yDHuD3GAAAANUlEQVQoz2MYhIC1AYnDnoDE4byBxGEqQ9Y0F1mTOk01YXAQyjANQBiNsJRGWtgTUANxMAIAk88RuZxQh1MAAAAASUVORK5CYII="
                            alt=""/></div>
                </div>
                <div data-v-2fa41a6b="" class="promote__cell-item">
                    <div data-v-2fa41a6b="" class="label"><img data-v-2fa41a6b=""
                            src="https://damangames.inhttps://damangames.in/assets/png/commission-1a0e795f.png" alt=""/><span
                            data-v-2fa41a6b="">Commission detail</span></div>
                    <div data-v-2fa41a6b="" class="arrow"><img data-v-2fa41a6b=""
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwAgMAAAAqbBEUAAAADFBMVEUAAABmZmZkZGRmZmZ0zVwQAAAAA3RSTlMA3yDHuD3GAAAANUlEQVQoz2MYhIC1AYnDnoDE4byBxGEqQ9Y0F1mTOk01YXAQyjANQBiNsJRGWtgTUANxMAIAk88RuZxQh1MAAAAASUVORK5CYII="
                            alt=""/></div>
                </div>
                <div data-v-2fa41a6b="" class="promote__cell-item">
                    <div data-v-2fa41a6b="" class="label"><img data-v-2fa41a6b=""
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAMAAAANIilAAAAAVFBMVEX6WloAAAD5WVn6Wlr6WVn7Wlr4Wlr4UFD6WVn7W1v5XFz/W1v/VVX7Wlr6WVn6WVn7W1v6WVn4WVn6Wlr5WVn5WVn5WVn6WVn6W1v5WVn6WVn5WVln1XYhAAAAG3RSTlNmAOwz2U8mDLJfLBsGebxZRsVw9ePGqZtwz4weICORAAABBElEQVRIx+3XyY4CIRAG4Gr2rTd11Jl+//ecGGNASoFqL230P1GHLxBSCQV0L+SLO+2m0QKIJYsAsHJyuoC9hEse4mukfoY5QA0DcISjLeCoMVbQhsFhrMdWbAPCCloxcIRlO5Y5DtCOIWTYU7DPsKJglWFOwXzjeL9fjw/n/rAWizPATqzD83U9r8HDrTB0bGJlaPjIjmlp2ImAcb5487gXLOZnR8PzksbQ8O8dHl44tujf5bY/BxuGUuowldrTkgZ3mCo9sX+ZyTvMFx/3gSXBHRbwWFEPHivo160QDrbVjhrhzhE2jph4cF4ZXOsW407LGpW+PKxL+9jZcXJ6o3+Md8H/Fdgw/IbWcWIAAAAASUVORK5CYII="
                            alt=""/><span data-v-2fa41a6b="">Invitation rules</span></div>
                    <div data-v-2fa41a6b="" class="arrow"><img data-v-2fa41a6b=""
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwAgMAAAAqbBEUAAAADFBMVEUAAABmZmZkZGRmZmZ0zVwQAAAAA3RSTlMA3yDHuD3GAAAANUlEQVQoz2MYhIC1AYnDnoDE4byBxGEqQ9Y0F1mTOk01YXAQyjANQBiNsJRGWtgTUANxMAIAk88RuZxQh1MAAAAASUVORK5CYII="
                            alt=""/></div>
                </div>
                <div data-v-2fa41a6b="" class="promote__cell-item">
                    <div data-v-2fa41a6b="" class="label"><img data-v-2fa41a6b=""
                            src="https://damangames.inhttps://damangames.in/assets/png/server-b7c71127.png" alt=""/><span
                            data-v-2fa41a6b="">Agent line customer service</span></div>
                    <div data-v-2fa41a6b="" class="arrow"><img data-v-2fa41a6b=""
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwAgMAAAAqbBEUAAAADFBMVEUAAABmZmZkZGRmZmZ0zVwQAAAAA3RSTlMA3yDHuD3GAAAANUlEQVQoz2MYhIC1AYnDnoDE4byBxGEqQ9Y0F1mTOk01YXAQyjANQBiNsJRGWtgTUANxMAIAk88RuZxQh1MAAAAASUVORK5CYII="
                            alt=""/></div>
                </div>
                <div data-v-2fa41a6b="" class="promote__cell-item">
                    <div data-v-2fa41a6b="" class="label"><img data-v-2fa41a6b=""
                            src="https://damangames.inhttps://damangames.in/assets/png/rebateRatio-7a0e4b1b.png" alt=""/><span
                            data-v-2fa41a6b="">Rebate ratio</span></div>
                    <div data-v-2fa41a6b="" class="arrow"><img data-v-2fa41a6b=""
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwAgMAAAAqbBEUAAAADFBMVEUAAABmZmZkZGRmZmZ0zVwQAAAAA3RSTlMA3yDHuD3GAAAANUlEQVQoz2MYhIC1AYnDnoDE4byBxGEqQ9Y0F1mTOk01YXAQyjANQBiNsJRGWtgTUANxMAIAk88RuZxQh1MAAAAASUVORK5CYII="
                            alt=""/></div>
                </div>
            </div>
            <div data-v-2fa41a6b="" class="commission">
                <div data-v-2fa41a6b="" class="commission__title"><i data-v-2fa41a6b=""
                        class="van-badge__wrapper van-icon"><img class="van-icon__image"
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAMAAABg3Am1AAABHVBMVEUAAAD8jKX9q779nbL8iqH/+Pv/j5/+wM7/j5f+kaf/jaH7jaX/jp3/v8P+6O3/6e7/3uX/jJj+usX+jqb8jaX9hZv/vcT/i6j9i5z8iqT//P3/9ff/f4z/bn3/jZr+mKj+iZn7iqj/v8//ZHH/vsP/9ff/d4P/fYf+8fT/v8X/sLX+c4H/sLf+dYT/wsb/wMX/mqD/lJ3/j5n/dYf/eYX/oan9hp//w8j/w8b/pq7/e4P/t7//t7//////ZHH/c3//ipT/vcP/pq7/anb/Y3D/bHn/f4r/Xmz/aHX/eoX/YG7/XWr/Wmj/fYj/ZXL/e4b/n6j/trz/sbf/qbD/kZr/jJb/eIP/cX3/rLP/hY//Z3T/oqr/l6D/lZ7/k5ydcTwhAAAAPXRSTlMAZnlwQOyfjJ8yXyYK38bFsp+BWFB5YCwfF/Xiw72yhIFGEPXv4+Pf2c/Pxr+zr5+fn5+fgH9wb29fX0AgTxUa5QAAAbFJREFUSMftlNd6gjAYhkG0bq1719G99y5iCxEEGe7Z3v9llCdiBUILPfDM9yzJ9z7hJ8mPbVgnoUw1EcARAslqMGSVz8CwNYkMmvfjf+I354O4DTmTkLQTkqaCkYDNFll7oRzIOi4ZstM4fFkJbtMq4fUSpqmt5mh0ZxRc2y4tHiFVotoork5DAdCU2yDsk3kcih4S4lls4iGPNEEaFQ2CmoGJCKkRhbpqakIdNLMWAkH+QBgEmqHlgIXgXQleRKj8U2j57D4JFZwXLdIMZxQKixWcWP5WFxzlyQIqaAcXxyGuxcER2kg7OJnjuVbM+dWIDSUoOL98FKMTgvZCEfCsWoPzB3QwFVhO9jl+ohXAt1lavlkKOTvhtC6ognjt9M2d19l2mweTK12jSfwefygxal6QqMa9vnMEE+UUGk6lbku7rKDmeSB+PmN63npfTQpMpboehuFZVhAElgHDwczUAc+UgUgBToKKwZKm9EhuKCeYkdfurD8Rh5QFQ3HSV7ppzES8M1bm/UEDYdCfK71OHENIhzvdce8DoTfudvaeMAtq6Yvj8DtCOHz5WMM2rJFvSI53OT9GTq0AAAAASUVORK5CYII="/></i><span
                        data-v-2fa41a6b="">promotion data</span></div>
                <div data-v-2fa41a6b="" class="commission__body">
                    <div data-v-2fa41a6b=""><span data-v-2fa41a6b="">218.47</span><span data-v-2fa41a6b="">This
                            Week</span></div><span data-v-2fa41a6b=""></span>
                    <div data-v-2fa41a6b=""><span data-v-2fa41a6b="">19093.09</span><span data-v-2fa41a6b="">Total
                            commission</span></div>
                </div>
                <div data-v-2fa41a6b="" class="commission__body">
                    <div data-v-2fa41a6b=""><span data-v-2fa41a6b="">19</span><span data-v-2fa41a6b="">direct
                            subordinate</span></div><span data-v-2fa41a6b=""></span>
                    <div data-v-2fa41a6b=""><span data-v-2fa41a6b="">35</span><span data-v-2fa41a6b="">Total number of
                            subordinates in the team</span></div>
                </div>
            </div>
        </div>
   
   </>
  )
}

export default Promotion
