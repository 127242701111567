import React from 'react'

function Paygate2() {
  return (
    <>
     <div data-v-845bb16b="" class="rechargeDetail__container"
            style={{ fontFamily: "'Roboto', 'Inter', sans-serif" }}>
            <div data-v-12a80a3e="" data-v-845bb16b="" class="navbar main">
                <div data-v-12a80a3e="" class="navbar-fixed">
                    <div data-v-12a80a3e="" class="navbar__content">
                        <div data-v-12a80a3e="" class="navbar__content-left"><i data-v-12a80a3e=""
                                class="van-badge__wrapper van-icon van-icon-arrow-left"></i></div>
                        <div data-v-12a80a3e="" class="navbar__content-center">Deposit</div>
                        <div data-v-12a80a3e="" class="navbar__content-right">Deposit history</div>
                    </div>
                </div>
            </div>
            <div data-v-845bb16b="" class="rechargeDetail__container-header">
                <p data-v-845bb16b="">Please complete the payment before the time ends</p>
                <div data-v-845bb16b="" class="rechargeDetail__container-header__countdown"><span
                        data-v-845bb16b="">1</span><span data-v-845bb16b="">8</span><span
                        data-v-845bb16b="">:</span><span data-v-845bb16b="">1</span><span data-v-845bb16b="">5</span>
                </div>
            </div>
            <div data-v-845bb16b="" class="rechargeDetail__container-main">
                <div data-v-845bb16b="" class="rechargeDetail__container-body">
                    <div data-v-845bb16b="" class="rechargeDetail__container-body__title">
                        <h1 data-v-845bb16b=""><svg data-v-845bb16b="" class="svg-icon icon-bankTitle">
                                <use xlinkhref="#icon-bankTitle"></use>
                            </svg> Jana small finance bank</h1>
                    </div>
                    <div data-v-845bb16b="">
                        <div data-v-845bb16b="" class="rechargeDetail__container-body__content">
                            <h1 data-v-845bb16b="">Full name</h1>
                            <div data-v-845bb16b=""><span data-v-845bb16b="">JANA JANTA BHOJNALAY</span><svg
                                    data-v-845bb16b="" class="svg-icon icon-copy">
                                    <use xlinkhref="#icon-copy"></use>
                                </svg></div>
                        </div>
                    </div>
                    <div data-v-845bb16b="">
                        <div data-v-845bb16b="" class="rechargeDetail__container-body__content">
                            <h1 data-v-845bb16b="">Bank Account Number</h1>
                            <div data-v-845bb16b=""><span data-v-845bb16b="">4755020001132942</span><svg
                                    data-v-845bb16b="" class="svg-icon icon-copy">
                                    <use xlinkhref="#icon-copy"></use>
                                </svg></div>
                        </div>
                    </div>
                    <div data-v-845bb16b="">
                        <div data-v-845bb16b="" class="rechargeDetail__container-body__content">
                            <h1 data-v-845bb16b="">IFSC</h1>
                            <div data-v-845bb16b=""><span data-v-845bb16b="">JSFB0004755</span><svg data-v-845bb16b=""
                                    class="svg-icon icon-copy">
                                    <use xlinkhref="#icon-copy"></use>
                                </svg></div>
                        </div>
                    </div>
                    <div data-v-845bb16b="">
                        <div data-v-845bb16b="" class="rechargeDetail__container-body__content">
                            <h1 data-v-845bb16b="">Balance</h1>
                            <div data-v-845bb16b=""><span data-v-845bb16b="">500.00</span><svg data-v-845bb16b=""
                                    class="svg-icon icon-copy">
                                    <use xlinkhref="#icon-copy"></use>
                                </svg></div>
                        </div>
                    </div>
                </div>
                <div data-v-845bb16b="" class="utr_box">
                    <h1 data-v-845bb16b="">UTR(UPI Ref.ID)</h1>
                    <div data-v-845bb16b="" class="van-cell van-field utr_input">
                        <div class="van-cell__value van-field__value">
                            <div class="van-field__body"><input type="tel" inputmode="numeric" id="van-field-3-input"
                                    class="van-field__control" placeholder="Please enter 12 -bit UTR"
                                    fdprocessedid="gxy20h"/>
                                <div class="van-field__button">
                                    <div data-v-845bb16b="" class="paste">Paste</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div data-v-7cba6004="" data-v-845bb16b="" class="Recharge__container-intro">
                    <div data-v-7cba6004="" class="Recharge__container-intro__title">
                        <div data-v-7cba6004="" class="img"><svg data-v-7cba6004="" class="svg-icon icon-shuoming">
                                <use xlinkhref="#icon-shuoming"></use>
                            </svg></div>
                        <p data-v-7cba6004="">Recharge instructions</p>
                    </div>
                    <div data-v-7cba6004="" class="Recharge__container-intro__lists">
                       
                        <div data-v-7cba6004="" class="item">
                            <p data-v-7cba6004="">If the transfer time is up, please fill out the deposit form again.
                            </p>
                            <p data-v-7cba6004="">The transfer amount must match the order you created, otherwise the
                                money cannot be credited successfully.</p>
                            <p data-v-7cba6004="">If you transfer the wrong amount, our company will not be responsible
                                for the lost amount!</p>
                            <p data-v-7cba6004="">Note: do not cancel the deposit order after the money has been
                                transferred.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div data-v-845bb16b="" class="rechargeDetail__container-buttons">
                <div data-v-845bb16b=""><span data-v-845bb16b="">Cancel</span><span data-v-845bb16b="">Complete
                        payment</span></div>
            </div>
            <div data-v-3e71d3da="" data-v-845bb16b="" class="dialog inactive">
                <div data-v-3e71d3da="" class="dialog__container" role="dialog" tabindex="0">
                    <div data-v-3e71d3da="" class="dialog__container-img"><img data-v-3e71d3da="" class="" alt=""
                            data-origin="/assets/png/tip-0498e3f9.png" src="/assets/png/tip-0498e3f9.png"/></div>
                    <div data-v-3e71d3da="" class="dialog__container-title">
                        <h1 data-v-3e71d3da="">Cancel transaction</h1>
                    </div>
                    <div data-v-3e71d3da="" class="dialog__container-content">
                        <div data-v-845bb16b="" class="cancen_model_cnt">If you have already transferred the money to
                            the recipient, Please do not press cancel the transaction!</div>
                    </div>
                    <div data-v-3e71d3da="" class="dialog__container-footer"><button data-v-3e71d3da="">Cancellation
                            confirmation</button><button data-v-3e71d3da="">Cancel</button></div>
                </div>
                <div data-v-3e71d3da="" class="dialog__outside"></div>
            </div>
        </div>



    </>
  )
}

export default Paygate2
