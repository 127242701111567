import "./App.css";
import MyContext from "./Context/MyContext";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Mainpage from "./Mainpage/Mainpage";
import Wingo from "./Wingo/Wingo";
import Activity from "./Activity/Activity";
import Promotion from "./Promotion/Promotion";
import Wallet from "./Wallet/Wallet";
import Account from "./Account/Account";
import Deposit from "./Account/Deposit/Deposit";
import Depositbarcode from "./Account/Deposit/Depositbarcode";
import Klottry from "./K3lottry/Klottry";
import D5lottry from "./5dlottry/D5lottry";
import Trxlottry from "./Trx/Trxlottry";
import Footer from "./Footer/Footer";
import Withdraw from "./Withdraw/Withdraw";
import Signup from "./Signuplogin/Signup";
import Login from "./Signuplogin/Login";
import Deposithistory from "./Gamehistory/Deposithistory";
import Withdrawhistory from "./Gamehistory/Withdrawhistory";
import Gamehistory from "./Gamehistory/Gamehistory";
import Transactionhistory from "./Gamehistory/Transactionhistory";
import Paygate1 from "./Account/Deposit/Paygate1";
import Paygate2 from "./Account/Deposit/Paygate2";
import Wino3min from "./K3lottry/Wino3min";
import Wino5min from "./5dlottry/Wino5min";
import Wino10min from "./Trx/Wino10min";

function App() {
  return (
    <>
      <MyContext>
        
          <Router>
            <Routes>

              <Route exact path="/" element={<Mainpage/>} />
              <Route exact path="/wingo" element={<Wingo/>} />
              {/* <Route exact path="/k3lottry" element={<Klottry/>} /> */}
              <Route exact path="/k3lottry" element={<Wino3min/>} />
              {/* <Route exact path="/5dlottry" element={<D5lottry/>} /> */}
              <Route exact path="/5dlottry" element={<Wino5min/>} />
              {/* <Route exact path="/trxlottry" element={<Trxlottry/>} /> */}
              <Route exact path="/trxlottry" element={<Wino10min/>} />
              <Route exact path="/activity" element={<Activity/>} />
              <Route exact path="/promotion" element={<Promotion/>} />
              <Route exact path="/wallet" element={<Wallet/>} />
              <Route exact path="/account" element={<Account/>} />
              <Route exact path="/deposit" element={<Deposit/>} />
              <Route exact path="/withdraw" element={<Withdraw/>} />
              <Route exact path="/login" element={<Login/>} />
              <Route exact path="/signup" element={<Signup/>} />
              <Route exact path="/deposithistory" element={<Deposithistory/>} />
              <Route exact path="/withdrawhistory" element={<Withdrawhistory/>} />
              <Route exact path="/gamehistory" element={<Gamehistory/>} />

              <Route exact path="/transachistory" element={<Transactionhistory/>} />



              <Route exact path="/deposit/paygate1" element={<Paygate1/>} />
              <Route exact path="/deposit/paygate2" element={<Paygate2/>} />


              
            </Routes>
            <Footer/>

          </Router>
      </MyContext>
    </>
  );
}

export default App;
