import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

function Deposit() {
    const navigate = useNavigate();
    

    useEffect(() => {
      const token = localStorage.getItem("token");
      if (!token) {
        navigate("/signup");
      }
    }, [navigate]);


    const [activeItem, setActiveItem] = useState(0);

  // Function to handle click and set active item
  const handleClick = (index) => {
    setActiveItem(index);
  };




  const [amount, setAmount] = useState('');
  const [isDepositActive, setIsDepositActive] = useState(false);

  // Function to handle click on predefined amounts
  const handleAmountClick = (value) => {
    setAmount(value);  // Set the input value
    setIsDepositActive(true);  // Activate the deposit button
  };

  // Function to handle manual input change
  const handleInputChange = (e) => {
    const value = e.target.value;
    setAmount(value);

    // Activate deposit button only if input is not empty
    setIsDepositActive(value !== '');
  };

  const handleDepositClick = () => {
    if (isDepositActive) {
      if (activeItem === 1) {
        navigate('/deposit/paygate2');
      } else if (activeItem === 0) {
        navigate('/deposit/paygate1');
      }
    }
  };

  return (
   <>

<div data-v-9171efc9="" class="Recharge__box" style={{ fontFamily: "'Roboto', 'Inter', sans-serif" }}>
            <div data-v-9171efc9="" class="Recharge__container">
                <div data-v-2d3dc984="" data-v-9171efc9="" class="navbar">
                    <div data-v-2d3dc984="" class="navbar-fixed" style={{background: "rgb(247, 248, 255)"}}>
                        <div data-v-2d3dc984="" class="navbar__content">
                            <div data-v-2d3dc984="" class="navbar__content-left"> <i data-v-2d3dc984="" onClick={() => navigate(-1)}
                                    class="van-badge__wrapper van-icon van-icon-arrow-left"></i>
                            </div>
                            <div data-v-2d3dc984="" class="navbar__content-center">
                                <div data-v-2d3dc984="" class="navbar__content-title">Deposit</div>
                            </div>
                            <div data-v-2d3dc984="" class="navbar__content-right">
                                <div data-v-9171efc9="" class="title">Deposit history</div>
                            </div>
                        </div>
                    </div>
                </div>



                <div data-v-d42c1f7a="" data-v-9171efc9="" class="balanceAssets">
                    <div data-v-d42c1f7a="" class="balanceAssets__header">
                        <div data-v-d42c1f7a="" class="balanceAssets__header__left"><img data-v-d42c1f7a=""
                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABICAMAAABiM0N1AAAArlBMVEUAAAD/sjH/zRj/zR//1zbuR0f/zBb/zRf/zhr/zRn/+HfuSUn/zBn/+njvSUn/zCH/+Xf/+XX/+HT/+nj/zBn/zRr/pSL/20H/2T3/1Db/zBf/30j/1zn/7mP/8Gb/3UT/9nL/9W//8mn/82z/7GDuSEj/+Xf/0zP/5lX/5FL/4Uz/6Vv/6Fj/40//613/+HT/vTT/2033kj3/xzz+yzX/rSj/sSr/0kH/sSz/rym/OY7sAAAAFnRSTlMAIsBCE+/v3IFxkZGRgYEy7+/v7rBhR1ORgAAAAh9JREFUWMPtkuly2jAQgA3hyJ2e1BKyMYGC7dbYXEn6/i/W1QqNughsI03+dPIh7SXzDSMcfPD/czXsQfTmqcv5tb+md88lHV/PzYAjXd/L4ZorD02nyw1D98u55oQb98uhDHpOl9PlFvcOl/ONn+Lp4ssZWA6XV+D70DI0vwIPnxZOfL2lnttFA6VV6vqBiL6UNouyFZ+JKDlNWSY1HA6pyB0qmgIJbJOxSnAnZowBk4GK3KGimWI6lcEs7LDChP3hMQMVuUNFz4rZIet2Rqa6PJpSkROxLYrj5xi3A1Rk8BZFcSQXFpoYw+FAT2IcmIeIKHKHiubRfC43xkguDIAa6KkeSXSkIg+IaDVfwWi1khtrALKu1AEs1aisayq6gPV2PyJ0AsOv1qSVMfiI0rdRreh3W6pRa1Gqc0pHyHrUIErboS5om63ffEUv8L0K8vqcKCOk2Tn2UiSLelEz8hft8yzbnhMVRZHBByKEGvCO9ltI50R5cZLj+WvTv5YXuYE2lKpJ1JbxS71onI81WJGGVlW96AJeqz81Ig+Cf5l48D6iH8gEtiomWE90g6VusMOEWCJnbFGIERMl1DmUoVYUevBOIiFECAFPhGyELDDgR00EgqXujkTCAyJiMGAQIApmSgOjpQ7MEl2KMCUR7ZgzOyLasCVjyyUsCTMBUYeqx83wXLEhov5mt3Rit+kT0eNPZ+4Cwl3fTdN/DD5oy19QoJEcy1l/8AAAAABJRU5ErkJggg=="/>
                            Balance</div>
                    </div>
                    <div data-v-d42c1f7a="" class="balanceAssets__main">
                        <p data-v-d42c1f7a="">₹0.78</p><img data-v-d42c1f7a=""
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFkAAAA7CAMAAAAn6dbMAAAAOVBMVEUAAAD///////////////////////////////////////////////////////////////////////8KOjVvAAAAEnRSTlMAIN9AgO8QMM9gcKC/kK9Qj29ZmZoEAAAB7klEQVRYw93Y23LjIAwGYHE+OU5W7/+wO2M1/r31QAzBN6uLTqd0voAEyA79H6EWvhAurtZ0ypEvhy6qR+au6LGXPtrl63nW3BfFTy2zMnlfXFTTd9FD/9CepttF6IXmRxY60/xIQgeaH+m2fNBjow3ND++uTNpka81YFZs7L8jm18+RSedL5zoNZLo0PlrggXo8t5XWx1dGuNCVjnaiLfMwvSVaVQb/MI/TupFBJZzsDfmppshKNDmpIYL+WhbLShNUCrP+Vn5t0ko/Mn2kfTIN2RuFbSHOW/5EB8dsK7KMZmx0dop2GbSv93d7llEzd6weQcZfo6/mFTRkDOJQW4L8XlOVznykIaNmej/UK/2S27QVOp9k1Ay/nWQyvMVabyScfssBbTG+q3eWKXH9qimgD7LSWMsi42cZNFGDDpBR2tPWhHwslW3eui4c5Nc/fVw9A1VlUslUz2HcaZFRM0RNlmjRIokccOK+lEFvcpIkP+l7GXeA4z0sTZBBIx7NizbQMK1988Uq0RDd7hVFVjRKhw+PaJEG6dz6L3lCGqKdwM1EF+oMY8sjeWqGRb5uecyOs1lM+jUfRlucH4Zvo+1Nb9PoQ1zUbTQv2UzWLXdE39tY0n208x3fXfTRpuu7i555q96LZonXknFuFn8BRodWROohzGIAAAAASUVORK5CYII="
                            alt=""/>
                    </div>
                    <div data-v-d42c1f7a="" class="balanceAssets__tip"><img data-v-d42c1f7a=""
                            src="https://damanclub.in/assets/png/cip-7ed1a634.png" alt=""/></div>
                </div>
                <div data-v-c627088b="" data-v-9171efc9="">
                    <div data-v-c627088b="" class="Recharge__container-tabcard">
                        <div data-v-c627088b="" className={`Recharge__container-tabcard__items ${
          activeItem === 0 ? "active" : ""
        }`}
        onClick={() => handleClick(0)}>
                            <div data-v-c627088b="" class="centers">
                                <div data-v-c627088b="" class="gift"><span data-v-c627088b="">+2%</span></div>
                                <div data-v-c627088b="" class="Recharge__container-tabcard__top"><img data-v-c627088b=""
                                        class="img"
                                        src="https://ossimg.envyenvelope.com/daman/payNameIcon/payNameIcon2_20230808035929pxk3.png"
                                        alt=""/></div>
                                <div data-v-c627088b="" class="Recharge__container-tabcard__bot">Local UPI Bonus </div>
                            </div>
                        </div>
                        <div data-v-c627088b="" className={`Recharge__container-tabcard__items ${
          activeItem === 1 ? "active" : ""
        }`}
        onClick={() => handleClick(1)}>
                            <div data-v-c627088b="" class="centers">
                                <div data-v-c627088b="" class="gift"><span data-v-c627088b="">+10%</span></div>
                                <div data-v-c627088b="" class="Recharge__container-tabcard__top"><img data-v-c627088b=""
                                        class="img"
                                        src="https://ossimg.envyenvelope.com/daman/payNameIcon/payNameIcon_20230808035808n6cg.png"
                                        alt=""/></div>
                                <div data-v-c627088b="" class="Recharge__container-tabcard__bot">Bank Transfer </div>
                            </div>
                        </div>
                        {/* <div data-v-c627088b="" class="Recharge__container-tabcard__items">
                            <div data-v-c627088b="" class="centers">
                                <div data-v-c627088b="" class="gift"><span data-v-c627088b="">+2%</span></div>
                                <div data-v-c627088b="" class="Recharge__container-tabcard__top"><img data-v-c627088b=""
                                        class="img"
                                        src="https://ossimg.envyenvelope.com/daman/payNameIcon/payNameIcon_20230824183241hlx2.png"
                                        alt=""/></div>
                                <div data-v-c627088b="" class="Recharge__container-tabcard__bot">E-Wallet </div>
                            </div>
                        </div> */}
                        {/* <div data-v-c627088b="" class="Recharge__container-tabcard__items">
                            <div data-v-c627088b="" class="centers">
                                <div data-v-c627088b="" class="gift"><span data-v-c627088b="">+2%</span></div>
                                <div data-v-c627088b="" class="Recharge__container-tabcard__top"><img data-v-c627088b=""
                                        class="img"
                                        src="https://ossimg.envyenvelope.com/daman/payNameIcon/payNameIcon_20230824184753jw3f.png"
                                        alt=""/></div>
                                <div data-v-c627088b="" class="Recharge__container-tabcard__bot">Paytm x QR </div>
                            </div>
                        </div> */}
                        {/* <div data-v-c627088b="" class="Recharge__container-tabcard__items">
                            <div data-v-c627088b="" class="centers">
                                <div data-v-c627088b="" class="gift"><span data-v-c627088b="">+2%</span></div>
                                <div data-v-c627088b="" class="Recharge__container-tabcard__top"><img data-v-c627088b=""
                                        class="img"
                                        src="https://ossimg.envyenvelope.com/daman/payNameIcon/payNameIcon_202308080410066jdc.png"
                                        alt=""/></div>
                                <div data-v-c627088b="" class="Recharge__container-tabcard__bot">UPI x QR </div>
                            </div>
                        </div> */}
                        
                        {/* <div data-v-c627088b="" class="Recharge__container-tabcard__items">
                            <div data-v-c627088b="" class="centers">
                                <div data-v-c627088b="" class="gift"><span data-v-c627088b="">+3%</span></div>
                                <div data-v-c627088b="" class="Recharge__container-tabcard__top"><img data-v-c627088b=""
                                        class="img"
                                        src="https://ossimg.envyenvelope.com/daman/payNameIcon/payNameIcon_2023080804094137ww.png"
                                        alt=""/></div>
                                <div data-v-c627088b="" class="Recharge__container-tabcard__bot">USDT Bonus 3% </div>
                            </div>
                        </div> */}
                        {/* <div data-v-c627088b="" class="Recharge__container-tabcard__items">
                            <div data-v-c627088b="" class="centers">
                                <div data-v-c627088b="" class="gift"><span data-v-c627088b="">+2%</span></div>
                                <div data-v-c627088b="" class="Recharge__container-tabcard__top"><img data-v-c627088b=""
                                        class="img"
                                        src="https://ossimg.envyenvelope.com/daman/payNameIcon/payNameIcon_20230808040928kyt8.png"
                                        alt=""/></div>
                                <div data-v-c627088b="" class="Recharge__container-tabcard__bot">TRX Bonus 2% </div>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div data-v-9522648f="" class="Recharge__content">
                    {/* <div data-v-9522648f="" class="Recharge__content-quickInfo boxStyle">
                        <div data-v-9522648f="" class="Recharge__content-quickInfo__title">
                            <div data-v-9522648f="" class="title"><img data-v-9522648f=""
                                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAMAAABg3Am1AAAANlBMVEUAAAD/cXL/cXP/cHT/cXL/cHL/cHD/cnP/cXP/cHD/cXH/c3P/cXL/cnL/cXL/cXL/cXP/cXLtIiw8AAAAEXRSTlMA379AYCAQn88wkFDvgLBwkJqQ7/MAAADYSURBVEjH1dJLDoMwEAPQyYf8CKS5/2W7gCmqFWSQ2kp9qywMkjWWn7P9nReig4nkHeSNEAt8ED9dIWyxWsy9Cl78xQply2V9rLSC/jfdqrDqo2fywaz/nb9VIUGFdq1CfFVI9uBJBWRGJ4x63cLzWAHULAOTVpg6cvQKfLNHhbw/DouMmf1arbK8Vhh7yAk/zkcBUAGlIAAqAHOeD263woEpC3kKBkE5GATVYBC8AhyYqjfzGQZBeRgEFWEQVIJBMAEOTM2QpwoMgrIwCAoGQTk4MJWb/KEnEComr7MGPBMAAAAASUVORK5CYII="
                                    alt=""/>
                                <p data-v-9522648f="">Select channel</p>
                            </div>
                        </div>
                        <div data-v-9522648f="" class="rechargeTypes_list">
                            <div data-v-9522648f="" class="Recharge__content-quickInfo__item item_active">
                                <div data-v-9522648f="" class="other">
                                    <div data-v-9522648f="">Local UPI</div>
                                    <div data-v-9522648f="">Balance:500 - 100K</div>
                                    <div data-v-9522648f="" class="bouns">2% bonus</div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    

                    <div data-v-9522648f="" className="Recharge__content-paymoney boxStyle">
      <div data-v-9522648f="" className="Recharge__content-paymoney__title">
        <img
          data-v-9522648f=""
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAMAAADVRocKAAAAS1BMVEUAAAD/cXL/cXL/cXL/cXP/cXL/cID/cHD/cnL/cHD/cHj/cnT/cXP/cXL/cXH/cnP/cHL/c3P/cHP/cHD/cnP/cHX/cXP/cnL/cXJIiAqNAAAAGHRSTlMA79+gv4AQEGAgIEDPcJCPYFBQMK8wkK/DVpbzAAAC9ElEQVRo3u2Z27ajIAyGk4goHmq17S7v/6SzXE0noxWQDdzM6n+5l5svJ2Ji4auvvoqXHu9KVTMUUq/sS9RBfvUT2r/CR/bQKLsRdnlDg3avCnLpVqM9ki4UmstyfQGXHKF57oxXfQMAlV3VQqJMvT+9Xk8XQO7Q9MASQGrJi9TMxucA6H1oiEOTBaBbR2gyAcZ9aFo2Pg9Ab61HDk02gCZvaNIBdzlejWx8TkAroTHAygogNp5DkxcgDtQABQDyXwTFAINddS0H4LdIacB6HfppQBspVNN8CiCtLl5Ud2HAjDZB1AYA6apLA2xVGmCn0gA7lwagdgOKJtqWdsHmU18a8FMaMJQGYBTgeHZpRh8hBjCCQ3UaILzHNMUBaTkI998xtqNah8ZDH7QnBWQKlSmSWsVllxuAdWgixLTLK2HxJA3VuJgGoLn14zMKSA2EZW7bp5bn+fgY+JVMFfOKeSiU4eWsFjoFWB3o0HHR/GouZ9uzCrWK6KYmUvsy92zJ1Tr34lC1WgjZAN2EVlR1pwnkCFGou0xa9gG/1gcNCfDQ/OHAMnaioTNlaioOAB6tSY/DM+jBX18CgPAXwo7Pd/mgQlkIELQzBqTZhYDIv0BN/NTbY/yYxpUN6T5rd4DeE58ZOGX/3N9OXAiJVqmxc6yliusF5U/ygm0wonfrYwcUvKoZ9XrgsJuVVcJs2lorm/UN7SS1Lwv3mDA6Vpuxu3/fG9rEaLYR2gGG7X25ii/SjMEkAHB3X2rxRRxuEkL0UQAD+8LFGj3WKA/AcMVcJTnxgB/3RHTjfNbcoH4XosWRZLb7gXK+JPkRcdHAVaZ87qsw9bAt04Q9ud2ezybfxS1JuChmuOY+Q2+7DTdYVmyrOHifXSQUdwbVu2bXJX1IMHyP2e5l03kMh/GkKs9wRXfOq1IfBlHCku8dGwhiOhEu4JAhT0WE3zfye4NTN/JX3JUChw+Xno/3+RC/FyUN6j8ek9IXGrwYyK/mWike3xv46j/RH4+wbTO2s0jgAAAAAElFTkSuQmCC"
          alt=""/>
        <p data-v-9522648f="">Deposit amount</p>
      </div>
      <div data-v-9522648f="" className="Recharge__content-paymoney__money-list">
        <div
          data-v-9522648f=""
          className="Recharge__content-paymoney__money-list__item"
          onClick={() => handleAmountClick('500')}
        >
          <div data-v-9522648f="" className="amount"><span data-v-9522648f="">₹</span> 500</div>
        </div>
        <div
          data-v-9522648f=""
          className="Recharge__content-paymoney__money-list__item"
          onClick={() => handleAmountClick('1000')}
        >
          <div data-v-9522648f="" className="amount"><span data-v-9522648f="">₹</span> 1K</div>
        </div>
        <div
          data-v-9522648f=""
          className="Recharge__content-paymoney__money-list__item"
          onClick={() => handleAmountClick('5000')}
        >
          <div data-v-9522648f="" className="amount"><span data-v-9522648f="">₹</span> 5K</div>
        </div>
        <div
          data-v-9522648f=""
          className="Recharge__content-paymoney__money-list__item"
          onClick={() => handleAmountClick('10000')}
        >
          <div data-v-9522648f="" className="amount"><span data-v-9522648f="">₹</span> 10K</div>
        </div>
        <div
          data-v-9522648f=""
          className="Recharge__content-paymoney__money-list__item"
          onClick={() => handleAmountClick('50000')}
        >
          <div data-v-9522648f="" className="amount"><span data-v-9522648f="">₹</span> 50K</div>
        </div>
        <div
          data-v-9522648f=""
          className="Recharge__content-paymoney__money-list__item"
          onClick={() => handleAmountClick('100000')}
        >
          <div data-v-9522648f="" className="amount"><span data-v-9522648f="">₹</span> 100K</div>
        </div>
      </div>
      <div data-v-9522648f="" className="Recharge__content-paymoney__money-input">
        <div data-v-9522648f="" className="place-div">₹</div>
        <div data-v-9522648f="" className="van-cell van-field amount-input" modelmodifiers="[object Object]">
          <div className="van-cell__value van-field__value">
            <div className="van-field__body">
              <input
                type="tel"
                inputMode="numeric"
                id="van-field-1-input"
                className="van-field__control"
                placeholder="Please enter the amount"
                value={amount}
                onChange={handleInputChange}
                fdprocessedid="w6hki"
              />
            </div>
          </div>
        </div>
        <div data-v-9522648f="" className="place-right">
          <img
            data-v-9522648f=""
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAMAAADVRocKAAAAPFBMVEUAAABoaGhoaGhmZmZnZ2dmZmZmZmZwcHBmZmZnZ2doaGhnZ2dmZmZmZmZmZmZpaWlmZmZgYGBnZ2dmZmbTzN1eAAAAE3RSTlMAXyDvv3DfEK+fQN+AUM8wkBDPjWztQAAAAuRJREFUaN7tWduu4yAM5H4LNO3p///rbkO1PNhh4BBVWql+aomZwWMSLhZf+9rHTMkcjXPPv6adCbnsF4J7G/STmA5SXYNunqdmljlS1M++RbUCb5og5i6LOsD8nuzd6HUKFf4pYZmU7tasUdj3GLfsz6fW9qbI88M378Gnvl95+zk1P/wKDy3VKLSdwb/XPnLMW26TMvkadvDDesapDurGh4wlvakhfHfMncmkqW001f4Y/w/rieO+YZXCge/FtD0OBoPcMsCHDHeQLISPGWxXSI3yizOte90dcIAMup+GDELEZrsI6vj0iiULPQ0iTgBO9Euk0AlAikWTL5R0HoBYNnOWZ8VRF+1Kd7z0eToLIXLMjqhG9NBcCPEkADJaTfJC9N4EE4L2rPPGtTYG/iGVcGPfBVOBMAN+lN9iU4UUgBmkfrweeOpeWTEQFs8wGoXWhqFoI/0iBWa67AIwDOKLvc1e0oQZaAM1TRJaWgowA8XHUzK31RQwtD9gZ5i5HGOG9hN5RxJTEgMMGJ9X3LVJBBgAfnttHUm7FyMMLD4mePUSyCTGp3i0ATNI8b8STEpEZpFaSTL9YGtAsDRNdzKLDFn7ll60RF60CPrgTwVdED77sZPLn2uguLp+wdk/sGR+dNG3V29bJNgszW+86CaOsmYAM0FtWN0caS0UBDO42sodrhLxxdt3zQ5KDR5AND6AbJ0DCD76lE3z+O154XAUOL2tmDs9bKfrjrGqc0r3a/iqBtC5yghrBJEGQK8yFsyC+01zxXXOhhycWkgAGqAFV4fowhJLfAcMED8jNwMYAH4YvDq8/fJi9scvXBIvXUivX47PXEg/wmRlxtcOZjxzuZZNRgsU+nC//6LE4tJoLYxKilNdwy4QvvpxemKZqlCnfX12TzB8UJkZLNQFMHxIUUuNNu3+aFVFtlIjqoVhir7pmJYr1b1yr/XXVMP5gjVBX7G95GDcweOciVkq8bWvfcr+APQTXgFY+Cs5AAAAAElFTkSuQmCC"
            alt=""
          />
        </div>
      </div>
      <div
        data-v-9522648f=""
        className={`Recharge__container-rechageBtn ${isDepositActive ? "rechage_active" : ""}`}
        onClick={handleDepositClick}
      >
        Deposit
      </div>
    </div>
                    <div data-v-9522648f="" class="Recharge__content-waitPay boxStyle" style={{display: "none"}}><img
                            data-v-9522648f="" src="https://damanclub.in/assets/png/tip-0498e3f9.png" alt=""/>
                        <div data-v-9522648f="" class="wait_text">You have 1 unpaid order</div>
                        <div data-v-9522648f="" class="Recharge__content-waitPay__countdown"><span
                                data-v-9522648f="">3</span><span data-v-9522648f="">0</span><span
                                data-v-9522648f="">:</span><span data-v-9522648f="">0</span><span
                                data-v-9522648f="">0</span></div>
                        <div data-v-9522648f="" class="go_pay">Go pay</div>
                    </div>
                </div>
                <div data-v-c2e24b33="" data-v-9171efc9="" class="Recharge__container-intro">
                    <div data-v-c2e24b33="" class="Recharge__container-intro__title"><img data-v-c2e24b33=""
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAMAAADVRocKAAAAP1BMVEUAAAD/cXL/cXL/cXL/cHD/cXL/cHP/cXP/cXT/cnP/cnL/c3P/cHj/cXP/cnL/cHj/cnL/cnL/cHL/cHD/cXJEdSoUAAAAFHRSTlMA799fIJBQv0Cfn1Agz3AQr4BwMGQ0OlkAAADtSURBVGje7dm5jgIxEIThPjz3sOxR7/+siwhABIzssSpAqj9z9EnuxHKbUupN83BxtBRpDWWgObfq9glnsto2BxXYgD4g/XAeu/cCcTyPQC9wfF0JMhBkYAYZGNjAxAacDUCAAAECBAgQIECAAAECBAgQ8BFAkAHPNsDXsVhbTcD31cyIwK8ZFfgxLrAaGShkYDUyMLKBv8cpHZVFNgCnfmn9FOCoLioBB1BOLEI8K4ELe8gDgIEJzAD8SgQsACxMIHHriwjY9BQ4QHHcWnYaYCPuLVshATY6urPDSpCAZ9lJRM26d/KeN5JS6qV/A1tBf8qroqkAAAAASUVORK5CYII="
                            alt=""/>
                        <p data-v-c2e24b33="">Recharge instructions</p>
                    </div>
                    <div data-v-c2e24b33="" class="Recharge__container-intro__lists">
                        
                        <div data-v-c2e24b33="" class="item">
                            <p data-v-c2e24b33="">After completing the UPI transaction, please fill in Ref NO./utr If
                                you do not fill in UTR,the loss of customer , our company will not responsible. please
                                be sure to fill it back！</p>
                            <p data-v-c2e24b33="">If you are wrong when you create an order, the loss of customer , our
                                company will not responsible !</p>
                        </div>
                    </div>
                </div>
                <div data-v-7926949c="" data-v-9171efc9="" class="record__main" payid="12">
                    <div data-v-7926949c="" class="record__main-title"><img data-v-7926949c=""
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgBAMAAAAQtmoLAAAAJFBMVEUAAAD/cXL/cXL/cHL/cXP/cHj/cXL/////9vb/7e3/pqf/pqaEf2fZAAAABnRSTlMA799ftyBNH49qAAAAiElEQVRYw+3ToQ2AQAyF4TNMQMICl+BhAwyeEUB0BWACBAOQMAFsia3oNT0BIen7/Wea14DsFR2liwIYSGkUQE9aAqB8EJIBfA0aUooCqNVFCYDUAAAAAFLgmlmnARwcLAawcbAawM3B/osrAXgB+AcA74D1CqhyQWsHPAAvQF3UJAB1UWVA5h68Q9V5A73wNQAAAABJRU5ErkJggg=="
                            alt=""/><span data-v-7926949c="">Deposit history</span></div>
                    <div data-v-91f2fefc="" data-v-7926949c="" class="empty__container mgt40"><img data-v-91f2fefc=""
                            alt="" class="" data-origin="https://damanclub.in/assets/png/empty-4ac9a431.png"
                            src="https://damanclub.in/assets/png/empty-4ac9a431.png"/>
                        <p data-v-91f2fefc="">No data</p>
                    </div>
                </div>
            </div>
            <div data-v-cf21bf5a="" data-v-9171efc9="" class="dialog inactive">
                <div data-v-cf21bf5a="" class="dialog__container" role="dialog" tabindex="0">
                    <div data-v-cf21bf5a="" class="dialog__container-img"><img data-v-cf21bf5a="" alt="" class=""
                            data-origin="https://damanclub.in/assets/png/orderCancelWarn-ac58c333.png"
                            src="https://damanclub.in/assets/png/orderCancelWarn-ac58c333.png"/></div>
                    <div data-v-cf21bf5a="" class="dialog__container-title">
                        <h1 data-v-cf21bf5a="">Invalid amount</h1>
                    </div>
                    <div data-v-cf21bf5a="" class="dialog__container-content">
                        <div data-v-9171efc9="" class="cancen_model_cnt">Please select another amount</div>
                    </div>
                    <div data-v-cf21bf5a="" class="dialog__container-footer"><button
                            data-v-cf21bf5a="">Cancel</button><button data-v-cf21bf5a="">OK</button></div>
                </div>
                <div data-v-cf21bf5a="" class="dialog__outside"></div>
            </div>
            <div data-v-cf21bf5a="" data-v-9171efc9="" class="dialog inactive">
                <div data-v-cf21bf5a="" class="dialog__container" role="dialog" tabindex="0">
                    <div data-v-cf21bf5a="" class="dialog__container-img"><img data-v-cf21bf5a="" alt="" class=""
                            data-origin="https://damanclub.in/assets/png/forbhidden-a43247ed.png"
                            src="https://damanclub.in/assets/png/forbhidden-a43247ed.png"/></div>
                    <div data-v-cf21bf5a="" class="dialog__container-title">
                        <h1 data-v-cf21bf5a="">You have been disabled from C2C transactions for 0 hours</h1>
                    </div>
                    <div data-v-cf21bf5a="" class="dialog__container-content">
                        <div data-v-9171efc9="" class="forbidden_tip">0 hours remaining</div>
                        <div data-v-9171efc9="" class="forbidden1">Because your transactions failed 0 times in a row
                        </div>
                        <div data-v-9171efc9="" class="forbidden2">C2C recharge is prohibited within 0 hours</div>
                        <div data-v-9171efc9="" class="forbidden3">If you have any questions, please contact customer
                            service</div>
                    </div>
                    <div data-v-cf21bf5a="" class="dialog__container-footer"><button
                            data-v-cf21bf5a="">Sure</button></div>
                </div>
                <div data-v-cf21bf5a="" class="dialog__outside"></div>
            </div>
            <div data-v-cf21bf5a="" data-v-9171efc9="" class="dialog inactive">
                <div data-v-cf21bf5a="" class="dialog__container" role="dialog" tabindex="0">
                    <div data-v-cf21bf5a="" class="dialog__container-img"><img data-v-cf21bf5a="" alt="" class=""
                            data-origin="https://damanclub.in/assets/png/orderCancelWarn-ac58c333.png"
                            src="https://damanclub.in/assets/png/orderCancelWarn-ac58c333.png"/></div>
                    <div data-v-cf21bf5a="" class="dialog__container-title">
                        <h1 data-v-cf21bf5a="">safety warning</h1>
                    </div>
                    <div data-v-cf21bf5a="" class="dialog__container-content">
                        <div data-v-9171efc9="" class="cancen_model_cnt">Please use your own PIX account to obtain the
                            order and QR code for recharge. Do not use the QR code provided by others to pay.</div>
                    </div>
                    <div data-v-cf21bf5a="" class="dialog__container-footer"><button
                            data-v-cf21bf5a="">Confirm</button></div>
                </div>
                <div data-v-cf21bf5a="" class="dialog__outside"></div>
            </div>
        </div>
   </>
  )
}

export default Deposit
