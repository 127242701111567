import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

function Gamehistory() {

    const navigate = useNavigate();

	

    useEffect(() => {
      const token = localStorage.getItem("token");
      if (!token) {
        navigate("/signup");
      }
    }, [navigate]);

  return (
    <>

<div data-v-1d8fbc24="" class="bet-container"
			style={{ "--f13b4d11-currentFontFamily": "'Roboto', 'Inter', sans-serif" }}>
			<div data-v-12a80a3e="" data-v-1d8fbc24="" class="navbar">
				<div data-v-12a80a3e="" class="navbar-fixed">
					<div data-v-12a80a3e="" class="navbar__content">
						<div data-v-12a80a3e="" class="navbar__content-left"><i data-v-12a80a3e=""
								class="van-badge__wrapper van-icon van-icon-arrow-left" onClick={() => navigate(-1)}></i></div>
						<div data-v-12a80a3e="" class="navbar__content-center">
							<div data-v-12a80a3e="" class="navbar__content-title">Bet history</div>
						</div>
						<div data-v-12a80a3e="" class="navbar__content-right"></div>
					</div>
				</div>
			</div>
			<div data-v-1d8fbc24="" class="bet-container-sticky" >
				<div class="van-sticky" >
					<div data-v-1d8fbc24="" style={{ background: "var(--bg_color_L1)" }}
                    >
						<div data-v-1d8fbc24="" class="fun-tabs tabs" is-auto-load="true" activeclassname="tab_active">
							<div class="fun-tabs__tab-list"
								style={{ transitionTimingFunction: 'cubic-bezier(0.25, 0.46, 0.45, 0.94)', transitionDuration: '360ms', transform: 'translate3d(0px, 0px, 0px)' }}>
								<div class="fun-tab-item funtab_item activeClassName" style={{ color: 'rgb(22, 119, 255)' }}>
									<div class="fun-tab-item__wrap">
										<div class="fun-tab-item__label">
											<div data-v-1d8fbc24="" class="tab_item tab_active"><svg data-v-1d8fbc24=""
													class="svg-icon icon-lottery">
													<use xlinkhref="#icon-lottery"></use>
												</svg><span data-v-1d8fbc24="">Lottery</span></div>
										</div>
									</div>
								</div>
								<div class="fun-tab-item funtab_item">
									<div class="fun-tab-item__wrap">
										<div class="fun-tab-item__label">
											<div data-v-1d8fbc24="" class="tab_item"><svg data-v-1d8fbc24=""
													class="svg-icon icon-video">
													<use xlinkhref="#icon-video"></use>
												</svg><span data-v-1d8fbc24="">Casino</span></div>
										</div>
									</div>
								</div>
								<div class="fun-tab-item funtab_item">
									<div class="fun-tab-item__wrap">
										<div class="fun-tab-item__label">
											<div data-v-1d8fbc24="" class="tab_item"><svg data-v-1d8fbc24=""
													class="svg-icon icon-fish">
													<use xlinkhref="#icon-fish"></use>
												</svg><span data-v-1d8fbc24="">Fishing</span></div>
										</div>
									</div>
								</div>
								<div class="fun-tab-item funtab_item">
									<div class="fun-tab-item__wrap">
										<div class="fun-tab-item__label">
											<div data-v-1d8fbc24="" class="tab_item"><svg data-v-1d8fbc24=""
													class="svg-icon icon-chess">
													<use xlinkhref="#icon-chess"></use>
												</svg><span data-v-1d8fbc24="">Rummy</span></div>
										</div>
									</div>
								</div>
								<div class="fun-tab-item funtab_item">
									<div class="fun-tab-item__wrap">
										<div class="fun-tab-item__label">
											<div data-v-1d8fbc24="" class="tab_item"><svg data-v-1d8fbc24=""
													class="svg-icon icon-flash">
													<use xlinkhref="#icon-flash"></use>
												</svg><span data-v-1d8fbc24="">Original</span></div>
										</div>
									</div>
								</div>
								<div class="fun-tab-item funtab_item">
									<div class="fun-tab-item__wrap">
										<div class="fun-tab-item__label">
											<div data-v-1d8fbc24="" class="tab_item"><svg data-v-1d8fbc24=""
													class="svg-icon icon-slot">
													<use xlinkhref="#icon-slot"></use>
												</svg><span data-v-1d8fbc24="">Slots</span></div>
										</div>
									</div>
								</div>
								<div class="fun-tabs__active-line"
									style={{
                                        transition: 'all 300ms ease 0s',
                                        width: '0px',
                                        height: '3px',
                                        transform: 'translate3d(53.5px, 0px, 0px)',
                                        backgroundColor: 'rgb(22, 119, 255)'
                                      }}>
								</div>
							</div>
						</div>
						<div data-v-1d8fbc24="" class="bet-container-searchBar">
							<div data-v-1d8fbc24="" class="ar-searchbar">
								<div data-v-fa757a88="" data-v-1d8fbc24="" class="ar-searchbar__selector">
									<div data-v-fa757a88=""><span data-v-fa757a88=""
											class="ar-searchbar__selector-default">Win Go</span><i data-v-fa757a88=""
											class="van-badge__wrapper van-icon van-icon-arrow-down"></i>
									</div>
								</div>
								<div data-v-fa757a88="" data-v-1d8fbc24="" class="ar-searchbar__selector">
									<div data-v-fa757a88=""><span data-v-fa757a88=""
											class="ar-searchbar__selector-default">Choose a date</span><i
											data-v-fa757a88=""
											class="van-badge__wrapper van-icon van-icon-arrow-down"></i>
									</div>
								</div>
							</div>
						</div>
						<div data-v-1d8fbc24=""></div>
					</div>
				</div>
			</div>
			<div data-v-1d8fbc24="" class="bet-content__box">
				<div data-v-61888f52="" data-v-1d8fbc24="" class="infiniteScroll"
					id="refresh40439c25e3fd40e6a847b16784b9528a">
					<div data-v-1d8fbc24="" class="bet-container-items">
						<div data-v-1d8fbc24="" class="bet-container-lottery">
							
				
							<div data-v-1d8fbc24="" class="bet-container-lottery-items">
								<div data-v-1d8fbc24="" class="bet-container-lottery-card">
									<div data-v-1d8fbc24="" class="bet-container-lottery-card-header ar-1px-b">
										<h1 data-v-1d8fbc24="">
											<h2 data-v-1d8fbc24="">Win Go</h2><span data-v-1d8fbc24=""
												class="colorE98613">Lose</span>
										</h1>
										<p data-v-1d8fbc24="">2024-06-11 04:28:28</p>
									</div>
									<div data-v-1d8fbc24="" class="bet-container-lottery-card-info">
										<ul data-v-1d8fbc24="">
											<li data-v-1d8fbc24=""><span data-v-1d8fbc24=""><svg data-v-1d8fbc24=""
														class="svg-icon icon-round">
														<use xlinkhref="#icon-round"></use>
													</svg>
													<h2 data-v-1d8fbc24="">Type</h2>
												</span><span data-v-1d8fbc24="">Win Go 1Min</span></li>
											<li data-v-1d8fbc24=""><span data-v-1d8fbc24=""><svg data-v-1d8fbc24=""
														class="svg-icon icon-round">
														<use xlinkhref="#icon-round"></use>
													</svg>
													<h2 data-v-1d8fbc24="">Period</h2>
												</span><span data-v-1d8fbc24="">20240611010269</span></li>
											<li data-v-1d8fbc24=""><span data-v-1d8fbc24=""><svg data-v-1d8fbc24=""
														class="svg-icon icon-round">
														<use xlinkhref="#icon-round"></use>
													</svg>
													<h2 data-v-1d8fbc24="">Order number</h2>
												</span><span data-v-1d8fbc24="">WG2024061104282865389635c</span></li>
											<li data-v-1d8fbc24=""><span data-v-1d8fbc24=""><svg data-v-1d8fbc24=""
														class="svg-icon icon-round">
														<use xlinkhref="#icon-round"></use>
													</svg>
													<h2 data-v-1d8fbc24="">Select</h2>
												</span>
												<p data-v-1d8fbc24="">0</p>
											</li>
											<li data-v-1d8fbc24=""><span data-v-1d8fbc24=""><svg data-v-1d8fbc24=""
														class="svg-icon icon-round">
														<use xlinkhref="#icon-round"></use>
													</svg>
													<h2 data-v-1d8fbc24="">Total bet</h2>
												</span><span data-v-1d8fbc24="">₹13.00</span></li>
										</ul>
									</div>
								</div><img data-v-1d8fbc24="" src="https://damanclub.in/assets/png/moonBar-f80ac733.png"/>
								<div data-v-1d8fbc24="" class="bet-container-lottery-note">
									<div data-v-1d8fbc24="" class="bet-container-lottery-note-result">
										<div data-v-1d8fbc24="">
											<h1 data-v-1d8fbc24=""><svg data-v-1d8fbc24="" class="svg-icon icon-round">
													<use xlinkhref="#icon-round"></use>
												</svg>Lottery results</h1>
											<h2 data-v-1d8fbc24=""><svg data-v-1d8fbc24="" class="svg-icon icon-round">
													<use xlinkhref="#icon-round"></use>
												</svg>
												<p data-v-1d8fbc24="">6</p><span data-v-1d8fbc24=""
													class="bigClass">Big</span><span data-v-1d8fbc24=""
													class="red">Red</span>
											</h2>
										</div>
									</div>
									<div data-v-1d8fbc24="" class="bet-container-lottery-note-box">
										<div data-v-1d8fbc24="">
											<div data-v-1d8fbc24="" class="bet-container-lottery-note-box-para">
												<h3 data-v-1d8fbc24="">₹12.74</h3><span data-v-1d8fbc24="">Actual
													amount</span>
											</div>
										</div>
										<div data-v-1d8fbc24="">
											<div data-v-1d8fbc24="" class="bet-container-lottery-note-box-para">
												<h3 data-v-1d8fbc24="">₹0.00</h3><span
													data-v-1d8fbc24="">Winnings</span>
											</div>
										</div>
										<div data-v-1d8fbc24="">
											<div data-v-1d8fbc24="" class="bet-container-lottery-note-box-para">
												<h3 data-v-1d8fbc24="">₹0.26</h3><span data-v-1d8fbc24="">Handling
													fee</span>
											</div>
										</div>
										<div data-v-1d8fbc24="">
											<div data-v-1d8fbc24="" class="bet-container-lottery-note-box-para">
												<h4 data-v-1d8fbc24="" class="h4_red">-₹13.00</h4><span
													data-v-1d8fbc24="">Profit/loss</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					
					<div data-v-61888f52="" class="infiniteScroll__loading">
						<div data-v-61888f52="">No more</div>
					</div>
				</div>
			</div>
		</div>
    
    </>
  )
}

export default Gamehistory
