import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

function Withdrawhistory() {

    const navigate = useNavigate();

    useEffect(() => {
      const token = localStorage.getItem("token");
      if (!token) {
        navigate("/signup");
      }
    }, [navigate]);

  return (
    <>
     <div data-v-e4760c44="" class="rechargeh__container"
            style={{ "--f13b4d11-currentFontFamily": "'Roboto', 'Inter', sans-serif" }}>
            <div data-v-12a80a3e="" data-v-e4760c44="" class="navbar white">
                <div data-v-12a80a3e="" class="navbar-fixed">
                    <div data-v-12a80a3e="" class="navbar__content">
                        <div data-v-12a80a3e="" class="navbar__content-left"><i data-v-12a80a3e=""
                                class="van-badge__wrapper van-icon van-icon-arrow-left" onClick={() => navigate(-1)}></i></div>
                        <div data-v-12a80a3e="" class="navbar__content-center">
                            <div data-v-12a80a3e="" class="navbar__content-title">Withdrawal history</div>
                        </div>
                        <div data-v-12a80a3e="" class="navbar__content-right"></div>
                    </div>
                </div>
            </div>
            <div data-v-e4760c44="" class="rechargeh__container_header">
                <div data-v-e4760c44="" class="van-tabs van-tabs--card onlineGames__container-tabBar">
                    <div class="van-tabs__wrap">
                        <div role="tablist" class="van-tabs__nav van-tabs__nav--card" aria-orientation="horizontal">
                            
                            <div id="van-tabs-9-0" role="tab" class="van-tab van-tab--card van-tab--active" tabindex="0"
                                aria-selected="true" aria-controls="van-tab-10"><span
                                    class="van-tab__text van-tab__text--ellipsis">
                                    <div data-v-e4760c44="" class="tabDiv"><svg data-v-e4760c44=""
                                            class="svg-icon icon-all">
                                            <use xlinkhref="#icon-all"></use>
                                        </svg> All</div>
                                </span></div>
                            <div id="van-tabs-9-1" role="tab" class="van-tab van-tab--card" tabindex="-1"
                                aria-selected="false" aria-controls="van-tab-11"><span
                                    class="van-tab__text van-tab__text--ellipsis">
                                    <div data-v-e4760c44="" class="tabDiv"><img data-v-e4760c44=""
                                            src="https://ossimg.envyenvelope.com/daman/payNameIcon/WithBeforeImgIcon_202309141300357sjg.png"/>
                                        BANK CARD</div>
                                </span></div>
                            <div id="van-tabs-9-2" role="tab" class="van-tab van-tab--card" tabindex="-1"
                                aria-selected="false" aria-controls="van-tab-12"><span
                                    class="van-tab__text van-tab__text--ellipsis">
                                    <div data-v-e4760c44="" class="tabDiv"><img data-v-e4760c44=""
                                            src="https://ossimg.envyenvelope.com/daman/payNameIcon/WithBeforeImgIcon_20230914130048fq13.png"/>
                                        USDT</div>
                                </span></div>
                        </div>
                    </div>
                    <div class="van-tabs__content"></div>
                </div>
                <div data-v-e4760c44="" class="ar">
                    <div data-v-e4760c44="" class="ar-searchbar">
                        <div data-v-fa757a88="" data-v-e4760c44="" class="ar-searchbar__selector">
                            <div data-v-fa757a88=""><span data-v-fa757a88=""
                                    class="ar-searchbar__selector-default">All</span><i data-v-fa757a88=""
                                    class="van-badge__wrapper van-icon van-icon-arrow-down"></i>
                            </div>
                        </div>
                        <div class="ar-searchbar__selector">
                            <div><span class="noSelect">Choose a date</span><i
                                    class="van-badge__wrapper van-icon van-icon-arrow-down"></i>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div data-v-cbab7763="" data-v-e4760c44="" class="infiniteScroll"
                id="refresh4d85c93d6fe84e0e9ecc9e02b4e3caaf">
                <div data-v-e4760c44="" class="rechargeh__container-content">
                    <div data-v-e4760c44="" class="rechargeh__container-content__item">
                        
                        <div data-v-e4760c44="" class="rechargeh__container-content__item-header ar-1px-b"><span
                                data-v-e4760c44="">Withdraw</span><span
                                data-v-e4760c44="" class="stateG">Completed </span>
                        </div>
                        <div data-v-e4760c44="" class="rechargeh__container-content__item-body">
                            <div data-v-e4760c44=""><span data-v-e4760c44="">Balance</span><span
                                    data-v-e4760c44="">₹4,200.00</span></div>
                            <div data-v-e4760c44=""><span data-v-e4760c44="">Type</span><span data-v-e4760c44=""> BANK
                                    CARD</span></div>
                            <div data-v-e4760c44=""><span data-v-e4760c44="">Time</span><span
                                    data-v-e4760c44="">2024-04-06 18:40:28</span></div>
                            <div data-v-e4760c44=""><span data-v-e4760c44="">Order number</span><span
                                    data-v-e4760c44="">WD2024040618402813023556b</span><svg data-v-e4760c44=""
                                    class="svg-icon icon-copy">
                                    <use xlinkhref="#icon-copy"></use>
                                </svg></div>
                            <div data-v-e4760c44=""><span data-v-e4760c44="">Remarks</span></div>
                            <div data-v-e4760c44=""><textarea
                                    data-v-e4760c44="" class="textarea" name="remark" cols="30" rows="10" readonly=""
                                    style={{display: "none"}}></textarea></div>
                        </div>
                    </div>
                    
                    
                </div>
                <div data-v-cbab7763="" class="infiniteScroll__loading">
                    <div data-v-cbab7763="">No more</div>
                </div>
            </div>
        </div>
    
    </>
  )
}

export default Withdrawhistory
